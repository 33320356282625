import { CustomTable, type NotificationProps, TitleWithIcon, Notification } from "src/components"
import { RiFilePaper2Line } from 'react-icons/ri';
import { Layout } from "src/layouts"
import { type Base, useService } from "src/hooks";
import { useEffect, useState } from "react";
import { Paths, currency, isCentral } from "src/utils";
import { useNavigate, useParams } from "react-router-dom"
import { FaArrowLeft, FaEye } from "react-icons/fa";
import { MdPayment } from "react-icons/md";
import { isEmpty } from "lodash";
import { InvoiceDetailsDrawer } from "./Details";

export const InvoicePage = () => {
  const [runnersInvoices, setRunnersInvoices] = useState<RunnersInvoices[]>([]);
  const [runnerInvoice, setRunnerInvoice] = useState<RunnersInvoices>({} as RunnersInvoices);

  const { runner } = useParams<{ runner: string }>();
  const navigate = useNavigate();
  const [notification, setNotification] = useState<NotificationProps>({
    title: 'Esta seguro que desea marcar como pagado esta factura?',
    type: 'asking',
    actions: [],
    visible: false,
    onDismiss: () => {}
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const runnersInvoicesSvc = useService<Base<RunnersInvoices[]>>({
    route: 'runnerInvoices',
    data: async (res, operation) => {
      if (!isEmpty(res.data) && operation === 'get') {
        setRunnersInvoices(res.data);
      }

      if (operation === 'update') {
        runnersInvoicesSvc.get(`?runner=${runner}`);
      }
    }
  });

  useEffect(() => {
    if (runner && isCentral()) {
      runnersInvoicesSvc.get(`?runner=${runner}`);
    } else if (!isCentral()) {
      runnersInvoicesSvc.get();
    }
  }, [runner]);

  const isLoading = runnersInvoicesSvc.serviceLoading === 'get';

  function onGoBack() {
    navigate(Paths.invoice);
  }

  function onPay(id: number) {
    return () => {
      setNotification(prev => ({
        ...prev,
        visible: true,
        actions: [
          {
            action: () => {
              setNotification(prev => ({ ...prev, visible: false, actions: [] }))
            },
            label: 'Cancelar',
            type: 'none'
          },
          {
            action: async() => {
              await runnersInvoicesSvc.update({
                id,
                data: {
                  invoiceStatus: 2 // pagado
                }
              });
              setNotification(prev => ({ ...prev, visible: false, actions: [] }));
            },
            label: 'Pagar',
            type: 'success'
          }
        ]
      }))
    };
  }

  function onViewDetails(runner: RunnersInvoices) {
    return () => {
      setIsOpen(true);

      setRunnerInvoice(runner);
    }
  }

  function onClose() {
    setIsOpen(false);

    setRunnerInvoice({} as RunnersInvoices);
  }

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <Notification
        {...{
          ...notification,
          onDismiss: () => {
            setNotification(prev => ({ ...prev, visible: false, actions: [] }))
          }
        }}
      />

      <InvoiceDetailsDrawer
        data={runnerInvoice}
        isOpen={isOpen}
        onClose={onClose}
      />

      <div className="p-6 bg-custom-bg flex h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
          <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-2 md:flex-row'>
            <TitleWithIcon
              icon={
                 isCentral()
                   ? <button onClick={onGoBack} type='button' className='w-full'>
                 <FaArrowLeft />
               </button>
                   : <RiFilePaper2Line />
              }
              title={isCentral() ? 'Facturas' : 'Estado de cuenta'}
            />

            {/* <div>
              <Input label='Buscar por N.Ticket' placeholder='12223' />
            </div> */}
          </div>

          <div className='flex flex-grow relative'>
            <div className='absolute inset-0 overflow-y-auto'>
              <CustomTable<RunnersInvoices>
                data={runnersInvoices}
                isLoading={isLoading}
                cols={[
                  {
                    name: 'Porcentaje Aplicado',
                    field: '',
                    render: ({ data }) => {
                      return <>{data.percentageApplied || 0}</>
                    }
                  },
                  {
                    name: 'Cuadre Socio',
                    field: '',
                    render: ({ data }) => {
                      return <div>
                        {currency((data.balanceRunner || 0).toString())}
                      </div>
                    }
                  },
                  {
                    name: 'Cuadre Central',
                    field: '',
                    render: ({ data }) => {
                      return <div>
                        {currency((data.balanceCenter || 0).toString())}
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Acciones',
                    class: 'text-center',
                    hide: !isCentral(),
                    render: ({ data }) => {
                      return <div className="flex items-center flex-end gap-2">
                        {data.invoiceStatus === 1 && <button onClick={onPay(data.id)} className="text-md flex items-center gap-2 text-white bg-red-600 p-1 px-4 rounded-full">
                          <MdPayment /> <p>Pagar</p>
                        </button>}

                        <button type="button" className="text-md flex items-center gap-2 text-white bg-cta-blue p-1 px-4 rounded-full" onClick={onViewDetails(data)}>
                          <FaEye /> <p>Detalles</p>
                        </button>
                      </div>
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
