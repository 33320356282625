import { CustomTable, TitleWithIcon } from "src/components"
import { RiFilePaper2Line } from 'react-icons/ri';
import { Layout } from "src/layouts"
// import { IoMdEye } from "react-icons/io";
import { type Base, useService } from "src/hooks";
import { useEffect, useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/utils";

export const SociosPage = () => {
  const [runners, setRunnersInvoices] = useState<Runner[]>([]);
  const runnersSvc = useService<Base<Runner[]>>({
    route: 'runners',
    data: res => {
      setRunnersInvoices(res.data);
    }
  });
  const navigate = useNavigate();

  useEffect(() => {
    runnersSvc.get(`?isPartner=true`);
  }, []);

  const isLoading = runnersSvc.serviceLoading === 'get';

  function navigateToDetails(runner: Runner) {
    navigate(`${Paths.invoice}/${runner.id}`)
  }

  return (
        <Layout
            config={{
              withSideBar: true
            }}
        >
            <div className="p-6 bg-custom-bg flex h-full">
                {/* box */}
                <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
                    <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-2 md:flex-row'>
                        <TitleWithIcon
                            icon={<RiFilePaper2Line />}
                            title='Factura socios'
                        />

                        {/* <div>
              <Input label='Buscar por N.Ticket' placeholder='12223' />
            </div> */}
                    </div>

                    <div className='flex flex-grow relative'>
                        <div className='absolute inset-0 overflow-y-auto'>
                            <CustomTable<Runner>
                                data={runners}
                                isLoading={isLoading}
                                onRowClick={navigateToDetails}
                                cols={[
                                  {
                                    name: 'Nombre',
                                    field: '',
                                    render: ({ data }) => {
                                      return <div className="capitalize">{data.user.names}{' '}{data.user.lastName}</div>
                                    }
                                  },
                                  {
                                    name: 'Prefijo de jugado',
                                    field: '',
                                    render: ({ data }) => {
                                      return <>{data.playerPrefix}</>
                                    }
                                  },
                                  {
                                    name: 'Porcentaje',
                                    field: '',
                                    render: ({ data }) => {
                                      return <>{data.percentage}</>
                                    }
                                  },
                                  {
                                    name: 'Acciones',
                                    field: '',
                                    render: ({ data }) => {
                                      return <div className="flex items-center flex-end">
                                                <button onClick={() => navigateToDetails(data)} className="text-md flex items-center gap-2 text-white bg-red-600 p-2 px-4 rounded-full">
                                                    <RiFilePaper2Line /> <p>Facturas</p> <MdChevronRight />
                                                </button>
                                            </div>
                                    }
                                  }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
  )
}
