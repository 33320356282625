/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft, FaEye } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { type BetIDS, type ReportsTypes } from "src/appTypes";
import { CButton, CustomTable, Input, TitleWithIcon } from "src/components";
import { useService } from "src/hooks";
import { Layout, Loader } from "src/layouts"
import { APP_STORAGE_KEY, Paths, badgesClasses, betStatus, betType, currency, getFormattedDateTime, getStorageValue, isBanker, search } from "src/utils";
import { type QueryForm, queryResolverDetail } from "./validations";
import { MdNote, MdSearch } from "react-icons/md";
import { CuadreDialog } from "./CuadreDialog";
import { IoMdEye } from "react-icons/io";
import { ReportsDetailsDialog } from "./reportsDetailsDialog";
import { AiFillPieChart } from "react-icons/ai";
import classNames from "classnames";

export const ReportDetailsPage = () => {
  const { banker } = useParams<{ banker: string }>();
  const currentBanker = isBanker() ? getStorageValue<{ banker: number }>(APP_STORAGE_KEY).banker?.toString() : banker;
  const [searchParams] = useSearchParams();
  const date = searchParams.get('date') || getFormattedDateTime(new Date(), 'year');
  const [currentDate, setCurrentDate] = useState<string>(date);
  const { register, handleSubmit, formState: { errors } } = useForm<Pick<QueryForm, 'date'>>({
    resolver: queryResolverDetail,
    defaultValues: {
      date: currentDate
    }
  });

  const [detail, setDetail] = useState<ReportsTypes>({} as ReportsTypes);
  const [query, setQuery] = useState<string>('');
  const [displayInfo, setDisplayInfo] = useState<boolean>(false);
  const [openDetailDialog, setDetailDialog] = useState<boolean>(false);
  const [displayCuadreDialog, setDisplayCuadreDialog] = useState<boolean>(false);
  const handleDisplayCuadreDialog = () => setDisplayCuadreDialog(prev => !prev)
  const [bet, setBet] = useState<BetIDS>({} as BetIDS);

  const navigate = useNavigate();

  const reportsSvc = useService<ReportsTypes[]>({
    route: 'reports',
    data: res => {
      const [currentBanker] = res;

      if (currentBanker) {
        setDetail(currentBanker);
      }
    }
  });

  useEffect(() => {
    if (banker || isBanker()) {
      reportsSvc.get(`?date=${date}&banker=${currentBanker}&allBet=true&sales=1`)
    }
  }, [banker]);

  function onGoBack() {
    navigate(isBanker() ? Paths.home : Paths.reports);
  }

  const getUserInput = handleSubmit(async data => {
    const query = Object.entries({ ...data, sales: 1, banker: currentBanker, allBet: true }).map(([key, value]) => {
      return `${key}=${value}`
    }).join('&');

    setCurrentDate(data.date);

    await reportsSvc.get(`?${query}`);
  });

  const isLoading = reportsSvc.serviceLoading === 'get';

  const modifiedList = useMemo(() => {
    return (detail?.report?.betIds || []).filter(d => {
      return search(d.id.toString(), query)
    })
  }, [detail, query]);

  function handleUserInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value } } = e;

    setQuery(value)
  }

  function handleDisplayInfo(status?: boolean) {
    return () => {
      setDisplayInfo(prev => status || !prev);
    }
  }
  function onCloseCallback() {
    setDetailDialog(false)
    setBet({} as BetIDS)
  }

  function seeDetails(data: BetIDS) {
    return () => {
      setBet(data);
      setDetailDialog(true);
    }
  }

  return (
    <Layout
      config={{
        withSideBar: isBanker()
      }}
    >
      <CuadreDialog
        isOpen={displayCuadreDialog}
        banker={currentBanker || ''}
        date={currentDate}
        onCLose={handleDisplayCuadreDialog}
      />
      <ReportsDetailsDialog
        isOpen={openDetailDialog}
        onCLose={onCloseCallback}
        bet={bet}
      />
      <div className="p-6 bg-custom-bg flex h-full">
        {isLoading && <Loader />}
        {/* box */}
        {!isLoading && <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl gap-4'>
          <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-4 md:flex-row'>
            <TitleWithIcon
              icon={
                isBanker()
                  ? <AiFillPieChart />
                  : <button onClick={onGoBack} type='button' className='w-full'>
                    <FaArrowLeft />
                  </button>
              }
              title={isBanker() ? "Cuadre" : 'Detalle'}
            />

            <div className="flex flex-col lg:flex-row lg:gap-4 gap-2 items-center">
              <button type="button" onClick={handleDisplayInfo()} className="group p-2 border rounded-md  hover:opacity-75 hover:border-cat-blue transition-all duration-300">
                <p className="group-hover:text-cta-blue flex items-center gap-2 font-semibold transition-all duration-300"><FaEye /> {displayInfo ? 'Ocultar' : 'Ver'} información del banker</p>
              </button>
              {/*  */}
              <button onClick={handleDisplayCuadreDialog} type="button" className="group p-2 border rounded-md  hover:opacity-75 hover:border-text-green-800 transition-all duration-300">
                <p className="group-hover:text-green-800 text-green-700 flex items-center gap-2 font-semibold transition-all duration-300"><MdNote /> Cuadre</p>
              </button>
            </div>
          </div>
          {/* ---details */}
          {displayInfo && <div className="border rounded-md p-4 flex flex-col gap-2 lg:gap-4">
            {/* detail */}
            <div className="flex justify-between items-center gap-2">
              {/* name */}
              <div className="w-full lg:w-1/3">
                <h1 className="font-bold">Nombre</h1>
                <p>{detail?.user?.names} {detail?.user?.lastName}</p>
              </div>
              {/* username */}
              <div className="w-full lg:w-1/3">
                <h1 className="font-bold">Username</h1>
                <p>{detail?.user?.username}</p>
              </div>
              {/* fecha */}
              <div className="w-full lg:w-1/3">
                <h1 className="font-bold">Fecha de admisión</h1>
                <p>{getFormattedDateTime(detail?.user?.createdAt, 'year')}</p>
              </div>
            </div>
            {/* divider */}
            <hr />
            {/*  */}
            <div className="flex justify-between items-center gap-2">
              {/* coliseo */}
              <div className="w-full lg:w-1/3">
                <h1 className="font-bold">Coliseo</h1>
                <p className="flex flex-col">{(detail?.coliseum || []).map(c => c.name).join(' / ')}</p>
              </div>
              {/* email */}
              <div className="w-full lg:w-1/3">
                <h1 className="font-bold">Email</h1>
                <p>{detail?.user?.email}</p>
              </div>
              {/* Status */}
              <div className="w-full lg:w-1/3">
                <h1 className="font-bold">Estado</h1>
                <p>{detail?.user?.enabled ? 'Activo' : 'Desactivado'}</p>
              </div>
            </div>
          </div>}
          {/* search */}
          <div className='border p-4 w-full rounded-md mb-4 flex items-center gap-2 lg:gap-4 lg:flex-row flex-col'>
            <Input type='text' label='# Ticket' placeholder="1234" value={query} onChange={handleUserInput} className="w-full" /> |
            <form onSubmit={getUserInput} className='flex items-center gap-2 lg:gap-4 lg:flex-row flex-col w-full'>
              <Input {...register('date')} type='date' label='Fecha' error={errors.date?.message} />
              <CButton type='submit' className='py-2 px-4 lg:w-fit w-full'>
                <MdSearch /> Buscar
              </CButton>
            </form>
          </div>

          <div className='flex flex-grow relative'>
            <div className='absolute inset-0 overflow-y-auto'>
              <CustomTable<BetIDS>
                data={modifiedList}
                cols={[
                  {
                    field: 'id',
                    name: '# Ticket'
                  },
                  {
                    field: 'betAmount',
                    name: 'Monto apostado',
                    render: ({ data }) => {
                      return <p>{currency(data?.betAmount)}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Monto ganado',
                    render: ({ data }) => {
                      return <p>{currency(data?.earnedAmount)}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Modo de juego',
                    render: ({ data }) => {
                      return <p>{betType[data?.gameMode?.id] || ''}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Estado',
                    render: ({ data }) => {
                      return <p className={classNames('px-2 py-1 rounded-full bg-gray-200', {
                        [badgesClasses.pending]: data.status === 1,
                        [badgesClasses.won]: data.status === 2,
                        [badgesClasses.default]: data.status === 3,
                        [badgesClasses.default]: data.status === 4,
                        [badgesClasses.default]: data.status === 5
                      })}>{betStatus[data?.status] || ''}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Fecha de creación',
                    render: ({ data }) => {
                      return <div className='text-left'>
                        <p>{getFormattedDateTime((data?.createdAt || ''), 'year')}</p>
                        <p>{getFormattedDateTime((data?.createdAt || ''), 'hours')}</p>
                      </div>
                    }
                  },
                  {
                    name: 'Acciones',
                    class: 'text-center w-1/6',
                    field: '',
                    render: ({ data }) => {
                      return <div className='flex items-center justify-center w-full'>
                        <button onClick={seeDetails(data)} type='button' className='bg-cta-blue hover:bg-opacity-80 duration-300 transition-all p-1 rounded-full text-white'>
                          <IoMdEye size={24} />
                        </button>
                      </div>
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>}
      </div>
    </Layout>
  )
}
