import { CustomTable, Input, TitleWithIcon } from "src/components"
import { Layout } from "src/layouts"
import { MdLiveTv, MdSettings } from "react-icons/md";
import { type Base, useService } from "src/hooks";
import { useEffect, useMemo, useState } from "react";
import { IoMdEye } from "react-icons/io";
import { Paths, search } from "src/utils";
import { useNavigate } from "react-router-dom";
import { useGames, useColiseum } from "src/store";
import { RiLiveLine } from "react-icons/ri";
import { isEmpty } from "lodash";
import { ConfigDialog } from "./ConfigDialog";
import { SubscriptorContainer } from 'src/container';

export const LiveGamesPage = () => {
  const { setColiseum, coliseum: coliseums } = useColiseum();
  const { games, setgames } = useGames();
  const [query, setQuery] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);

  const coliseumsId = games.map((game) => game.coliseum.id);
  const handleConfigDialog = (status: boolean) => () => setIsOpen(status);

  const navigate = useNavigate();

  const modifiedList = useMemo(() => {
    return coliseums.filter((battle) => coliseumsId.includes(battle.id)).filter(coliseum => {
      return search(coliseum.name, query) || search(coliseum.country, query) || search(coliseum.city, query);
    })
  }, [coliseums, query, coliseumsId, games]);

  const coliseumsSvc = useService<Base<coliseum[]>>({
    route: 'coliseums',
    data: res => {
      if (res) {
        setColiseum(res.data);
      }
    }
  });

  const todyGamesSvc = useService<Game[]>({
    route: 'todayGames',
    data: res => {
      if (res) {
        setgames(res);
      }
    }
  });

  useEffect(() => {
    coliseumsSvc.get()
    todyGamesSvc.get()
  }, []);

  function goToDetailsPage(id: number) {
    return () => navigate(`${Paths.liveGames}/${id}`)
  }

  function gotoToDisplayAll() {
    const [coliseum] = modifiedList;

    if (!coliseum) return;

    navigate(`${Paths.liveGames}/${coliseum.id}?display-all=true`)
  }

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <SubscriptorContainer>
        <div className="p-6 bg-custom-bg flex h-full">
          <ConfigDialog
            isOpen={isOpen}
            onClose={handleConfigDialog(false)}
          />
          {/* box */}
          <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
            <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-2 md:flex-row'>
              <TitleWithIcon
                icon={<MdLiveTv />}
                title="Juegos en vivo"
              />

              <div className="flex flex-wrap gap-4 items-center">
                {/*  */}
                <button
                  type="button"
                  onClick={handleConfigDialog(true)}
                  className="group flex items-center gap-2 rounded-full p-2 text-white bg-gray-600 active:bg-gray-500 hover:opacity-90 transition-all duration-100">
                  <MdSettings className="group-hover:animate-spin" size={24} />
                </button>
                {/*  */}
                <button
                  disabled={isEmpty(modifiedList)}
                  type="button"
                  onClick={gotoToDisplayAll}
                  className="disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 px-4 py-2 rounded-full flex gap-2 items-center bg-red-600 hover:opacity-90 active:bg-red-500 text-white">
                  <RiLiveLine /> <h1>Ver todos</h1>
                </button>
                {/* search */}
                <label className='flex bg-white items-center rounded-md'>
                  <Input
                    label='Buscar coliseo'
                    type='text'
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    className='p-2 outline-none'
                    placeholder="Nombre, Ciudad o País"
                  />
                </label>
              </div>
            </div>

            <div className='flex flex-grow relative'>
              <div className='absolute inset-0 overflow-y-auto'>
                <CustomTable<coliseum>
                  isLoading={coliseumsSvc.serviceLoading === 'get'}
                  data={modifiedList}
                  cols={[
                    {
                      field: 'name',
                      name: 'Nombre'
                    },
                    {
                      field: 'city',
                      name: 'Ciudad'
                    },
                    {
                      field: 'country',
                      name: 'País'
                    },
                    {
                      field: '',
                      name: 'Acciones',
                      render({ data }) {
                        return <div className='flex items-center justify-start w-full'>
                          <button onClick={goToDetailsPage(data.id)} type='button' className='bg-cta-blue flex items-center hover:bg-opacity-80 duration-300 transition-all p-1 px-2 gap-2 rounded-full text-white'>
                            <IoMdEye />
                            <p>Ver peleas</p>
                          </button>
                        </div>
                      }
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </SubscriptorContainer>
    </Layout>
  )
}
