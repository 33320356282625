import { CButton, Drawer, Input } from "src/components"
import { type CancelBetDetailsType, type CancelBetType } from "../../../appTypes/CancelBetType";
import { betStatusType, betType, currency, generateFighterInfo, generateKey, getActualFighter, getFormattedDateTime } from "src/utils";
import { get, isEmpty } from "lodash";
import { useService, type Base } from "src/hooks";
import { useEffect, useState } from "react";
import { Loader } from "src/layouts";
import { MdError } from "react-icons/md";

export const ValidateTicketDialog: React.FC<CancelBetsProps> = (props) => {
  const { isOpen, onCLose, bet } = props;
  const [betDetails, setBetDetails] = useState<CancelBetDetailsType[]>([]);
  const [code, setCode] = useState<string>('');
  const [isValid, setIsValid] = useState<{ displayError: boolean, called: boolean, valid?: boolean }>({ displayError: false, called: false });

  const betDetailsSvc = useService<Base<CancelBetDetailsType[]>>({
    route: 'betDetails',
    data: (res) => {
      setBetDetails(res.data);
    }
  });

  const handleClose = () => {
    onCLose();
    betDetailsSvc.clearError();
    setBetDetails([]);
    setCode('')
    setIsValid({
      displayError: false,
      called: false
    });
  };

  const betBillsSvc = useService({
    route: 'bills',
    data: res => {
    }
  });

  const betSvc = useService({
    route: 'bets',
    data: async (res) => {
      const displayError = isEmpty(get(res, 'data', []) || []);
      setIsValid({
        displayError,
        called: true,
        valid: !displayError
      });

      if (!displayError) {
        // call
        betBillsSvc.update({
          id: bet.id,
          data: {
            status: 2,
            bet: bet.id
          }
        })
      }
    }
  });

  useEffect(() => {
    if (bet?.id) {
      betDetailsSvc.get(`/?bet=${bet.id}`)
    }
  }, [bet]);

  const onValidate = () => {
    betSvc.get(`?id=${bet.id}&verificationCode=${code}`);
  }

  function getUserInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value } } = e;

    setCode(value)
  }
  const isLoading = betSvc.serviceLoading === 'get';
  const loadingContent = betDetailsSvc.serviceLoading === 'get';

  const total = ((+bet?.betAmount || 0) + (+bet.earnedAmount || 0)).toString();

  const hasError = () => {
    const errors: Record<string, boolean> = {};

    if (!code) {
      errors.isEmpty = true
    }

    if (isNaN(+code)) {
      errors.notValidNumber = true;
    }

    if (betDetailsSvc.apiError) {
      errors.apiError = true;
    };

    const isValid = Object.values(errors).every(error => !error);

    return !isValid;
  }

  return (
    <Drawer title="Detalles de la apuesta" isOpen={isOpen} onClose={handleClose}>
      <div className="flex flex-grow flex-col bg-gray-50">
        <div className="flex-1 relative flex justify-center items-center">
          {betDetailsSvc.apiError && !bet && <div className="text-center flex items-center justify-center flex-col gap-2">
            <MdError size={42} className="text-red-600"/>
            <h1 className="font-bold text-red-800">No fue posible cargar la información</h1>
          </div>}
          {loadingContent && !betDetailsSvc.apiError && <Loader />}
          {!loadingContent && !betDetailsSvc.apiError && <div className="absolute inset-0 overflow-y-auto p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-6">
              {/*  */}
              <div>
                <h1>Arriesgando:</h1>
                <p className="font-bold">{currency(bet?.betAmount || '0', 'USD')}</p>
              </div>
              {/*  */}
              <div>
                <h1>A ganar:</h1>
                <p className="font-bold">{currency(bet?.earnedAmount || '0', 'USD')}</p>
              </div>
              {/*  */}
              <div>
                <h1>Estado:</h1>
                <p className="font-bold">{betStatusType[bet?.status]}</p>
              </div>
              {/*  */}
              <div>
                <h1>Tipo:</h1>
                <p className="font-bold">{betType[bet?.gameMode?.id] || 0}</p>
              </div>

              <div>
                <h1>Coliseo:</h1>
                <p className="font-bold">{(bet?.betDetails?.length && bet?.betDetails[0]?.game?.coliseum?.name) || ''}</p>
              </div>

              <div>
                <h1>Fecha:</h1>
                <div className="font-bold flex flex-col">
                  <p>{getFormattedDateTime(bet.createdAt)}</p>
                </div>
              </div>

              <div>
                <h1>Total:</h1>
                <p className="font-bold">{currency(total, 'USD')}</p>
              </div>
            </div>
            <Input
                label="Código de verificación"
                error={(!isLoading && isValid.displayError && isValid.called) ? "El código proporcionado no coincide con nuestros registros." : undefined}
                value={code}
                onChange={getUserInput}
                className={isValid.valid ? 'border-green-700' : ''}
            />
            {/* Details */}
            <h1>Detalles:</h1>
            <ul className="flex flex-col gap-4">
              {
                !isEmpty(betDetails) && betDetails.map(e => {
                  return <li className='p-4 border rounded-md bg-white' key={generateKey(e.id, e.betType.name)}>
                    <div className="border-b pb-4">
                      <p className="text-sm">
                        <span className="">
                          <b>#{e?.game?.fightNumber || 0}</b> |</span>{" "}
                        {e?.game?.fighters[0]?.team.name || ''}{" "}
                        <span className="text-red-600 font-bold">VS</span>{" "}
                        {e?.game?.fighters[1]?.team.name || ''}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 py-2">
                      {/* game type */}
                      <p>
                        <b>Tipo:</b> {e?.betType?.name || ''}
                      </p>
                      {/* playing to */}
                      <p>
                        <b>Jugando a:</b> {generateFighterInfo(e, e.fighter?.id)}
                      </p>

                      <p>
                        <b>Estado del juego:</b> {betStatusType[e.game?.status]}
                      </p>
                      {/* playing to */}
                      <p>
                        <b>Ganador:</b> {getActualFighter(e.game?.fighters, e.fighter?.id)?.isWinner ? getActualFighter(e.game?.fighters, e.fighter?.id)?.team.name : 'N/A'}
                      </p>
                    </div>
                  </li>
                })
              }
            </ul>
            {/*  */}
          </div>}
        </div>
        <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
          <CButton type='button' className="w-fit bg-transparent group p-3" onClick={handleClose}>
            <p className="text-cta-blue font-bold group-hover:opacity-80 transition-all duration-300">
              Cancelar
            </p>
          </CButton>

          <CButton
            type='submit'
            className="w-fit p-3 px-6 bg-cta-blue enabled:hover:bg-opacity-80 font-bold"
            onClick={onValidate}
            busy={isLoading || loadingContent}
            disabled={hasError()}
          >
            Validar
          </CButton>
        </div>
      </div>
    </Drawer>
  )
};

interface CancelBetsProps {
  isOpen: boolean
  onCLose: () => void
  bet: CancelBetType
  onRefresh: () => void
}
