import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const schema = Yup.object({
  banker: Yup.number().typeError('Un banker es necesario').notRequired(),
  date: Yup.string().required('Este campo es requerido')
});

const detailSchema = Yup.object({
  date: Yup.string().required('Este campo es requerido')
});

export const queryResolver = yupResolver(schema);
export const queryResolverDetail = yupResolver(detailSchema);

export interface QueryForm {
  banker: number
  date: string
};
