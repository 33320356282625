import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { NoResult } from 'src/components';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  function onNavigate() {
    navigate('/');
  }
  return (
    <div className="h-screen flex items-center justify-center">
        <NoResult
            message='Ups!'
            desc='No hay nada que mostrar aqui!'
        >
            <button
             type='button'
             onClick={onNavigate}
             className='p-4 rounded-lg bg-[#181E5C] text-white flex gap-2 items-center group transition-all duration-300'>
                <FaArrowLeft />
                <p>Volver</p>
            </button>
        </NoResult>
    </div>
  )
}
