import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Logo_heahde from 'src/assets/Logo_heahde.svg';
import { Link } from 'react-router-dom';
import { protectedRoutes } from 'src/router';
import { useIsAuth } from 'src/store';
import { generatePath } from 'src/utils';
import { useSidebarActions } from 'src/hooks';
import { MdOutlineOpenInNew } from 'react-icons/md';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
interface props {
  sidebarOpen: boolean
  setSidebarOpen: (value: boolean) => void
  onLogout: () => void
}
export function SideMenu({ sidebarOpen, setSidebarOpen, onLogout }: props) {
  const { auth } = useIsAuth();
  const { getAction } = useSidebarActions();

  const generateLink = (item: typeof protectedRoutes[0]) => {
    return <Link
      key={item.key}
      to={generatePath(item.path)}
      className={classNames(
        'text-indigo-100 hover:bg-indigo-600',
        'group flex items-center rounded-md px-2 py-2 text-base font-medium gap-4'
      )}
    >
      {item.icon} {item.label}
    </Link>
  }

  const generateAction = (route: typeof protectedRoutes[0]) => {
    return <button className={classNames(
      'text-indigo-100 hover:bg-indigo-600 w-full',
      'group flex items-center rounded-md px-2 py-2 text-base font-medium gap-4'
    )} onClick={getAction(route.action)}>
      {route.icon} {route.label} <MdOutlineOpenInNew />
    </button>
  }
  return (
    <>
      <div className=''>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-[#0B0E2C] pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src={Logo_heahde}
                      alt="Your Company"
                    />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {protectedRoutes
                        .filter(e => e.roles?.includes(auth.role))
                        .filter(route => route.icon && route.label)
                        .filter(e => !e.hide)
                        .map((item) => (
                          item.action ? generateAction(item) : generateLink(item)
                        ))}
                    </nav>

                    <div className="fixed bottom-0 w-full bg-[#0b0e2ca3] px-4 py-2">
                      <hr />

                      <button
                        type='button'
                        className="BadgeTextSideMenu w-full hover:text-gray-400 focus:outline-none text-center mt-2"
                        onClick={onLogout}
                      >
                        Cerrar sesión
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
              </div>
            </div>
          </Dialog>
        </Transition.Root>

      </div>
    </>
  )
}
