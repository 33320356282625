export const Paths = {
  home: '/',
  transactions: '/transacciones',
  history: '/historial',
  historyDetails: '/historial/:date',
  pending: '/apuestas-pendientes',
  userCrud: '/usuarios',
  betSettings: '/ajustes-de-apuesta',
  cancelBets: '/cancelar-apuestas',
  validateTickets: '/validar-ticket',
  reports: '/reportes',
  action: '/action',
  invoice: '/facturas',
  accountStatus: '/estado-de-cuentas',
  players: '/jugadores',
  cuadre: '/cuadre',
  liveGames: '/juegos-en-vivo'
}
