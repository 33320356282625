import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { WebSocket } from "ws";

import { createClient } from 'graphql-ws';

import { getStorageValue } from './utils/storage';
import { APP_STORAGE_KEY } from './utils/Constant';
import { env } from "./env";

const WS_ULR = env.REACT_APP_GRAPHQL_SUBSCRIPTION || '';

const { token } = getStorageValue<{ token: string }>(APP_STORAGE_KEY);

let activeSocket: any, timedOut: NodeJS.Timeout;

const xClient = createClient({
  webSocketImpl: WebSocket,
  url: WS_ULR,
  connectionParams: {
    authToken: token
  },
  keepAlive: 10_000,
  on: {
    connected: (socket) => (activeSocket = socket),
    ping: (received) => {
      if (!received) {
        timedOut = setTimeout(() => {
          if (activeSocket.readyState === WebSocket.OPEN) {
            activeSocket.close(4408, 'Request Timeout');
          }
        }, 5_000);
      }
    },
    pong: (received) => {
      if (received) {
        clearTimeout(timedOut);
      }
    }
  }
})

const wsLink = new GraphQLWsLink(xClient);

export const client = new ApolloClient({
  link: wsLink,
  cache: new InMemoryCache()
});
