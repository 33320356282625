export const APP_STORAGE_KEY = '@cock-fight-admin';

// weeks;
export const CURRENT_WEEK = 'CURRENT_WEEK';
export const LAST_WEEK = 'LAST_WEEK';
export const TREE_WEEKS_BACK = 'TREE_WEEKS_BACK';

// bet type
export const betType: Record<number, string> = {
  1: 'Directo',
  2: 'Parlay'
} as const;

export const userRole: Record<number, string> = {
  1: 'Admin',
  2: 'Player',
  6: 'Banker'
} as const;

export const betStatus: Record<number, string> = {
  1: 'Pendiente', // yellow
  2: 'Ganado', // green
  3: 'Perdido', // soft red
  4: 'Cancelado', // red
  5: 'Anulado' // dark red
} as const;

export const betStatusType: Record<number, string> = {
  1: 'Pendiente',
  2: 'En progreso',
  3: 'Finalizado',
  4: 'Cancelado',
  5: 'Cerrado',
  6: 'Tabla'
} as const;

export const betStatusIds = {
  pending: 1,
  inProgress: 2,
  finished: 3,
  canceled: 4,
  closed: 5,
  tabla: 6
} as const;

export const ROLES = {
  admin: 1,
  banker: 6,
  player: 2
} as const;

export const BET_TYPES = {
  parlay: 2,
  directo: 1
}

export const days: Record<number, string> = {
  0: 'Domingo',
  1: 'Lunes',
  2: 'Martes',
  3: 'Miércoles',
  4: 'Jueves',
  5: 'Viernes',
  6: 'Sábado'
} as const;

export const months: Record<number, string> = {
  0:'Enero',
  1:'Febrero',
  2:'Marzo',
  3:'Abril',
  4:'Mayo',
  5:'Junio',
  6:'Julio',
  7:'Agosto',
  8:'Septiembre',
  9:'Octubre',
  10:'Noviembre',
  11:'Diciembre'
} as const;

export * from './RoutesPaths';

export enum invoiceTypeEnum {
  PROFITS = 1,
  LOSS = 2,
}
export enum invoiceStatusEnum {
  PENDING = 1,
  PAID = 2,
  ANNULLED = 3,
}

export const invoiceType = {
  1: 'Ganancias',
  2: 'Perdidas'
} as const;

export const invoiceStatus = {
  1: 'Pendiente',
  2: 'Pagado',
  3: 'Anulado'
} as const;

export const badgesClasses = {
  pending: "text-yellow-700 bg-yellow-200",
  won: "text-green-600 font-bold bg-green-200",
  default: "text-gray-600 font-bold bg-gray-200"
};
