import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { CButton, CustomTable, Input, TitleWithIcon } from 'src/components'
import { type Base, useService } from 'src/hooks'
import { Layout } from 'src/layouts'
import { Paths, currency, getFormattedDateTime } from 'src/utils'
import { IoMdEye } from 'react-icons/io'
import { type ReportsTypes, type Banker } from 'src/appTypes'
import { MdBarChart, MdSearch } from 'react-icons/md'
import { Controller, useForm } from 'react-hook-form'
import { type QueryForm, queryResolver } from './validations'
import Select from "react-select";
import { useNavigate } from 'react-router-dom'

export const ReportsPage = () => {
  const date = getFormattedDateTime(new Date(), 'year');
  const [bets, setBets] = useState<ReportsTypes[]>([]);
  const [bankers, setBanker] = useState<Banker[]>([]);
  const [currentDate, setCurrentDate] = useState<string>(date);
  const { register, handleSubmit, formState: { errors }, control } = useForm<QueryForm>({
    resolver: queryResolver,
    defaultValues: {
      date
    }
  });
  const navigate = useNavigate();

  const reportSvc = useService<ReportsTypes[]>({
    route: 'reports',
    data: res => {
      if (!isEmpty(res)) {
        setBets(res);
      }
    }
  });

  const bankerSvc = useService<Base<Banker[]>>({
    route: 'bankers',
    data: res => {
      if (!isEmpty(res.data)) {
        setBanker(res.data);
      };
    }
  });

  useEffect(() => {
    reportSvc.get(`?date=${date}&sale=1`);
  }, []);

  useEffect(() => {
    if (isEmpty(bankers)) {
      bankerSvc.get();
    }
  }, [bankers]);

  const bankerOptions = bankers.map(b => ({ value: b.id, label: b.user.names }));

  const getUserInput = handleSubmit(async data => {
    const query = Object.entries({ ...data, sales: 1 }).map(([key, value]) => {
      return `${key}=${value}`
    }).join('&');

    setCurrentDate(data.date);

    await reportSvc.get(`?${query}`);
  });

  function gotoDetailsPage(id: number) {
    return () => {
      navigate(`${Paths.reports}/${id}?date=${currentDate}`);
    }
  }

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <div className="p-6 bg-custom-bg flex h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
          <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-4 md:flex-row'>
            <TitleWithIcon
              icon={<MdBarChart />}
              title='Reportes'
            />
          </div>

          <div className='border p-4 w-full rounded-md mb-4'>
            <form onSubmit={getUserInput} className='flex items-center gap-2 lg:gap-4 lg:flex-row flex-col'>
              <label className="w-full flex flex-col">
                <p className='text-gray-500 font-bold'>Banker</p>
                <Controller
                  name="banker"
                  control={control}
                  render={({ field }) => <Select
                    {...field}
                    isSearchable
                    onChange={e => field.onChange(e?.value)}
                    value={bankerOptions.find((e: any) => e.value === field.value)}
                    options={bankerOptions}
                  />}
                />
                <p className='text-red-500 py-2 font-medium'>{errors.banker?.message}</p>
              </label>

              <Input {...register('date')} type='date' label='Fecha' error={errors.date?.message} />

              <CButton type='submit' className='py-2 px-4 lg:w-fit w-full'>
                <MdSearch /> Buscar
              </CButton>
            </form>
          </div>

          <div className='flex flex-grow relative'>
            <div className='absolute inset-0 overflow-y-auto'>
              <CustomTable<ReportsTypes>
                isLoading={reportSvc.serviceLoading === 'get'}
                data={bets}
                cols={[
                  {
                    field: '',
                    name: 'Banker',
                    class: 'text-left w-1/6',
                    render: ({ data }) => {
                      return <div className='capitalize'>{data?.user?.username}</div>
                    }
                  },
                  {
                    field: '',
                    name: 'Coliseos',
                    class: 'text-left w-1/2',
                    render: ({ data }) => {
                      return <p className='text-left'>{(data?.coliseum || []).map(c => c.name).join(', ') || ''}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Vendido',
                    render: ({ data }) => {
                      return <p>{currency(data?.report?.totalSold.toString(), 'USD')}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Pagado',
                    render: ({ data }) => {
                      return <p>{currency(data?.report?.paidBetBill.toString(), 'USD')}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Deposito',
                    render: ({ data }) => {
                      return <p>{currency(data?.report?.deposit.toString(), 'USD')}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Retiro',
                    render: ({ data }) => {
                      return <p>{currency(data?.report?.withdrawal.toString(), 'USD')}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Balance',
                    render: ({ data }) => {
                      return <p>{currency(data?.report?.cashBClear.toString(), 'USD')}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Acciones',
                    class: 'text-center w-1/6',
                    render: ({ data }) => {
                      return <div className='flex items-center justify-center w-full'>
                        <button onClick={gotoDetailsPage(data.id)} type='button' className='bg-cta-blue hover:bg-opacity-80 duration-300 transition-all p-1 rounded-full text-white'>
                          <IoMdEye size={24} />
                        </button>
                      </div>
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
