import { useSubscription } from "@apollo/client";
import { get, isEmpty, merge } from "lodash";
import { useEffect } from "react";
import { LISTEN_TO_GAMES, LISTEN_TO_TODAY_GAMES, LIST_TO_FIGHTERS } from 'src/graphql';
import { useGames } from "src/store";
import { useAnimation, useService } from "src/hooks";

const ONE_MINUTE = 60_000;

export const SubscriptorContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { data } = useSubscription(LISTEN_TO_GAMES);
  const { data: newTodayGame } = useSubscription(LISTEN_TO_TODAY_GAMES);
  const { data: fighter } = useSubscription<FighterSuscriptor>(LIST_TO_FIGHTERS);
  const { games, setgames } = useGames();
  const { handleAnimation } = useAnimation();

  const gamesSvc = useService<Game[]>({
    route: 'todayGames',
    data: res => {
      if (res) {
        setgames(res);
      }
    }
  });

  useEffect(() => {
    if (data) {
      const currentGame: Game = data.listenGames;
      const filteredGamesList = games.filter((game) => game.id !== currentGame.id).filter(g => g.status === 1);
      const filteredGames = games.find((game) => game.id === currentGame.id) || {} as any;

      handleAnimation(currentGame.id, {
        duration: ONE_MINUTE
      });

      if (filteredGames && !isEmpty(get(filteredGames, 'fighters', []) || [])) {
        setgames([...filteredGamesList, merge(filteredGames, currentGame)].filter(g => g.status === 1));
      } else {
        void gamesSvc.get();
      }
    }
  }, [data]);

  useEffect(() => {
    if (newTodayGame && !games.find((g) => g.id === newTodayGame.listenTodayGames.id)) {
      setgames([...games, newTodayGame.listenTodayGames]);
    }
  }, [newTodayGame]);

  useEffect(() => {
    if (fighter?.listenFighters && fighter.listenFighters.game) {
      const deliveredGame = games.find(g => g.id === fighter.listenFighters.game.id);

      if (deliveredGame) {
        const filteredGamesList = games.filter((game) => game.id !== deliveredGame.id);

        const { fighters } = deliveredGame;
        const currentFighter = fighters.find(f => f.id === +fighter.listenFighters.id);

        if (currentFighter) {
          const fighterUpdate: Fighter = {
            ...currentFighter,
            betPercent: fighter.listenFighters?.betPercent?.toString(),
            favorite: fighter.listenFighters.favorite
          };

          const filteredFighter = fighters.filter(f => f.id !== currentFighter.id);

          const updatedList = [...filteredFighter, fighterUpdate];

          const payload = {
            ...deliveredGame,
            fighters: updatedList
          };

          setgames([...filteredGamesList, payload]);

          handleAnimation(deliveredGame.id, { type: 'pulse', duration: ONE_MINUTE });
        }
      }
    }
  }, [fighter])

  return (
    <>{children}</>
  )
}
