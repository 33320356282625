import classNames from "classnames"
import { Drawer } from "src/components"
import { currency, getFormattedDateTime, invoiceStatus, invoiceType } from "src/utils"
import { PiChartLineDown, PiChartLineUp } from 'react-icons/pi';

export const InvoiceDetailsDrawer: React.FC<InvoiceDetailsDrawerProps> = ({ isOpen, onClose, data }) => {
  return (
        <Drawer isOpen={isOpen} onClose={onClose} title="Detalles">
            <div className="flex-grow p-4 flex flex-wrap self-center w-full flex-col">
                <div className="flex items-center">
                    {/* invoice type */}
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Tipo de factura:</h1>
                        <p className="font-bold">{invoiceType[data.invoiceType as 1 | 2] || ''}</p>
                    </div>
                    {/* Status */}
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Estado:</h1>
                        <p className={classNames("font-semibold py-1 px-2 rounded-lg w-fit",
                          { "text-yellow-700 bg-yellow-200": data.invoiceStatus === 1 },
                          { "text-green-600 font-bold bg-green-200": data.invoiceStatus === 2 },
                          { "text-red-700 bg-red-200": data.invoiceStatus === 3 }
                        )}>{invoiceStatus[data.invoiceStatus as 1 | 2 | 3] || ''}</p>
                    </div>
                </div>

                {/* date */}
                <div className="flex items-center">
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Fecha:</h1>
                        <p className="font-bold">{getFormattedDateTime(data.createdAt, 'year')}</p>
                    </div>
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Apuestas:</h1>
                        <p className="font-bold">{data.countBets || 0}</p>
                    </div>
                </div>
                <hr />
                {/* totals */}
                <div className="flex items-center">
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Total vendido:</h1>
                        <p className="font-bold">{currency((data.totalSold || 0).toString())}</p>
                    </div>
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Balance de la semana:</h1>
                        <p className="font-bold">{currency((data.balanceWeek || 0).toString())}</p>
                    </div>
                </div>
                {/*  */}
                <div className="flex items-center">
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Total ganado:</h1>
                        <div className="flex items-center gap-2 text-green-600 font-bold">
                            <PiChartLineUp className="text-xl" />
                            {currency((data.totalWin || 0).toString())}
                        </div>
                    </div>

                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Total perdido:</h1>
                        <div className="flex items-center gap-2 text-red-600 font-bold">
                            <PiChartLineDown className="text-xl" />
                            {currency((data.totalLost || 0).toString())}
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="flex items-center">
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Total Recargado:</h1>
                        <p className="font-bold">{currency((data.totalRecharge || 0).toString())}</p>
                    </div>
                    {/*  */}
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Total Retirado:</h1>
                        <p className="font-bold">{currency((data.totalWithdrawal || 0).toString())}</p>
                    </div>
                </div>
                <hr />
                {/*  */}
                <div className="flex items-center">
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Cuadre socio:</h1>
                        <p className="font-bold">{currency((data.balanceRunner || 0).toString())}</p>
                    </div>
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Cuadre Central:</h1>
                        <p className="font-bold">{currency((data.balanceCenter || 0).toString())}</p>
                    </div>
                </div>
                {/*  */}
                <hr />
                {/*  */}
                <div className="flex items-center">
                    <div className="w-1/2">
                        <h1 className="font-medium text-gray-500">Porcentaje Aplicado:</h1>
                        <p className="font-bold">{data.percentageApplied || 0}</p>
                    </div>
                </div>
            </div>
        </Drawer>
  )
}

interface InvoiceDetailsDrawerProps {
  data: RunnersInvoices
  isOpen: boolean
  onClose: () => void
};
