import { useEffect, useMemo, useState } from 'react'
import { IoMdPaper } from 'react-icons/io'
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md'
import { CButton, CustomTable, Notification, TitleWithIcon, type NotificationProps } from 'src/components'
import { useService, type Base } from 'src/hooks'
import { Layout } from 'src/layouts'
import { BET_TYPES, betType, currency, userRole } from 'src/utils'
import { BetSettingsDialog } from './BetSettingsDialog'
import { isEmpty, pick } from 'lodash'

export const BetSettings = () => {
  const [betSettings, setBetSettings] = useState<BetSettings[]>([]);
  const [betSetting, setBetSetting] = useState<BetSettings>({} as BetSettings);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState<NotificationProps>({
    title: 'Estas seguro que deseas eliminar esta configuración?',
    type: 'delete',
    actions: [],
    visible: false,
    onDismiss: () => {}
  });

  const betSettingsSvc = useService<Base<BetSettings[]>>({
    route: 'betSettings',
    data: async ({ data }, svc) => {
      if (svc === 'get') {
        setBetSettings(data);
      }

      if (svc === 'remove') {
        await betSettingsSvc.get();
      }
    }
  });

  useEffect(() => {
    betSettingsSvc.get();
  }, []);

  useEffect(() => {
    if (betSettingsSvc.apiError) {
      betSettingsSvc.clearError();
    }
  }, [betSettingsSvc.apiError])

  const handleEdit = (data: BetSettings) => {
    return () => {
      setBetSetting(data);
      setIsOpen(true);
    }
  }

  const onCloseRefresh = () => {
    betSettingsSvc.get();
  }

  const validToCreate = useMemo(() => {
    const playerSettings = betSettings.filter(b => b.userRole === 2);
    const bankerSettings = betSettings.filter(b => b.userRole === 6);

    const valid = {
      canAddMorePlayers: true,
      canAddMoreBankers: true
    }

    const getSettings = (values: BetSettings[]) => values.filter(b => b.gameMode.id === BET_TYPES.directo || b.gameMode.id === BET_TYPES.parlay)

    if (!isEmpty(playerSettings)) {
      valid.canAddMorePlayers = (getSettings(playerSettings).length < 2);
    }

    if (!isEmpty(bankerSettings)) {
      valid.canAddMoreBankers = (getSettings(bankerSettings).length < 2);
    }

    const overall = Object.values({ ...pick(valid, ['canAddMoreBankers', 'canAddMorePlayers']) }).some(v => v);

    return {
      ...valid,
      overall
    }
  }, [betSettings])

  function onDelete(id: number) {
    return () => {
      setNotification(prev => ({
        ...prev,
        visible: true,
        actions: [
          {
            action: () => {
              setNotification(prev => ({ ...prev, visible: false, actions: [] }))
            },
            label: 'Cancelar',
            type: 'none'
          },
          {
            action: async() => {
              await betSettingsSvc.remove({ id });
              setNotification(prev => ({ ...prev, visible: false, actions: [] }));
            },
            label: 'Remover',
            type: 'remove'
          }
        ]
      }))
    };
  }

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <BetSettingsDialog
        settings={betSettings}
        isOpen={isOpen}
        betSetting={betSetting}
        onCloseRefresh={onCloseRefresh}
        onClose={() => setIsOpen(false)} />
      <Notification
        {...{
          ...notification,
          onDismiss: () => {
            setNotification(prev => ({ ...prev, visible: false, actions: [] }))
          }
        }}
      />

      <div className="p-6 bg-custom-bg flex h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
          <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-2 md:flex-row'>
            <TitleWithIcon
              icon={<IoMdPaper />}
              title='Apuestas'
            />

            <button
              type='button'
              className='flex gap-2 items-center group disabled:cursor-not-allowed disabled:opacity-75'
              onClick={() => setIsOpen(true)}
              disabled={!validToCreate.overall}
            >
              <div className='p-2 rounded-[300rem] bg-cta-blue text-white enabled:group-hover:bg-opacity-60 transition-all duration-300'>
                <MdAdd size={24} />
              </div>
              <h1 className='font-medium enabled:group-hover:opacity-60 duration-300 transition-all'>Crear Ajuste</h1>
            </button>
          </div>

          <div className='flex flex-grow relative'>
            <div className='absolute inset-0'>
              <CustomTable<BetSettings>
                data={betSettings}
                className='h-full'
                isLoading={betSettingsSvc.serviceLoading === 'get'}
                cols={[
                  {
                    field: '',
                    name: 'Tipo de juego',
                    render: ({ data }) => {
                      return <div className='flex items-start gap-2'>
                        {betType[data.gameMode?.id || 0]}
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Role',
                    render: ({ data }) => {
                      return <div className='flex items-start gap-2'>
                        {userRole[data.userRole]}
                      </div>
                    }
                  },
                  {
                    field: 'settings',
                    name: 'Ajustes',
                    render: ({ data }) => {
                      return <div className='flex flex-col items-start gap-2'>
                        {data.settings.map(setting => <p key={setting.max}>{currency(setting.max.toString(), 'USD')}</p>)}
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Acciones',
                    render: ({ data }) => {
                      return <div className='flex gap-2 items-center'>
                        <CButton
                          onClick={handleEdit(data)}
                          className='bg-transparent p-[0.8rem] rounded-[300rem] group hover:bg-cta-blue hover:bg-opacity-75 transition-all'>
                          <MdEdit className='group-hover:text-white text-cta-blue transition-all' size={24} />
                        </CButton>

                        <CButton
                          onClick={onDelete(data.id)}
                          className='bg-transparent p-[0.8rem] rounded-[300rem] group hover:bg-red-700 hover:bg-opacity-75 transition-all'>
                          <MdDelete className='group-hover:text-white text-red-700 transition-all' size={24} />
                        </CButton>
                      </div>
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
