import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const userSchema = Yup.object({
  balance: Yup.number().min(0, 'El mínimo es cero').typeError('Es necesario un numero igual o mayor que cero').required('Este campo es requerido'),
  email: Yup.string().email('Por favor inserte un email valido').required('Este campo es requerido'),
  names: Yup.string().required('Este campo es requerido'),
  surNames: Yup.string().required('Este campo es requerido'),
  //   password: Yup.string().required('Este campo es requerido'),
  // runner: Yup.number().typeError('Un runner es requerido').required('Este campo es requerido'),
  cardId: Yup.string().required('Este campo es requerido')
});

export const resolver = yupResolver(userSchema);

export interface UserForm {
  balance: string
  cardId: string
  email: string
  names: string
  //   password: string
  // runner: number
  surNames: string
}
