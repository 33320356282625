import { create } from 'zustand'

interface State {
  auth: { isReady: boolean, role: number }
  displayGlobalModal: SidebarAction | 'none'
  currentAnimation: {
    id: number
    type: 'loader' | 'pulse'
  }
  globalMessage: string
  betType: number
  isModalOpen: boolean
  isModalOpenParlay: boolean
  selectedColiseum: number
  coliseum: coliseum[]
  games: Game[]
  selectedBet: Bet | null
  history: any[]
  Banks: any[]
  pendingBets: PendingBet[]
  balance: Balance | null
  selectedParlay: selectedParlay[]
  setColiseum: (coliseum: State['coliseum']) => void
  setCurrentAnimation: (currentAnimation: State['currentAnimation']) => void
  setgames: (games: State['games']) => void
  setSelectedBet: (selectedBet: State['selectedBet']) => void
  sethistory: (history: State['history']) => void
  setBanks: (Banks: State['Banks']) => void
  setAuth: (auth: State['auth']) => void
  setIsModalOpenParlay: (isModalOpenParlay: State['isModalOpenParlay']) => void
  setBetType: (betType: State['betType']) => void
  setPendingBets: (pendingBets: State['pendingBets']) => void
  setModalOpen: (isModalOpen: State['isModalOpen']) => void
  setSelectedColiseum: (selectedColiseum: State['selectedColiseum']) => void
  setBalance: (balance: State['balance']) => void
  setSelectedParlay: (selectedParlay: State['selectedParlay']) => void
  setGlobalMessage: (message?: State['globalMessage']) => void
  setDisplayGlobalModal: (displayGlobalModal: State['displayGlobalModal']) => void
}

const useStore = create<State>((set) => ({
  auth: { isReady: false, role: 0 },
  displayGlobalModal: 'none',
  isModalOpen: false,
  isModalOpenParlay: false,
  currentAnimation: {
    id: 0,
    type: 'loader'
  },
  selectedColiseum: 0,
  coliseum: [],
  games: [],
  selectedBet: null,
  betType: 1,
  history: [],
  Banks: [],
  pendingBets: [],
  balance: null,
  selectedParlay: [],
  globalMessage: '',
  setColiseum: (coliseum) => { set({ coliseum }); },
  setgames: (games) => { set({ games }); },
  setCurrentAnimation: (currentAnimation) => { set({ currentAnimation }); },
  setSelectedBet: (selectedBet) => { set({ selectedBet }); },
  sethistory: (history) => { set({ history }); },
  setBanks: (Banks) => { set({ Banks }); },
  setModalOpen: (isModalOpen) => { set({ isModalOpen }); },
  setIsModalOpenParlay: (isModalOpenParlay) => { set({ isModalOpenParlay }); },
  setAuth: (auth) => { set({ auth }); },
  setBetType: (betType) => { set({ betType }); },
  setPendingBets: (pendingBets) => { set({ pendingBets }); },
  setSelectedColiseum: (selectedColiseum) => { set({ selectedColiseum }); },
  setBalance: (balance) => { set({ balance }); },
  setSelectedParlay: (selectedParlay) => { set({ selectedParlay }); },
  setGlobalMessage: (globalMessage) => set({ globalMessage }),
  setDisplayGlobalModal: (displayGlobalModal) => { set({ displayGlobalModal }) }
}));

export const useIsAuth = () => useStore(({ auth, setAuth }) => ({ auth, setAuth }))
export const useSelectColiseum = () => useStore(({ selectedColiseum, setSelectedColiseum }) => ({ selectedColiseum, setSelectedColiseum }))
export const useColiseum = () => useStore(({ coliseum, setColiseum }) => ({ coliseum, setColiseum }))
export const useGames = () => useStore(({ games, setgames }) => ({ games, setgames }))
export const useSelectedBet = () => useStore(({ selectedBet, setSelectedBet }) => ({ selectedBet, setSelectedBet }))
export const useHistory = () => useStore(({ history, sethistory }) => ({ history, sethistory }))
export const useBanks = () => useStore(({ Banks, setBanks }) => ({ Banks, setBanks }))
export const useBetType = () => useStore(({ betType, setBetType }) => ({ betType, setBetType }))
export const usePendingBets = () => useStore(({ pendingBets, setPendingBets }) => ({ pendingBets, setPendingBets }))
export const useModal = () => useStore(({ isModalOpen, setModalOpen }) => ({ isModalOpen, setModalOpen }))
export const useSelectedParlay = () => useStore(({ selectedParlay, setSelectedParlay }) => ({ selectedParlay, setSelectedParlay }))
export const useBalance = () => useStore(({ balance, setBalance }) => ({ balance, setBalance }))
export const useCurrentAnimation = () => useStore(({ currentAnimation, setCurrentAnimation }) => ({ currentAnimation, setCurrentAnimation }))
export const useModalParlay = () => useStore(({ isModalOpenParlay, setIsModalOpenParlay }) => ({ isModalOpenParlay, setIsModalOpenParlay }))
export const useGlobalMessage = () => useStore(({ globalMessage, setGlobalMessage }) => ({ globalMessage, setGlobalMessage }));
export const useGlobalModal = () => useStore(({ displayGlobalModal, setDisplayGlobalModal }) => ({ displayGlobalModal, setDisplayGlobalModal }));
