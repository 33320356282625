import Select from "react-select";

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Alert, CButton, Drawer, Input } from 'src/components';
import { BET_TYPES } from "src/utils";
import { useEffect, useState } from "react";
import { type Base, useService } from "src/hooks";

interface FormValues {
  gameMode: number
  userRole: number
  settings: Array<{
    max: string
  }>
}

const required = 'Este campo es requerido!';

export const validationSchema = Yup.object().shape({
  gameMode: Yup.number().required(required),
  userRole: Yup.number().required(required),
  settings: Yup.array()
    .of(
      Yup.object().shape({
        max: Yup.string().notRequired()
      })
    )
    .when('gameMode', {
      is: (val: number) => {
        return val === BET_TYPES.parlay
      },
      then: schema => schema.max(4, 'Solo puede agregar 4 configuraciones.'),
      otherwise: schema => schema.max(1, 'Solo pude agregar 1 configuración.')
    })
    .required(required)
});

export const BetSettingsDialog: React.FC<BetSettingsDialogProps> = ({ isOpen, onClose, betSetting, onCloseRefresh, settings }) => {
  const [editMode, setEditMode] = useState<boolean>();
  const [betSettingId, setBetSettingId] = useState<number>(0);
  const [localBetType, setBetType] = useState<0 | 1 | 2>(1);
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [gameId, setGameId] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      settings: [
        { max: "" }
      ]
    }
  });

  const betSettingsSvc = useService<Base<BetSettings[]>>({
    route: 'betSettings',
    data: () => {
      onCloseRefresh();

      onCloseDialog();
    }
  });
  useEffect(() => {
    if (betSetting.id) {
      setEditMode(true);
      setBetSettingId(betSetting.id);
      setGameId(betSetting.gameMode.id);

      setValue('gameMode', betSetting.gameMode.id);
      setValue('userRole', betSetting.userRole);
      setValue('settings', betSetting.settings.map(setting => ({ max: setting.max.toString() })))
    } else {
      reset();
    }
  }, [betSetting]);

  const { fields, replace } = useFieldArray({
    control,
    name: 'settings'
  });

  useEffect(() => {
    if (localBetType === BET_TYPES.directo && fields.length === 4) {
      replace({ max: '' })
    } else if (localBetType === BET_TYPES.parlay && fields.length === 1) {
      replace([{ max: '' }, { max: '' }, { max: '' }, { max: '' }])
    } else {
      replace({ max: '' })
    }
  }, [localBetType]);

  useEffect(() => {
    if (betSettingsSvc.apiError) {
      betSettingsSvc.clearError();
    }
  }, [betSettingsSvc.apiError]);

  const isIn = (type: number, role: number) => {
    return !!settings.find(s => s.gameMode.id === type && s.userRole === role);
  }

  const onSubmit = (data: FormValues) => {
    betSettingsSvc.clearError();

    setDisplayAlert(false);

    if (isIn(data.gameMode, data.userRole) && !editMode) {
      setDisplayAlert(true);
      return;
    }

    const payload = {
      ...data,
      settings: [
        ...data
          .settings
          .map((setting, index) => ({ ...setting, count: 2 + index }))
      ]
    };

    if (betSettingId && editMode) {
      // update here
      betSettingsSvc.update({
        id: betSettingId,
        data: payload
      })
    } else {
      // create here
      betSettingsSvc.post({
        data: payload
      })
    };
  };

  const gameModeOptions = [
    { value: 0, label: 'Seleccione un tipo de juego' },
    { value: 1, label: 'Directo' },
    { value: 2, label: 'Parlay' }
  ];

  const gameRoles = [
    { value: 0, label: 'Seleccione un tipo de juego' },
    { value: 3, label: 'Player' },
    { value: 6, label: 'Banker' }
  ];

  const onCloseDialog = () => {
    setEditMode(false);
    setBetSettingId(0);
    setBetType(0);
    setDisplayAlert(false);
    reset();

    onClose();
  };

  const isServiceLoading = betSettingsSvc.serviceLoading === 'post' || betSettingsSvc.serviceLoading === 'update';

  return (
    <Drawer title={editMode ? 'Editar ajuste' : 'Agregar ajuste'} isOpen={isOpen} onClose={onCloseDialog}>
      <form className="flex flex-col flex-1" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex-grow bg-gray-50 relative">
          <div className="absolute inset-0 overflow-y-auto">
            <div className="p-4 flex flex-col gap-4">
              {displayAlert && <Alert kind='error'>
                No es posible crear esta configuración, ya que es probable que ya exista.
              </Alert>}
              <label className="w-full flex flex-col">
                <p className='text-gray-500 font-bold'>Modo de juego</p>
                <Controller
                  name="gameMode"
                  control={control}
                  render={({ field }) => <Select
                    {...field}
                    isSearchable
                    isDisabled={editMode}
                    className="disabled:cursor-not-allowed"
                    onChange={e => {
                      field.onChange(e?.value || 0);
                      setBetType(e?.value as 1 | 2 || 0)
                      setGameId(e?.value || 0);
                    }}
                    value={gameModeOptions.find((e: any) => e.value === field.value)}
                    options={gameModeOptions}
                  />}
                />
                <p className='text-red-500 py-2 font-medium'>{errors.gameMode?.message}</p>
              </label>

              <label className="w-full flex flex-col">
                <p className='text-gray-500 font-bold'>Role</p>
                <Controller
                  name="userRole"
                  control={control}
                  render={({ field }) => <Select
                    {...field}
                    isSearchable
                    isDisabled={editMode}
                    className="disabled:cursor-not-allowed"
                    onChange={e => {
                      field.onChange(e?.value || 0)
                    }}
                    value={gameRoles.find((e: any) => e.value === field.value)}
                    options={gameRoles}
                  />}
                />
                <p className='text-red-500 py-2 font-medium'>{errors.gameMode?.message}</p>
              </label>

              <div className="flex flex-col gap-3">
                <label>
                  <p className='text-gray-500 font-bold'>Ajustes</p>
                </label>
                {fields.map((field, index) => (
                  <div key={field.id} className="flex gap-4 items-center border p-4 bg-white rounded-md">
                    <Controller
                      control={control}
                      name={`settings.${index}.max` as const}
                      render={({ field }) => <Input
                        type="text"
                        label={(gameId === BET_TYPES.parlay) ? `${2 + index} combinaciones` : 'Maximo'}
                        {...field}
                        error={errors.settings ? errors.settings[index]?.max?.message : ''}
                      />}
                    />
                  </div>
                ))}
                <span className="text-red-500 py-2 font-medium">{errors.settings?.message}</span>
              </div>
            </div>
          </div>
        </div>
        {/* action */}
        <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
          <CButton type='button' className="w-fit bg-transparent group p-3" onClick={onCloseDialog}>
            <p className="text-red-500 font-bold group-hover:text-red-800 transition-all duration-300">
              Cancelar
            </p>
          </CButton>

          <CButton
            busy={isServiceLoading}
            type='submit'
            className="w-fit p-3 px-6"
          >
            {editMode ? 'Actualizar' : 'Guardar'}
          </CButton>
        </div>
      </form>
    </Drawer>
  );
};

interface BetSettingsDialogProps {
  isOpen: boolean
  onClose: () => void
  betSetting: BetSettings
  onCloseRefresh: () => void
  settings: BetSettings[]
}
