import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { Alert, CButton, Drawer, Input } from "src/components"
import { type Base, useService } from "src/hooks";
import { useGlobalModal } from "src/store"
import { BiLoaderAlt } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import { get, isEmpty } from "lodash";
import { currency, onPrintWithdrawal } from "src/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { MdOutlineRadioButtonUnchecked, MdOutlineRadioButtonChecked } from "react-icons/md";
import { useValidatePrinter } from "src/hooks/useValidatePrinter";

interface Query {
  query: string
  searchByCardId: boolean
}

const resolver = yupResolver(Yup.object({
  searchByCardId: Yup.boolean(),
  query: Yup.string().required('Este campo es requerido')
}))

export const WithdrawBalanceModal = () => {
  const { displayGlobalModal, setDisplayGlobalModal } = useGlobalModal();
  const { register, formState: { errors }, clearErrors, reset, handleSubmit, watch } = useForm<Query>({
    defaultValues: {
      searchByCardId: false
    },
    resolver
  });
  const [state, setState] = useState({
    called: false,
    currentUser: {} as User
  });
  const [inputError, setInputError] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [withdraws, setWithdraws] = useState<RechargePlayer[]>([]);
  const [current, setCurrent] = useState<RechargePlayer>({} as RechargePlayer);
  const ref = useRef<HTMLDivElement | null>(null);

  const user = watch('query');
  const { isValid, renderAlert } = useValidatePrinter()

  function onClear() {
    reset({})
    clearErrors()
  }

  const rechargeSvc = useService<Base<RechargePlayer[]>>({
    route: 'rechargeBalance',
    data: (res, operation) => {
      if (operation === 'get') {
        setWithdraws(res.data)
      }

      if (operation === 'update') {
        onPrintWithdrawal(state.currentUser.username, current.amount);
        handleClose();
        // add onPrint here
      }
    }
  });

  useEffect(() => {
    if (rechargeSvc.apiError) {
      const { response } = rechargeSvc.apiError;
      const message = (get(response, 'data.message', '') || '').toLowerCase();
      const msg = "invalid verification code".toLowerCase();
      const isInvalidCode = message.includes(msg);

      if (isInvalidCode) {
        setInputError("El código proporcionado no coincide con nuestros registros.");
      }

      rechargeSvc.clearError();
    }
  }, [rechargeSvc.apiError]);

  useEffect(() => {
    if (user) {
      setState({
        called: false,
        currentUser:  {} as User
      });
    }
  }, [user])

  const userSvc = useService<Base<User[]>>({
    route: 'users',
    data: async res => {
      setState({
        called: true,
        currentUser: isEmpty(res.data) ? {} as User : res.data[0]
      });

      if (!isEmpty(res.data)) {
        const { player: { id } } = res.data[0];

        await rechargeSvc.get(`?player=${id}&transactionType=2&status=1`);
      }
    }
  });

  function handleClose() {
    onClear();
    setDisplayGlobalModal('none');
    userSvc.clearError();
    setState({
      called: false,
      currentUser: {} as User
    });
    setCode('');
    setCurrent({} as RechargePlayer);
    setWithdraws([]);
    setInputError('')
  }

  const handleUserInput = handleSubmit(async data => {
    const payloadKey = data.searchByCardId ? 'cardId' : 'username';

    const payload = {
      [payloadKey]: data.query,
      role: 3
    }

    const query = Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&');

    setState({
      called: false,
      currentUser: {} as User
    });

    setWithdraws([]);

    await userSvc.get(`?${query}`);
  });

  const isLoading = userSvc.serviceLoading === 'get';
  const isRechargeLoading = rechargeSvc.serviceLoading === 'get';

  const hasError = () => {
    const errors: Record<string, boolean> = {};

    if (!code) {
      errors.isEmpty = true
    }

    if (isNaN(+code)) {
      errors.notValidNumber = true;
    }

    const isValid = Object.values(errors).every(error => !error);

    return !isValid;
  }

  const onWithdrawMoney = async () => {
    const payload = {
      status: 2,
      code
    };

    await rechargeSvc.update({
      id: current.id,
      data: payload
    })
  };

  const onSelect = (item: RechargePlayer) => {
    return () => {
      if (item.id === current.id) {
        return setCurrent({} as RechargePlayer)
      }

      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' });
        ref.current.scrollTop = ref.current.scrollHeight
      }

      return setCurrent(item)
    }
  }

  function handleCode(event: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value } } = event;

    setCode(value);

    if (inputError) {
      setInputError('')
    }

    if (isNaN(+value)) {
      setInputError('No es un numero valido')
    }
  }

  return (
        <Drawer
            isOpen={displayGlobalModal === 'withdraw-balance'}
            onClose={handleClose}
            title="Retiro"
        >
            <form className="flex flex-col flex-1" onSubmit={handleUserInput}>
                <div className="flex-grow bg-gray-50 relative">
                    <div ref={ref} className="absolute inset-0 overflow-y-auto p-4 flex flex-col gap-4">
                        {renderAlert()}

                        <div>
                            <Input
                                {...register('query')}
                                type="text"
                                label="Nombre de usuario o cédula"
                                placeholder="Ej: juan22 / 0010000001"
                                error={errors.query?.message}
                            />
                            <label className="flex items-center gap-2 cursor-pointer select-none">
                                <input {...register('searchByCardId', { value: false })} type="checkbox" />
                                <p className="text-gray-500 font-bold">Buscar por cédula</p>
                            </label>
                        </div>
                        {/* loader */}
                        {isLoading && <div className="flex-grow w-full flex items-center gap-3 justify-center">
                            <BiLoaderAlt className='animate-spin' size={42} />
                            <h2>Buscando usuario</h2>
                        </div>}
                        {/* if no data */}
                        {!isLoading && state.called && !state.currentUser?.id && <div className="flex-grow w-full flex justify-center items-start">
                            <Alert kind="info">
                                No se encontró ningún usuario. por favor verifique que el nombre de usuario o cédula estén correctos.
                            </Alert>
                        </div>}
                        {/* user info */}
                        {!isLoading && state.currentUser?.id && <div className="flex flex-col gap-4 pt-4 border-t">
                            <h1 className="font-bold text-gray-800">Información del usuario</h1>
                            <div className="grid grid-cols-2 gap-6 border-t pt-4">
                                <div>
                                    <h1 className="font-bold text-gray-600">Nombre</h1>
                                    <p className="text-gray-800">{state.currentUser.names} {state.currentUser.lastName}</p>
                                </div>
                                <div>
                                    <h1 className="font-bold text-gray-600">Nombre de usuario</h1>
                                    <p className="text-gray-800">{state.currentUser.username}</p>
                                </div>
                                <div>
                                    <h1 className="font-bold text-gray-600">Cédula</h1>
                                    <p className="text-gray-800">{state.currentUser.cardId || 'N/A'}</p>
                                </div>
                                <div>
                                    <h1 className="font-bold text-gray-600">Correo</h1>
                                    <p className="text-gray-800">{state.currentUser.email || 'N/A'}</p>
                                </div>
                            </div>
                        </div>}
                        {/* content */}
                        <div className="flex-grow relative flex flex-col">
                            {isRechargeLoading && <div className="flex-grow w-full flex items-center gap-3 justify-center">
                                <BiLoaderAlt className='animate-spin' size={42} />
                                <h2>Cargando información, por favor espere.</h2>
                            </div>}
                            {!isRechargeLoading && !isEmpty(withdraws) && <div className="flex flex-col gap-2">
                                <div>
                                    <h1 className="font-bold text-gray-800">Retiros disponibles</h1>
                                    <p className="text-sm text-gray-500 font-semibold">Seleccione un retiro para continuar:</p>
                                </div>
                                <hr className="w-full" />
                                <ul className="flex flex-col gap-2">
                                    {withdraws.map(w => {
                                      return <li key={w.id} className="border rounded-lg p-4 bg-white">
                                            <button onClick={onSelect(w)} type="button" className="w-full text-left flex-col flex gap-2">
                                                <div className="flex items-center justify-between w-full">
                                                    <div className="flex items-center w-full gap-2">
                                                        <div className="text-cta-blue text-xl">
                                                            {current.id === w.id ? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />}
                                                        </div>
                                                        <h1>Monto: <b className="text-sm text-green-600 py-1 px-4 bg-green-200 rounded-full">{currency(w.amount)}</b></h1>
                                                    </div>
                                                </div>
                                                <hr className="w-full" />
                                                <div className="flex flex-col gap-2 w-full">
                                                    <h2 className="text-gray-600 font-bold">Persona autorizada:</h2>
                                                    <p className="">
                                                        <span className="text-gray-600">- Nombre:</span>{' '}
                                                        <span className="text-gray-800 font-semibold">{w.requestWithdrawalBalance.name}</span>
                                                    </p>
                                                    <p className="">
                                                        <span className="text-gray-600">- Cédula:</span>{' '}
                                                        <span className="text-gray-800 font-semibold">{w.requestWithdrawalBalance.cardId}</span>
                                                    </p>
                                                </div>
                                            </button>
                                        </li>
                                    })}
                                </ul>
                            </div>}
                            {/*  */}
                            {state.currentUser.id && !isRechargeLoading && isEmpty(withdraws) && <div className="flex flex-col gap-2">
                                <Alert kind="info">
                                  No hay retiros disponibles.
                                </Alert>
                            </div>}
                        </div>
                        {/* end content */}
                        {/* Input */}
                        {current?.id && <div className="border rounded-md bg-white p-4">
                            <Input
                                label="Escriba el código de verificación"
                                placeholder=""
                                value={code}
                                onChange={handleCode}
                                error={inputError}
                            />
                        </div>}
                    </div>
                </div>
                {/* action */}
                <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
                    <CButton type='button' className="w-fit bg-transparent group p-3" onClick={handleClose}>
                        <p className="text-red-500 font-bold group-hover:text-red-800 transition-all duration-300">
                            Cancelar
                        </p>
                    </CButton>

                    <CButton
                        type='submit'
                        className="w-fit p-2 px-6"
                        busy={isLoading}
                        disabled={!!userSvc.apiError || !isValid}
                    >
                        <p className="text-white font-bold text-sm">
                            Buscar usuario
                        </p>
                    </CButton>

                    {state.currentUser?.id && !isEmpty(withdraws) && <CButton
                        type='button'
                        className="w-fit bg-green-900 p-2 px-6 text-sm disabled:cursor-not-allowed"
                        disabled={hasError() || !isValid}
                        onClick={onWithdrawMoney}
                        busy={rechargeSvc.serviceLoading === 'update'}
                    >
                        <p className="text-white font-bold text-sm">
                            Retirar
                        </p>
                    </CButton>}
                </div>
            </form>
        </Drawer>
  )
}
