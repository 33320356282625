import axios from 'axios';
import toast from 'react-hot-toast';

import {
  type IService,
  type Response,
  type Operation,
  type DeleteOperation,
  type PutOperation,
  type ApiOptions
} from './interface';

import { type RoutesKeys, routes, URL } from './routes';

import { getStorageValue, APP_STORAGE_KEY, deleteStorageValue } from 'src/utils';
import { get } from 'lodash';

class Service implements IService {
  constructor(private readonly route: RoutesKeys, private readonly options?: ApiOptions) {}

  // indicate that the user is un authorized
  private isNotAuthorized(data: unknown) {
    // if no auth then logout the user
    // clear the token and any other pre-saved data.
    const status: number = get(data, 'response.status', 0) || 0;

    if (status === 401) {
      toast.dismiss();

      deleteStorageValue(APP_STORAGE_KEY);
      deleteStorageValue('logged');

      window.location.replace('/');

      window.location.reload();
    }
  }

  private async baseApi() {
    const { token } = getStorageValue<{ token: string }>(APP_STORAGE_KEY);

    const config = {
      headers: {
        Authorization: `Bearer ${this.options?.token || token}`,
        Accept: 'application/json'
      }
    } as Partial<any>

    // if we want to use a public url with no token
    if (this.options?.isPublic) {
      delete config.headers.Authorization
    }

    return axios.create(config);
  }

  private url() {
    const where = routes[this.route];

    if (where.svc) {
      return `${URL}/${where.svc}/${where.route}`;
    }
    return `${URL}/${where.route}`;
  }

  public async get<T>(query: string = ''): Promise<Response<T>> {
    try {
      const api = await this.baseApi();

      const res = await api.get(`${this.url()}${query}`);

      return {
        data: res.data,
        error: undefined
      };
    } catch (error) {
      this.isNotAuthorized(error);
      // only show this if the messages can be shown
      if (!this.options?.messages?.get?.hideMessage) {
        toast.error(this.options?.messages?.get?.onError || 'Oh, No. no fue posible obtener la informacion!');
      }
      console.error(error);
      return {
        error: error as any,
        data: undefined
      };
    }
  }

  public async post<T>(args: Operation<T>): Promise<Response<T>> {
    const { query = '', data } = args;
    try {
      const api = await this.baseApi();

      const res = await api.post(`${this.url()}${query}`, data);

      if (!this.options?.messages?.post?.hideMessage) {
        toast.success(this.options?.messages?.post?.onSuccess || 'Operacion completada con exito!');
      }

      return {
        data: res.data,
        error: undefined
      };
    } catch (error) {
      this.isNotAuthorized(error);
      if (!this.options?.messages?.post?.hideMessage) {
        toast.error(this.options?.messages?.post?.onError || 'Oh, No. no fue posible completar esta operacion!');
      }

      console.error(error);
      return {
        error: error as any,
        data: undefined
      };
    }
  }

  public async update<T>(args: PutOperation<T>): Promise<Response<T>> {
    const { id = '', data } = args;
    try {
      const api = await this.baseApi();

      const res = await api.put(`${this.url()}/${id}`, data || {});
      if (!this.options?.messages?.update?.hideMessage) {
        toast.success(this.options?.messages?.update?.onSuccess || 'Operacion completada con exito!');
      }

      return {
        data: res.data,
        error: undefined
      };
    } catch (error) {
      this.isNotAuthorized(error);
      if (!this.options?.messages?.update?.hideMessage) {
        toast.error(this.options?.messages?.update?.onError || 'Oh, No. no fue posible completar esta operacion!');
      }

      console.error(error);
      return {
        error: error as any,
        data: undefined
      };
    }
  }

  public async delete<T>(args: DeleteOperation): Promise<Response<T>> {
    const { id } = args;
    try {
      const api = await this.baseApi();

      const res = await api.delete(`${this.url()}/${id}`);
      if (!this.options?.messages?.delete?.hideMessage) {
        toast.success(this.options?.messages?.delete?.onSuccess || 'Operacion completada con exito!');
      }

      return {
        data: res.data,
        error: undefined
      };
    } catch (error) {
      this.isNotAuthorized(error);
      if (!this.options?.messages?.delete?.hideMessage) {
        toast.error(this.options?.messages?.delete?.onError || 'Oh, No. no fue posible completar esta operacion!');
      }

      console.error((error as any).response?.data);
      return {
        error: error as any,
        data: undefined
      };
    }
  }
}

export { Service };
