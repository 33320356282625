import { MdClose } from "react-icons/md"
import { CButton } from "src/components"

export const Drawer: React.FC<React.PropsWithChildren<DrawerProps>> = (props) => {
  const { isOpen } = props;

  if (!isOpen) {
    return null;
  }

  return (
    <div className="absolute inset-0 z-50 bg-black bg-opacity-70 flex justify-end" onClick={props.onClose}>
      <div className="w-full lg:w-1/3 bg-white h-full flex flex-col" onClick={e => e.stopPropagation()}>
        {/* header */}
        <div className="p-4 flex items-center border-b bg-white">
          <div className="flex-grow">
            <h1 className="font-bold">{props.title}</h1>
          </div>

          <CButton className="bg-red-400 rounded-[300rem] hover:bg-red-500 transition-all duration-300 p-2" onClick={props.onClose}>
            <MdClose size={24} />
          </CButton>
        </div>
        {/*  */}
       {props.children}
      </div>
    </div>
  )
}

interface DrawerProps {
  isOpen: boolean
  onClose: () => void
  title: string
}
