import { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { CustomTable, Input, TitleWithIcon } from 'src/components'
import { type Base, useService } from 'src/hooks'
import { Layout } from 'src/layouts'
import { type CancelBetType } from '../../appTypes/CancelBetType'
import { betType, currency, getFormattedDateTime, isBanker, search } from 'src/utils'
import { IoMdEye } from 'react-icons/io'
import { ValidateTicketDialog } from './ValidateTicketDialog';
import { FaTicketAlt } from 'react-icons/fa'

export const ValidateTicketPage = () => {
  const [bets, setBets] = useState<CancelBetType[]>([]);
  const [bet, setBet] = useState<CancelBetType>({} as CancelBetType);
  const [openDetailDialog, setDetailDialog] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('')

  const betSvc = useService<Base<CancelBetType[]>>({
    route: 'bets',
    data: res => {
      if (!isEmpty(res.data)) {
        setBets(res.data);
      }
    }
  });

  useEffect(() => {
    betSvc.get('/?status=2');
  }, []);

  function onCloseCallback() {
    setDetailDialog(false)
    setBet({} as CancelBetType)
  }

  function onEdit(data: CancelBetType) {
    return () => {
      setBet(data);
      setDetailDialog(true);
    }
  }

  const modifiedList = useMemo(() => {
    return bets.filter(b => {
      return search(b?.id.toString(), query)
    })
  }, [query, bets]);

  function handleUserInput(e: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value } } = e;

    setQuery(value);
  }

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <ValidateTicketDialog
        isOpen={openDetailDialog}
        onCLose={onCloseCallback}
        bet={bet}
        onRefresh={async () => await betSvc.get('/?status=2')}
      />
      <div className="p-6 bg-custom-bg flex h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
          <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-2 md:flex-row'>
            <TitleWithIcon
              icon={<FaTicketAlt />}
              title='Validar ticket'
            />

            <div>
              <Input label='Buscar por N.Ticket' placeholder='12223' onChange={handleUserInput} value={query} />
            </div>
          </div>

          <div className='flex flex-grow relative'>
            <div className='absolute inset-0 overflow-y-auto'>
              <CustomTable<CancelBetType>
                isLoading={betSvc.serviceLoading === 'get'}
                data={modifiedList}
                cols={[
                  {
                    field: 'id',
                    name: 'N. Ticket'
                  },
                  {
                    field: '',
                    name: 'Usuario',
                    render: ({ data }) => {
                      return <div className='capitalize'>{data.player.user.role.name}</div>
                    },
                    hide: isBanker()
                  },
                  {
                    field: '',
                    name: 'Tipo de apuesta',
                    render: ({ data }) => {
                      return <p>{betType[data?.gameMode?.id] || ''}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Monto',
                    render: ({ data }) => {
                      return <p>{currency(data.betAmount.toString(), 'USD')}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Monto a ganar',
                    render: ({ data }) => {
                      return <p>{currency(data.earnedAmount.toString(), 'USD')}</p>
                    }
                  },
                  {
                    field: 'createdAt',
                    name: 'Fecha de creación',
                    render: ({ data }) => {
                      return <div className='text-left'>
                        <p>{getFormattedDateTime(data.createdAt, 'year')}</p>
                        <p>{getFormattedDateTime(data.createdAt, 'hours')}</p>
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Acciones',
                    class: 'text-center',
                    render: ({ data }) => {
                      return <div className='flex items-center justify-center w-full'>
                        <button onClick={onEdit(data)} type='button' className='bg-cta-blue hover:bg-opacity-80 duration-300 transition-all p-1 rounded-full text-white'>
                          <IoMdEye size={24} />
                        </button>
                      </div>
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
