import { useSelectedParlay } from 'src/store';
import React from 'react'
import Icon_coock_blue from '../../assets/Icon_coock_blue.svg'
import Icon_coock_yellow from '../../assets/Icon_coock_yellow.svg'
import { CustomCheckbox } from 'src/components'
import { Loader } from 'src/layouts';
import { useAnimation } from 'src/hooks';
import classNames from 'classnames';
import { getFormattedDateTime } from 'src/utils';

interface Props {
  data: Game
}
export function ParlayCard({ data }: Props) {
  const { selectedParlay, setSelectedParlay } = useSelectedParlay();
  const { currentAnimation } = useAnimation();

  const setCurrent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = +e.target.value < 3 ? 1 : 2
    if (!selectedParlay.find((item: any) => item.id === data.id && item.type === type)) {
      if (selectedParlay.length < 5) {
        setSelectedParlay([...selectedParlay, { id: data.id, selected: +e.target.value, Game: data, type }])
      }
    } else {
      const newSelectedParlay = selectedParlay.map((item: any) => {
        if (item.id === data.id && item.selected === +e.target.value && item.type === type) {
          return null
        } else if (item.id === data.id && item.type === type) {
          return { ...item, selected: +e.target.value, type }
        }
        return item
      })
      setSelectedParlay(newSelectedParlay.filter((item: any) => item !== null))
    }
  }
  const getSelected = (type: number) => {
    const selected = selectedParlay.find((item: any) => item.id === data.id && item.type === type)
    if (selected) {
      return selected.selected
    }
    return 0
  }

  const classes = classNames("shadow-lg bg-white rounded-md relative");

  // this means that we have a game to update;
  const animate = currentAnimation.id === data.id;

  if (animate && currentAnimation.type === 'loader') {
    return <div className='animate-pulse bg-white border rounded-md h-[20rem] w-full shadow-lg flex flex-col'>
      <div className='h-14 w-full bg-gray-300 rounded-t-md'></div>
      <div className='flex items-center justify-evenly flex-grow p-4'>
        <Loader />
      </div>
    </div>
  }

  const generateImage = (color: string) => {
    if (color === 'blue') {
      return Icon_coock_blue
    }

    return Icon_coock_yellow
  }
  return (
    <div className={classes}>

      {animate && currentAnimation.type === 'pulse' && <div className='absolute -top-1 -right-1'>
        <span className="relative flex h-4 w-4">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-4 w-4 bg-red-600"></span>
        </span>
      </div>}

      <div className='rounded-md overflow-hidden'>
        <div className="px-6 py-4 text-center text-gray-700 bg-gray-200">
          <h1 className="text-lg cardTitle flex justify-between">
            <span className='capitalize'>{getFormattedDateTime(data.startDate, 'month')}</span>
            <span>#{data.fightNumber}</span>
          </h1>
        </div>
        <div className="p-2 ">
          <div className="flex">
            <div className="w-2/5 px-2 py-4 text-center flexJustifyCenter">
              <img src={generateImage(data.fighters[0].color)} alt="Image 1" className="w-16 h-16 object-cover rounded-full mx-auto" />
              <p className="mt-4 cardRusterName">{data.fighters[0].team.name}({data?.fighters[0]?.favorite ? '-' : '+'}{data?.fighters[0]?.betPercent})</p>
              <div className="flex items-center justify-center h-8 rounded-md mt-2">
                <CustomCheckbox
                  name={`firstFighter${data.id}`}
                  value={1}
                  checked={getSelected(1) === 1}
                  onChange={setCurrent}
                />
              </div>
            </div>
            <div className="w-1/5 px-2 py-4 text-center flex justify-center items-center">
              <p className="mt-4 text-[#CE0B0B] font-extrabold text-4xl ">VS</p>
            </div>
            <div className="w-2/5 px-2 py-4 text-center flexJustifyCenter">
              <img src={generateImage(data.fighters[1].color)} alt="Image 3" className="w-16 h-16 object-cover rounded-full mx-auto" />
              <p className="mt-4 cardRusterName">{data.fighters[1].team.name}({data?.fighters[1]?.favorite ? '-' : '+'}{data?.fighters[1]?.betPercent})</p>
              <div className="flex items-center justify-center h-8 rounded-md mt-2">
                <CustomCheckbox
                  name={`secondFighter${data.id}`}
                  value={2}
                  checked={getSelected(1) === 2}
                  onChange={setCurrent}
                />
              </div>
            </div>
          </div>
          {data.morePercentage &&
            <div className="px-2 py-4 flex flex-col items-center flex-wrap">
              <p className="text-[#636363] font-bold">Tiempo:</p>
              <div className="flex">
                <label className="flex items-center pr-2">
                  <CustomCheckbox
                    name={`FirstTime${data.id}`}
                    value={3}
                    checked={getSelected(2) === 3}
                    onChange={setCurrent}
                  />
                  <span className="ml-3 smallerText">B {data.moreTime} (-{data.morePercentage})</span>
                </label>
                <label className="flex items-center">
                  <CustomCheckbox
                    name={`SecondTime${data.id}`}
                    value={4}
                    checked={getSelected(2) === 4}
                    onChange={setCurrent}
                  />
                  <span className="ml-3 smallerText">A {data.moreTime} (-{data.morePercentage})</span>
                </label>
              </div>
            </div>
          }
        </div>
      </div>
    </div>

  )
}
