import { APP_STORAGE_KEY, ROLES } from "../Constant";
import { getStorageValue } from "../storage";

export function isBanker() {
  return getStorageValue<{ role: number }>(APP_STORAGE_KEY).role === ROLES.banker;
}

export function generatePath(path: string) {
  return path
}

export function isCentral() {
  const urlParams = new URLSearchParams(window.location.search);
  const isCentralParam = urlParams.get('isCentral') === 'true';

  const hostName = window.location.hostname;

  const central = process.env.REACT_APP_IS_CENTRAL || 'false';
  const centralByHostName = hostName === "center.cockfightsportbet.com" || hostName === "central.admin.staging.cockfightsportbet.com";

  return central.toLowerCase().includes('true') || isCentralParam || centralByHostName;
}
