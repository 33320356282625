import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { MdAdd, MdEdit, MdPeopleAlt, MdSearch, MdVerified } from 'react-icons/md'
import { CustomTable, Input, TitleWithIcon } from 'src/components'
import { useService, type Base } from 'src/hooks'
import { Layout } from 'src/layouts'
import { search } from 'src/utils'
import { currency } from '../../utils/format/index';
import { CreatePlayerDialog } from './CreatePlayerDialog'
import { ChangePasswordDialog } from './ChangePasswordDialog'

export const CreatePlayerPage = () => {
  const [players, setUsers] = useState<PlayerUser[]>([]);
  const [query, setQuery] = useState<string>('');
  const [isOpen, setIsOpen] = useState<'change' | 'create' | 'none'>('none');
  const [currentUser, setCurrentUser] = useState<PlayerUser>({} as PlayerUser);
  const [perPage, setPerPage] = useState<number>(10);

  const modifiedList = useMemo(() => {
    return players.filter(player => {
      return search(player.user.names, query) || search(player.user.lastName, query) || search(player.user.username, query);
    })
  }, [players, query])

  const playersSvc = useService<Base<PlayerUser[]>>({
    route: 'players',
    data: res => {
      setUsers(res.data);
    }
  });

  useEffect(() => {
    playersSvc.get(`/?paginate=true&perPage=${perPage}`);
  }, []);

  const handleDialog = (status: 'change' | 'create' | 'none') => {
    setIsOpen(status);
  };

  const loadMore = async () => {
    await playersSvc.get(`/?paginate=true&perPage=${perPage}`);
  }

  async function onLoadMore() {
    const more = perPage + 10;
    setPerPage(more)
    await playersSvc.get(`/?paginate=true&perPage=${more}`)
  }

  const onCloseDialog = () => {
    handleDialog('none');
    setCurrentUser({} as PlayerUser);
  }

  const handleEditUser = (user: PlayerUser) => {
    return () => {
      handleDialog('change');
      setCurrentUser(user);
    }
  }

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <CreatePlayerDialog
        isOpen={isOpen === 'create'}
        onClose={onCloseDialog}
        onLoadMore={loadMore}
        user={currentUser}
      />
      <ChangePasswordDialog
        isOpen={isOpen === 'change'}
        onClose={onCloseDialog}
        onLoadMore={loadMore}
        user={currentUser}
      />
      <div className="p-6 bg-custom-bg flex h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
          <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-2 md:flex-row'>
            <TitleWithIcon
              icon={<MdPeopleAlt />}
              title='Jugadores'
            />
            <div className='flex items-center justify-end flex-wrap gap-4'>
              {/*  */}
              <button
                onClick={() => handleDialog('create')}
                type='button'
                className='flex gap-2 items-center group'
              >
                <div className='p-2 rounded-[300rem] bg-cta-blue text-white group-hover:bg-opacity-60 transition-all duration-300'>
                  <MdAdd size={24} />
                </div>
                <h1 className='font-medium group-hover:opacity-60 duration-300 transition-all'>Crear jugador</h1>
              </button>
              {/*  */}
              <label className='flex bg-white items-center rounded-md'>
                <MdSearch size={32} />
                <Input
                  label='Buscar jugador'
                  type='text'
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  className='p-2 outline-none'
                />
              </label>
            </div>
          </div>
          <div className='flex flex-grow relative'>
            <div className='absolute inset-0'>
              <CustomTable<PlayerUser>
                data={modifiedList}
                className='h-full'
                isLoading={playersSvc.serviceLoading === 'get'}
                onServiceCall={onLoadMore}
                cols={[
                  {
                    field: 'names',
                    name: 'Nombres',
                    render: ({ data }) => {
                      const status = classNames('text-custom-green',
                        { 'text-gray-400': !data.user.enabled }
                      )
                      return <div className='flex items-start gap-2'>
                        <MdVerified className={status} size={24} />
                        <div className='flex flex-col items-start'>
                          <p>{data.user.names || ''}</p>
                          <p>{data.user.lastName || ''}</p>
                        </div>
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Nombre de usuario',
                    render: ({ data }) => {
                      return <div className='flex items-start gap-2'>
                        <p>{data.user.username || ''}</p>
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Cédula',
                    render: ({ data }) => {
                      return <div className='flex items-start gap-2'>
                        <p>{data.user.cardId || ''}</p>
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Correo',
                    render: ({ data }) => {
                      return <div className='flex items-start gap-2'>
                        <p className='break-all'>{data.user.email || ''}</p>
                      </div>
                    }
                  },
                  {
                    field: '',
                    name: 'Balance',
                    render({ data }) {
                      return <p className={classNames('font-bold text-gray-400', {
                        'text-green-600': !isNaN(+data.balance) && +data.balance > 0
                      })}>{currency(data.balance)}</p>
                    }
                  },
                  {
                    field: '',
                    name: 'Acciones',
                    render: ({ data }) => {
                      return <div className='flex gap-2 items-center w-full'>
                                <button
                                    onClick={handleEditUser(data)}
                                    className='group bg-cta-blue p-3 py-1 rounded-full text-white hover:bg-opacity-75 transition-all flex items-center gap-1'>
                                    <MdEdit className='text-white' />
                                    <p className=''>Editar</p>
                                </button>
                            </div>
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
