declare global {
  interface Window {
    env: any
  }
}

// change with your own variables
interface EnvType {
  REACT_APP_GRAPHQL_SUBSCRIPTION: string
  REACT_APP_BASE_URL: string
  REACT_APP_IS_CENTRAL: string
}

export const env: EnvType = { ...process.env, ...window.env }
