import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { MdPrint } from 'react-icons/md';
import { useBetType, useSelectedParlay } from 'src/store'
import { isPrinterActive, onPrintLines } from 'src/utils';

export const TopBar: React.FC<{ enablePrint?: boolean, games?: Game[] }> = ({ enablePrint = false, games }) => {
  const { betType, setBetType } = useBetType()
  const { setSelectedParlay } = useSelectedParlay()

  const handleBetType = (type: 1 | 2) => () => setBetType(type);
  const clear = () => setSelectedParlay([]);

  const directBet = () => {
    handleBetType(1)();
    clear();
  }

  function onPrint() {
    if (!isEmpty(games) && games) {
      // print here
      onPrintLines(games);
    }
  }

  return <div className={classNames("bg-[#ffffff] p-4 flex justify-between flex-wrap max-w-screen border-b gap-4", { 'justify-end': !enablePrint })}>
    {enablePrint && <div>
      <button
        type="button"
        disabled={isEmpty(games) || !isPrinterActive()}
        onClick={onPrint}
        className='disabled:cursor-not-allowed disabled:opacity-50 py-2 px-4 flex items-center gap-2 rounded-md bg-red-600 text-white active:bg-red-700 transition-all duration-300'>
        <MdPrint /> Lineas
      </button>
    </div>}
    {/*  */}
    <div className={classNames("flex justify-end items-center", { 'w-full': !enablePrint })}>
      <span className='pr-2 TitleText'>TIPO DE JUGADA / </span>

      <label className="flex items-center pr-2">
        <input
          type="radio"
          className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          name={''}
          value={2}
          checked={betType === 2}
          onChange={handleBetType(2)}
        />
        <span className="ml-3 selectText">Parlay</span>
      </label>
      <label className="flex items-center">
        <input
          type="radio"
          className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
          name={''}
          value={1}
          checked={betType === 1}
          onChange={directBet}
        />
        <span className="ml-3 selectText">Directo</span>
      </label>
    </div>
  </div>
}
