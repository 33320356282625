import BaseModal from 'src/components/BaseModal'
import { Loader } from 'src/layouts';
import { betType } from 'src/utils';
import { isEmpty } from 'lodash';
import { NoResult } from '../NoResults';
import DetailItem from './Item';

interface Props {
  selectedBet: {
    bets: BetDetails[]
    betType: number
    betAmount: number | string
    earnedAmount: number | string
    date: string
  }
  onClose: () => void
  onAction: () => void
  isOpen: boolean
  isLoading?: boolean
  hasError?: {
    message: string
  }
}

export const DetailsModal: React.FC<Props> = function (props) {
  const { selectedBet, onClose, isOpen, onAction, isLoading } = props;

  return (
    <BaseModal isOpen={isOpen}>
      <div className="flex flex-col items-center justify-between p-4 px-8 gap-6 h-full">
        {/* header */}
        <div className="flex w-full items-center gap-6">
          <h1 className="TitleTextModal">{betType[selectedBet.betType]}</h1>
          <hr className="border-gray-400 w-full" />
        </div>
        {/* no data */}
        {isEmpty(selectedBet.bets) && <NoResult className='text-center' />}

        {(!isLoading && !isEmpty(selectedBet.bets)) && <>
          <div className="w-full">
            <ul className=" bg-[#F5F7F9] p-5 overflow-auto max-h-80 rounded-lg flex flex-col gap-4">
              {
                selectedBet.bets.map(bet => {
                  return <DetailItem date={selectedBet.date} key={bet.id} bet={bet}
                  to={bet.fighter?.color === "blue" ? 1 : 0}
                  amount={`${bet.fighter?.favorite ? '+' : '-'} ${bet.betPercent}`}
                  />
                })
              }
            </ul>
            {/*  */}
            <div className="flex items-center justify-between py-2">
              {/*  */}
              <div className='flex justify-between flex-wrap items-center'>
                <span className='mdoalNormalText'>Monto de apuestas:</span>
                <span className='font-bold '>${selectedBet.betAmount}</span>
              </div>
              {/*  */}
              <div>
                <p className='smallText'>Monto a ganar
                  <span className='font-bold '>${selectedBet.earnedAmount}</span>
                </p>
              </div>
            </div>
            <div className="">
              <p className='smallerText font-bold'>
                Monto máximo a ganar (4+) combinaciones:
              </p>
              <p className='font-bold smallerText'>
                ${selectedBet.earnedAmount}
              </p>
            </div>
          </div>
        </>}
        {isLoading && <div className='h-96'>
          <Loader />
        </div>}
        {/* actions */}
        <div className="flex py-4 w-full justify-end gap-4">
          <button disabled={isLoading} type="button" className="disabled:opacity-60 disabled:cursor-not-allowed text-[#404F60] p4 rounded-md" onClick={onClose}>Cancelar</button>
          <button disabled={isLoading} type="button" className="disabled:opacity-60 disabled:cursor-not-allowed bg-[#181E5C] text-white px-4 py-2 rounded-md" onClick={onAction}>Cerrar</button>
        </div>
      </div>
    </BaseModal>
  )
}
