import { ReactComponent as LogoHeader } from 'src/assets/Logo_heahde.svg';

export const LoginContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div className='w-full h-full flex flex-col'>
        <div className='bg-[#0B0E2C] px-12 p-4'>
            <LogoHeader className='w-32' />
          </div>
        {children}
    </div>
  )
}
