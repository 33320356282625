import { isPrinterActive } from 'src/utils';
import { useSelectedParlay, useModalParlay } from '../../store';
import toast from 'react-hot-toast';

export function RedNote() {
  const { selectedParlay } = useSelectedParlay();
  const { setIsModalOpenParlay, isModalOpenParlay } = useModalParlay();
  const timeParlay = selectedParlay.map((parlay) => parlay.type === 2).filter((parlay) => parlay);
  const directParlay = selectedParlay.map((parlay) => parlay.type === 1).filter((parlay) => parlay);

  const validateParlay = () => {
    if (selectedParlay.length > 1 && selectedParlay.length <= 5) {
      if (timeParlay.length <= directParlay.length) {
        setIsModalOpenParlay(true)
      } else {
        toast.error('Las apuestas a tiempo no deben exceder la cantidad de apuestas a Pelea.');
      }
    }
  }
  return (
    <button type='button' disabled={!isPrinterActive()} className='group disabled:cursor-not-allowed bg-white'>
      <div className={`group-disabled:bg-opacity-70 group-disabled:shadow text-white shadow-lg flex items-center justify-center shadow-[#CE0B0B] rounded-full h-14 w-14 bg-[#CE0B0B] bottom-6 right-6 z-50 active:bottom-5 ${isModalOpenParlay ? 'hidden' : 'fixed'}`}
        onClick={validateParlay}>
          <p className="text-white text-5xl font-bold">
            {selectedParlay.length}
          </p>
        </div>
    </button>
  )
}
