import { type CancelBetDetailsType, type FighterElement } from "src/appTypes";

export function getTime(detail: CancelBetDetailsType, type: 'A' | 'B') {
  return {
    A: `A ${detail?.maxTime?.substring(3)}(-${detail.betPercent})`,
    B: `B ${detail?.minTime?.substring(3)}(${-detail.betPercent})`
  }[type]
}

export function getActualFighter(fighters: FighterElement[], id: number) {
  return fighters.find(v => v.id === id);
}

export function generateFighterInfo(details: CancelBetDetailsType, id: number) {
  const actual = getActualFighter(details.game.fighters, id);
  const favorite = actual?.favorite ? "-" : "+";

  // team name and actual betPercent
  if (actual?.team.name && actual?.betPercent) {
    return `${actual?.team.name}(${favorite}${actual?.betPercent})`
  }
  // if type In time then check for the following
  if (details.betType.id === 2) {
    if (details.minTime) {
      return getTime(details, 'B')
    } else if (details.maxTime) {
      return getTime(details, 'A')
    }
  }

  return ''
}
