import { BiMoneyWithdraw } from 'react-icons/bi';
import { FaCashRegister, FaHome, FaTicketAlt } from 'react-icons/fa';
import { MdBarChart, MdCancelPresentation, MdLiveTv, MdPeopleAlt, MdPersonAdd, MdPrint, MdSettings } from 'react-icons/md'
import { RiFilePaper2Line } from 'react-icons/ri';
import { LiaMoneyBillWaveSolid } from 'react-icons/lia';
import {
  LoginPage, HomePage,
  Dashboard,
  CreateUserPage,
  BetSettings,
  CancelBets,
  ReportsPage,
  ReportDetailsPage,
  InvoicePage,
  CreatePlayerPage,
  LiveGamesPage,
  LiveGamesDetailsPage
} from 'src/pages';
import { SociosPage } from 'src/pages/Invoice/Socios';
import { ValidateTicketPage } from 'src/pages/validate-tickets';
import { Paths, ROLES, generateKey, isCentral } from 'src/utils';

export const globalRoutes: Routes[] = [
  {
    path: Paths.home,
    page: <LoginPage />,
    key: 'LoginPage'
  }
];

export const protectedRoutes: Routes[] = [
  {
    icon: <FaHome />,
    key: 'Home',
    label: 'Home',
    page: <Dashboard />,
    path: Paths.home,
    roles: [ROLES.admin]
  },
  {
    icon: <FaCashRegister />,
    key: 'Ventas',
    label: 'Ventas',
    page: <HomePage />,
    path: Paths.home,
    roles: [ROLES.banker]
  },
  {
    icon: <MdPeopleAlt />,
    key: 'CreateUserPage',
    label: 'Usuarios',
    page: <CreateUserPage />,
    path: Paths.userCrud,
    roles: [ROLES.admin]
  },
  {
    icon: <MdPeopleAlt />,
    key: 'Player-page',
    label: 'Jugadores',
    page: <CreatePlayerPage />,
    path: Paths.players,
    roles: [ROLES.admin]
  },
  {
    icon: <MdSettings />,
    key: 'BetSettings',
    label: 'Ajustes de apuesta',
    page: <BetSettings />,
    roles: [ROLES.admin],
    path: Paths.betSettings
  },
  {
    icon: <MdCancelPresentation />,
    key: 'CancelBets',
    label: 'Cancelar apuestas',
    page: <CancelBets />,
    roles: [ROLES.admin, ROLES.banker],
    path: Paths.cancelBets
  },
  {
    icon: <FaTicketAlt />,
    key: 'ValidateTicket',
    label: 'Validar ticket',
    page: <ValidateTicketPage />,
    roles: [ROLES.admin, ROLES.banker],
    path: Paths.validateTickets
  },
  {
    icon: <MdBarChart />,
    key: 'Reports',
    label: 'Reportes',
    page: <ReportsPage />,
    roles: [ROLES.admin],
    path: Paths.reports
  },
  {
    key: 'Reports-details',
    page: <ReportDetailsPage />,
    roles: [ROLES.admin],
    path: `${Paths.reports}/:banker`
  },
  {
    icon: <MdBarChart />,
    key: 'Reports-banker',
    label: 'Cuadre',
    page: <ReportDetailsPage />,
    path: Paths.cuadre,
    roles: [ROLES.banker]
  },
  {
    icon: <RiFilePaper2Line />,
    key: generateKey('Factura socios', Date.now()),
    label: 'Factura Socios',
    page: <SociosPage />,
    path: Paths.invoice,
    roles: [ROLES.admin],
    hide: !isCentral()
  },
  {
    icon: <RiFilePaper2Line />,
    key: generateKey('Estado de cuenta', Date.now()),
    label: 'Estado de cuenta',
    page: <InvoicePage />,
    path: Paths.accountStatus,
    roles: [ROLES.admin],
    hide: isCentral()
  },
  {
    key: generateKey('Factura-socios-runner', Date.now()),
    page: <InvoicePage />,
    path: `${Paths.invoice}/:runner`,
    roles: [ROLES.admin]
  },
  {
    icon: <MdLiveTv />,
    key: generateKey('live-games', Date.now()),
    label: 'Juegos en vivo',
    page: <LiveGamesPage />,
    path: Paths.liveGames,
    roles: [ROLES.banker, ROLES.admin]
  },
  {
    key: generateKey('live-games', Date.now()),
    page: <LiveGamesDetailsPage />,
    path: `${Paths.liveGames}/:coliseum`,
    roles: [ROLES.banker, ROLES.admin]
  },
  {
    icon: <MdPersonAdd />,
    key: generateKey('create-user', Date.now()),
    label: 'Crear usuario',
    path: Paths.action,
    roles: [ROLES.banker],
    action: 'create-user'
  },
  {
    icon: <LiaMoneyBillWaveSolid />,
    key: generateKey('recargar-player', Date.now()),
    label: 'Recargar player',
    path: Paths.action,
    roles: [ROLES.banker],
    action: 'recargar-player'
  },
  {
    icon: <BiMoneyWithdraw />,
    key: generateKey('retiros', Date.now()),
    label: 'Retiro',
    path: Paths.action,
    roles: [ROLES.banker],
    action: 'withdraw-balance'
  },
  {
    icon: <MdPrint />,
    key: generateKey('printers', Date.now()),
    label: 'Impresoras',
    path: Paths.action,
    roles: [ROLES.banker, ROLES.admin],
    action: 'printers'
  }
]

export interface Routes {
  icon?: JSX.Element
  key: string
  label?: string
  page?: JSX.Element
  path: string
  roles?: number[]
  action?: SidebarAction
  hide?: boolean
  target?: '_blank'
}
