import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { MdAdd, MdEdit, MdPeopleAlt, MdSearch, MdVerified } from 'react-icons/md'
import { CustomTable, TitleWithIcon } from 'src/components'
import { useService, type PaginatedBase } from 'src/hooks'
import { Layout } from 'src/layouts'
import { CreateUserDialog } from './CreateUserDialog'
import { search } from 'src/utils'

export const CreateUserPage = () => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [isUserDialogOpen, setIsUserDialogOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<UserModel>({} as UserModel);
  const [query, setQuery] = useState<string>('')
  const [perPage, setPerPage] = useState<number>(10);

  const modifiedList = useMemo(() => {
    return users.filter(user => {
      return search(user.names, query) || search(user.lastName, query) || search(user.username, query);
    })
  }, [users, query])

  function handleUserDialog(status: boolean) {
    return () => {
      setIsUserDialogOpen(status)
    }
  }

  const userSvc = useService<PaginatedBase<UserModel[]>>({
    route: 'users',
    data: res => {
      setUsers(res.data);
    }
  });

  useEffect(() => {
    userSvc.get(`/?paginate=true&page=1&perPage=${perPage}`);
    // userSvc.get();
  }, []);

  async function onLoadMore() {
    const more = perPage + 10;
    setPerPage(more)

    console.log(more);
    await userSvc.get(`/?paginate=true&page=1&perPage=${more}`)
  }

  async function loadMore() {
    await userSvc.get(`/?paginate=true&page=1&perPage=${perPage}`);
    // userSvc.get();
  }

  const handleEditUser = (user: UserModel) => {
    return () => {
      handleUserDialog(true)();

      setCurrentUser(user)
    }
  }

  const onCLoseDialog = () => {
    handleUserDialog(false)();
    setCurrentUser({} as UserModel)
  }

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <CreateUserDialog
        isOpen={isUserDialogOpen}
        onClose={onCLoseDialog}
        onLoadUserAfterClose={loadMore}
        user={currentUser}
      />
      <div className="p-6 bg-custom-bg flex h-full">
        {/* box */}
        <div className='bg-white pt-8 p-6 flex flex-col flex-grow rounded-lg shadow-xl'>
          <div className='pb-4 bg-white flex lg:items-center justify-between flex-col gap-2 md:flex-row'>
            <TitleWithIcon
              icon={<MdPeopleAlt />}
              title='Usuarios'
            />
            <div className='flex items-center justify-end flex-wrap gap-4'>
              <button
                type='button'
                className='flex gap-2 items-center group'
                onClick={handleUserDialog(true)}>
                <div className='p-2 rounded-[300rem] bg-cta-blue text-white group-hover:bg-opacity-60 transition-all duration-300'>
                  <MdAdd size={24} />
                </div>
                <h1 className='font-medium group-hover:opacity-60 duration-300 transition-all'>Crear usuario</h1>
              </button>
              <label className='flex border bg-white items-center rounded-md'>
                <MdSearch size={32} />
                <input
                  type='text'
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  className='p-2 outline-none'
                  placeholder='Buscar usuario'
                />
              </label>
            </div>
          </div>
          <div className='flex flex-grow relative'>
            <div className='absolute inset-0'>
              <CustomTable<UserModel>
                data={modifiedList}
                className='h-full'
                isLoading={userSvc.serviceLoading === 'get'}
                onServiceCall={onLoadMore}
                cols={[
                  {
                    field: 'names',
                    name: 'Nombres',
                    render: ({ data }) => {
                      const status = classNames('text-custom-green',
                        { 'text-gray-400': !data.enabled }
                      )
                      return <div className='flex items-start gap-2'>
                        <MdVerified className={status} size={24} />
                        <div className='flex flex-col items-start tex-xs'>
                          <p>{data.names || ''}</p>
                          <p>{data.lastName || ''}</p>
                        </div>
                      </div>
                    }
                  },
                  {
                    field: 'username',
                    name: 'Username'
                  },
                  {
                    field: 'email',
                    name: 'Email'
                  },
                  {
                    field: 'role',
                    name: 'Role',
                    render: ({ data }) => {
                      return <span>{data?.role?.name}</span>
                    }
                  },
                  {
                    field: '',
                    name: 'Acciones',
                    render: ({ data }) => {
                      return <div className='flex gap-2 items-center'>
                        <button
                          type="button"
                          onClick={handleEditUser(data)}
                          className='bg-cta-blue p-3 py-1 rounded-full text-white hover:bg-opacity-75 transition-all flex items-center gap-1'>
                          <MdEdit className='text-white' />
                          Editar
                        </button>

                        {/* <CButton className='bg-transparent p-[0.8rem] rounded-[300rem] group hover:bg-red-700 hover:bg-opacity-75 transition-all'>
                          <MdDelete className='group-hover:text-white text-red-700 transition-all' size={24} />
                        </CButton> */}
                      </div>
                    }
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
