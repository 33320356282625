export function setStorageValue(key: string, value: Record<string, unknown>): void {
  const data = JSON.stringify(value);

  localStorage.setItem(key, data);
}

export function getStorageValue<T>(key: string) {
  const data = localStorage.getItem(key) || '{}';

  return JSON.parse(data) as T;
}

export function deleteStorageValue(key: string) {
  localStorage.removeItem(key);
}

export function updateStorageValue<T>(key: string, value: Record<string, unknown>): void {
  const prevData = getStorageValue<T>(key);

  const newData = {
    ...prevData,
    ...value
  }

  setStorageValue(key, newData);
}
