import classNames from "classnames";
import { useEffect, useState } from "react";
import { MdOutlineRadioButtonUnchecked, MdOutlineRadioButtonChecked } from "react-icons/md";
import { Drawer } from "src/components"
import { APP_STORAGE_KEY, generateKey, getStorageValue, setStorageValue } from 'src/utils';

export interface Options {
  label: string
  value: number
}
export const OPTIONS: Options[] = [
  { label: '5 segundos', value: 5000 },
  { label: '10 segundos', value: 10_000 },
  { label: '20 segundos', value: 20_000 },
  { label: '30 segundos', value: 30_000 },
  { label: '40 segundos', value: 40_000 },
  { label: '50 segundos', value: 50_000 },
  { label: '1 minuto', value: 60_000 }
]

export const DEFAULT_TIMER = { label: '10 segundos', value: 10_000 };
export const TIMER_KEY = `${APP_STORAGE_KEY}-timer`

export const ConfigDialog: React.FC<ConfigDialogProps> = ({ isOpen, onClose }) => {
  const [currentTimer, setCurrentTimer] = useState<Options>({} as Options);

  useEffect(() => {
    const storage = getStorageValue<Options>(TIMER_KEY);

    if (!storage.value) {
      setStorageValue(TIMER_KEY, { ...DEFAULT_TIMER });
      setCurrentTimer(DEFAULT_TIMER);
    } else {
      setCurrentTimer(storage);
    }
  }, []);

  const onChangeTimer = (opt: Options) => {
    return () => {
      setStorageValue(TIMER_KEY, { ...opt });

      setCurrentTimer(opt);

      onClose();
    }
  }

  return (
        <Drawer isOpen={isOpen} title="Configurar tiempo de la vista" onClose={onClose}>
            <ul className="flex gap-4 flex-col p-4">
                {
                    OPTIONS.map(opt => {
                      return <li
                            key={generateKey(opt.value, opt.value)}
                        >
                            <button type="button" onClick={onChangeTimer(opt)}
                                className={classNames("w-full transition-all duration-300 border font-semibold rounded-md bg-red-600 p-4 flex items-center gap-4 cursor-pointer hover:text-white hover:bg-red-600 hover:border-transparent text-gray-600",
                                  {
                                    'bg-white': currentTimer.value !== opt.value
                                  },
                                  {
                                    'text-white': currentTimer.value === opt.value
                                  }
                                )}>

                                {currentTimer.value === opt.value ? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />} {opt.label}
                            </button>
                        </li>
                    })
                }
            </ul>
        </Drawer>
  )
}

export interface ConfigDialogProps {
  isOpen: boolean
  onClose: () => void
}
