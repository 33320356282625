import * as qz from 'qz-tray';
import { type Cuadre } from "src/appTypes"
import { get } from "lodash"
import { APP_STORAGE_KEY } from "../Constant";
import { calculateBet, calculatePairlay, currency } from "../format";
import { getStorageValue } from "../storage";

import { getFormattedDateTime } from "../Date";
import { PRINTER_KEY } from 'src/Modals';

export function isPrinterActive() {
  const { printer, ready } = getStorageValue<{ printer: string, ready: boolean }>(PRINTER_KEY);
  return qz.websocket.isActive() && printer && ready;
}

export async function printParlay(bet: any, selectedParlay: any) {
  if (!isPrinterActive()) {
    return;
  }
  try {
    const { user } = getStorageValue<{ user: string }>(APP_STORAGE_KEY);
    const { printer } = getStorageValue<{ printer: string }>(PRINTER_KEY);
    console.log('selectedParlay>>>', selectedParlay)
    const date = getFormattedDateTime(new Date());
    const coliseum = selectedParlay[0].Game.coliseum.name;
    const betType = 'Parlay'
    const toWin = +calculatePairlay(selectedParlay, bet.betAmount);
    const prize = +bet.betAmount + toWin;

    !qz.websocket.isActive() && await qz.websocket.connect();

    const config = qz.configs.create(printer);

    const html = `
    <div class="ticket">
        <div style="text-align: center">
            <div style="font-weight: bold">Cock Fight Sport Bets</div>
            <div style="font-size: 12px;">cockfightsportbet.com</div>
        </div>
        <hr />
        <table>
            <tbody>
                <tr>
                    <td>Fecha: </td>
                    <td>${date}</td>
                </tr>
                <tr>
                    <td>Coliseo: </td>
                    <td>${coliseum}</td>
                </tr>
                <tr>
                    <td>Ticket: </td>
                    <td>${bet.id} - ${bet.verificationCode}</td>
                </tr>
                <tr>
                    <td>Vendido: </td>
                    <td>${user}</td>
                </tr>
                <tr>
                    <td>Tipo: </td>
                    <td>${betType}</td>
                </tr>
            </tbody>
        </table>
        <div style="text-align: center;">
            <hr />
            - Apuestas -
            <hr />
        </div>
        <table>
            <tbody>
                ${selectedParlay.map((detail: any) => {
            const selected = (detail?.selected - 1);
            const fighter = detail.Game.fighters[selected]

            const sign = get(detail, `Game.fighters.${selected}.favorite`) ? '-' : '+';
            if (fighter) {
                return `
                                <tr>
                                    <td>#${detail.Game.fightNumber} - ${detail.Game.fighters[selected].team.name}</td>
                                </tr>
                                <tr>
                                    <td>    MONEY LINE (${sign}${detail.Game.fighters[selected].betPercent})</td>
                                </tr>
                                <tr>
                                  <td styles="margin-bottom: 5px;"></td>
                                </tr>
                            `
            } else if (detail.selected >= 3 && detail.type === 2) {
                const type = {
                    3: `Al Tiempo B ${detail.Game.lessTime.slice(3)}(-${detail.Game.lessPercentage})`,
                    4: `Al Tiempo A ${detail.Game.moreTime.slice(3)}(-${detail.Game.morePercentage})`
                }
                return `
                            <tr>
                                <td>#${detail.Game.fightNumber} - ${detail.Game.fighters[0].team.name} vs ${detail.Game.fighters[1].team.name}</td>
                            </tr>
                            <tr>
                                <td>   ${type[detail.selected as 3 | 4]}</td>
                            </tr>
                            <tr>
                                  <td styles="margin-bottom: 5px;"></td>
                                </tr>
                            `
            }

            return ``
        })
            }
            </tbody>
        </table>
        <hr />
        <table style="border-collapse: collapse; width: 100%;">
            <tbody>
                <tr style="text-align: left;">
                  <td>Apostado:</td>
                  <td>${currency(bet.betAmount, 'USD')}</td>
                </tr>
                <tr style="text-align: left;">
                    <td>Total a ganar:</td>
                    <td> ${currency(toWin.toString(), 'USD')}</td>
                </tr>
                <tr style="text-align: left;">
                    <td>Total:</td>
                    <td>${currency(prize.toString(), 'USD')}</td>
                </tr>
            </tbody>
        </table>
        <hr />
        <div style="font-size: 12px; font-weight: bold; text-align: center;">
            <div>
                REVISE SU TICKET
            </div>
            <div>
                SOLO SE PAGA POR LO QUE DIGA EL TICKET!
            </div>
            <div>
                (c) Cock Fight Sport Bets
            </div>
        </div>
    </div>
  `;

    const data = [{
      type: 'html',
      format: 'plain', // or 'plain' if the data is raw HTML
      data: html
    }];

    await qz.print(config, data);
  } catch (error) {
    console.error('>>>', error);
  }
}

export async function printBet(bet: any, selectedBet: any) {
  if (!isPrinterActive()) {
    return;
  }
  try {
    const { user } = getStorageValue<{ user: string }>(APP_STORAGE_KEY);
    const { printer } = getStorageValue<{ printer: string }>(PRINTER_KEY);

    const date = getFormattedDateTime(new Date());
    const coliseum = selectedBet?.game.coliseum.name;
    const betType = 'Directo'
    const sign = selectedBet?.game.fighters[selectedBet.to].favorite ? '-' : '+';

    const toWinAmountStraight = +calculateBet(null, selectedBet, bet.betAmount);
    const prize = +bet.betAmount + toWinAmountStraight;

    !qz.websocket.isActive() && await qz.websocket.connect();

    const config = qz.configs.create(printer);

    const html = `
    <div class="ticket">
        <div style="text-align: center">
            <div style="font-weight: bold">Cock Fight Sport Bets</div>
            <div style="font-size: 12px;">cockfightsportbet.com</div>
        </div>
        <hr />
        <table>
            <tbody>
                <tr>
                    <td>Fecha: </td>
                    <td>${date}</td>
                </tr>
                <tr>
                    <td>Coliseo: </td>
                    <td>${coliseum}</td>
                </tr>
                <tr>
                    <td>Ticket: </td>
                    <td>${bet.id} - ${bet.verificationCode}</td>
                </tr>
                <tr>
                    <td>Vendido: </td>
                    <td>${user}</td>
                </tr>
                <tr>
                    <td>Tipo: </td>
                    <td>${betType}</td>
                </tr>
            </tbody>
        </table>
        <div style="text-align: center;">
            <hr />
            - Apuestas -
            <hr />
        </div>
        <table>
            <tbody>
                <tr>
                  <td>#${selectedBet?.game.fightNumber} - ${selectedBet?.game.fighters[selectedBet.to].team.name}</td>
                </tr>
                <tr>
                    <td>   MONEY LINE (${sign}${selectedBet?.game.fighters[selectedBet.to].betPercent})</td>
                </tr>
            </tbody>
        </table>
        <hr />
        <table style="border-collapse: collapse; width: 100%;">
            <tbody>
                <tr style="text-align: left;">
                  <td>Apostado:</td>
                  <td>${currency(bet.betAmount, 'USD')}</td>
                </tr>
                <tr style="text-align: left;">
                    <td>Total a ganar:</td>
                    <td> ${currency(toWinAmountStraight.toString(), 'USD')}</td>
                </tr>
                <tr style="text-align: left;">
                    <td>Total:</td>
                    <td>${currency(prize.toString(), 'USD')}</td>
                </tr>
            </tbody>
        </table>
        <hr />
        <div style="font-size: 12px; font-weight: bold; text-align: center;">
            <div>
                REVISE SU TICKET
            </div>
            <div>
                SOLO SE PAGA POR LO QUE DIGA EL TICKET!
            </div>
            <div>
                (c) Cock Fight Sport Bets
            </div>
        </div>
    </div>
  `;

    const data = [{
      type: 'html',
      format: 'plain', // or 'plain' if the data is raw HTML
      data: html
    }];

    await qz.print(config, data);
  } catch (error) {
    console.error('>>>', error);
  }
}

export async function onPrintCuadre(cuadre: Cuadre) {
  if (!isPrinterActive()) {
    return;
  }
  try {
    const { user } = getStorageValue<{ user: string }>(APP_STORAGE_KEY);
    const { printer } = getStorageValue<{ printer: string }>(PRINTER_KEY);
    const date = getFormattedDateTime(new Date());

    !qz.websocket.isActive() && await qz.websocket.connect();
    const config = qz.configs.create(printer);

    const html = `
  <div class="ticket">
      <div style="text-align: center">
          <div style="font-weight: bold">Cock Fight Sport Bets</div>
          <div style="font-size: 12px;">cockfightsportbet.com</div>
      </div>
      <hr />
      <table>
          <tbody>
              <tr>
                  <td>Fecha: </td>
                  <td>${date}</td>
              </tr>
              <tr>
                  <td>Atendido por: </td>
                  <td>${user}</td>
              </tr>
          </tbody>
      </table>
      <div style="text-align: center;">
          <hr />
          - Reporte -
          <hr />
      </div>
      </table>
      <hr />
      <table style="border-collapse: collapse; width: 100%;">
          <tbody>
              <tr style="text-align: left;">
                <td>Total Vendido:</td>
                <td>${currency(cuadre.totalSold.toString(), 'USD')}</td>
              </tr>
              <tr style="text-align: left;">
                  <td>Total Pagado:</td>
                  <td> ${currency(cuadre.paidBetBill.toString(), 'USD')}</td>
              </tr>
              <tr style="text-align: left;">
                  <td>Depósitos:</td>
                  <td>${currency(cuadre.deposit.toString(), 'USD')}</td>
              </tr>
              <tr style="text-align: left;">
                  <td>Retiros:</td>
                  <td>${currency(cuadre.withdrawal.toString(), 'USD')}</td>
              </tr>
              <tr style="text-align: left;">
                  <td>Balance en caja:</td>
                  <td>${currency(cuadre.cashBClear.toString(), 'USD')}</td>
              </tr>
          </tbody>
      </table>
      <hr />
      <div style="font-size: 12px; font-weight: bold; text-align: center;">
          <div>
              (c) Cock Fight Sport Bets
          </div>
      </div>
  </div>
`;

    const data = [{
      type: 'html',
      format: 'plain', // or 'plain' if the data is raw HTML
      data: html
    }];

    await qz.print(config, data);
  } catch (error) {
    console.log('>>>>', error)
  }
}

export async function onPrintLines(games: Game[]) {
  if (!isPrinterActive()) {
    return;
  }
  try {
    const lines = games.map(g => {
      const [first, second] = g.fighters;
      if (g.morePercentage || g.lessPercentage) {
        return `#${g.fightNumber} ${first.team.name} (${first.favorite ? '-' : '+'}${first.betPercent || 'N/A'}) vs ${second.team.name} (${second.favorite ? '-' : '+'}${second.betPercent || 'N/A'}) Time: ${g.moreTime.slice(3)}`
      }
      return `#${g.fightNumber} ${first.team.name} (${first.favorite ? '-' : '+'}${first.betPercent || 'N/A'}) vs ${second.team.name} (${second.favorite ? '-' : '+'}${second.betPercent || 'N/A'})`
    });

    const [{ coliseum: { name } }] = games;

    const { printer } = getStorageValue<{ printer: string }>(PRINTER_KEY);
    const date = getFormattedDateTime(new Date());

    !qz.websocket.isActive() && await qz.websocket.connect();
    const config = qz.configs.create(printer);

    const html = `
  <div class="ticket">
      <div style="text-align: center">
          <div style="font-weight: bold">Cock Fight Sport Bets</div>
          <div style="font-size: 12px;">cockfightsportbet.com</div>
      </div>
      <hr />
      <table>
          <tbody>
              <tr>
                  <td>Fecha: </td>
                  <td>${date}</td>
              </tr>
              <tr>
                  <td>Coliseo: </td>
                  <td>${name}</td>
              </tr>
          </tbody>
      </table>
      <div style="text-align: center;">
          <hr />
          - Lineas -
          <hr />
      </div>
      </table>
      <hr />
      <table style="border-collapse: collapse; width: 100%;">
        ${lines.map(l => (
            `<tr>
                    <td>${l}</td>
               </tr>
               <tr>
                <td style="margin-bottom: 5px">------------------------------</td>
               </tr>
               `
        )).join('\n\n\n')
            }
      </table>
      <hr />
      <div style="font-size: 12px; font-weight: bold; text-align: center;">
          <div>
              (c) Cock Fight Sport Bets
          </div>
      </div>
  </div>
`;

    const data = [{
      type: 'html',
      format: 'plain', // or 'plain' if the data is raw HTML
      data: html
    }];

    await qz.print(config, data);
  } catch (error) {
    console.log(">>>", error)
  }
}

export async function onPrintCreateUser(userName: string, balance: string) {
  if (!isPrinterActive()) {
    return;
  }
  try {
    const { user } = getStorageValue<{ user: string }>(APP_STORAGE_KEY);
    const { printer } = getStorageValue<{ printer: string }>(PRINTER_KEY);
    const date = getFormattedDateTime(new Date());

    !qz.websocket.isActive() && await qz.websocket.connect();
    const config = qz.configs.create(printer);

    const html = `
    <div class="ticket">
        <div style="text-align: center">
            <div style="font-weight: bold">Cock Fight Sport Bets</div>
            <div style="font-size: 12px;">cockfightsportbet.com</div>
        </div>
        <hr />
        <table>
            <tbody>
                <tr>
                    <td>Fecha: </td>
                    <td>${date}</td>
                </tr>
                <tr>
                    <td>Atendido por: </td>
                    <td>${user}</td>
                </tr>
            </tbody>
        </table>
        <div style="text-align: center;">
            <hr />
            - Jugador -
            <hr />
        </div>
        </table>
        <hr />
        <table style="border-collapse: collapse; width: 100%;">
            <tbody>
                <tr style="text-align: left;">
                  <td>Usuario: ${userName}</td>
                </tr>
                <tr style="text-align: left;">
                  <td>Balance inicial: ${currency(balance)}</td>
                </tr>
            </tbody>
        </table>
        <hr />
        <div style="font-size: 12px; font-weight: bold; text-align: center;">
            <div>
                Bienvenido a la familia Cock Fight Sport Bets
            </div>
            <div>
                (c) Cock Fight Sport Bets
            </div>
        </div>
    </div>
  `;
    const data = [{
      type: 'html',
      format: 'plain', // or 'plain' if the data is raw HTML
      data: html
    }];
    await qz.print(config, data);
  } catch (error) {
    console.log(">>>>", error)
  }
}

export async function onPrintRecharge(userName: string, balance: string) {
  if (!isPrinterActive()) {
    return;
  }
  try {
    const { user } = getStorageValue<{ user: string }>(APP_STORAGE_KEY);
    const { printer } = getStorageValue<{ printer: string }>(PRINTER_KEY);
    const date = getFormattedDateTime(new Date());

    !qz.websocket.isActive() && await qz.websocket.connect();
    const config = qz.configs.create(printer);

    const html = `
      <div class="ticket">
          <div style="text-align: center">
              <div style="font-weight: bold">Cock Fight Sport Bets</div>
              <div style="font-size: 12px;">cockfightsportbet.com</div>
          </div>
          <hr />
          <table>
              <tbody>
                  <tr>
                      <td>Fecha: </td>
                      <td>${date}</td>
                  </tr>
                  <tr>
                      <td>Atendido por: </td>
                      <td>${user}</td>
                  </tr>
              </tbody>
          </table>
          <div style="text-align: center;">
              <hr />
              - Recarga -
              <hr />
          </div>
          </table>
          <hr />
          <table style="border-collapse: collapse; width: 100%;">
              <tbody>
                  <tr style="text-align: left;">
                    <td>Usuario: ${userName}</td>
                  </tr>
                  <tr style="text-align: left;">
                    <td>Deposito: ${currency(balance)}</td>
                  </tr>
              </tbody>
          </table>
          <hr />
          <div style="font-size: 12px; font-weight: bold; text-align: center;">
              <div>
                  (c) Cock Fight Sport Bets
              </div>
          </div>
      </div>
    `;
    const data = [{
      type: 'html',
      format: 'plain', // or 'plain' if the data is raw HTML
      data: html
    }];
    await qz.print(config, data);
  } catch (error) {
    console.log(">>>>", error)
  }
}

export async function onPrintWithdrawal(userName: string, balance: string) {
  if (!isPrinterActive()) {
    return;
  }
  try {
    const { user } = getStorageValue<{ user: string }>(APP_STORAGE_KEY);
    const { printer } = getStorageValue<{ printer: string }>(PRINTER_KEY);
    const date = getFormattedDateTime(new Date());

    !qz.websocket.isActive() && await qz.websocket.connect();
    const config = qz.configs.create(printer);

    const html = `
      <div class="ticket">
          <div style="text-align: center">
              <div style="font-weight: bold">Cock Fight Sport Bets</div>
              <div style="font-size: 12px;">cockfightsportbet.com</div>
          </div>
          <hr />
          <table>
              <tbody>
                  <tr>
                      <td>Fecha: </td>
                      <td>${date}</td>
                  </tr>
                  <tr>
                      <td>Atendido por: </td>
                      <td>${user}</td>
                  </tr>
              </tbody>
          </table>
          <div style="text-align: center;">
              <hr />
              - Retiro -
              <hr />
          </div>
          </table>
          <hr />
          <table style="border-collapse: collapse; width: 100%;">
              <tbody>
                  <tr style="text-align: left;">
                    <td>Usuario: ${userName}</td>
                  </tr>
                  <tr style="text-align: left;">
                    <td>Retiro: ${currency(balance)}</td>
                  </tr>
              </tbody>
          </table>
          <hr />
          <div style="font-size: 12px; font-weight: bold; text-align: center;">
              <div>
                  (c) Cock Fight Sport Bets
              </div>
          </div>
      </div>
    `;
    const data = [{
      type: 'html',
      format: 'plain', // or 'plain' if the data is raw HTML
      data: html
    }];
    await qz.print(config, data);
  } catch (error) {
    console.log(">>>>", error)
  }
}
