import { useEffect, useState } from "react"
import { BiLoaderAlt } from "react-icons/bi"
import { MdInfo, MdPrint } from "react-icons/md"
import { type Cuadre } from "src/appTypes"
import { useService } from "src/hooks"
import { Loader } from "src/layouts"
import { currency, onPrintCuadre } from "src/utils"

export interface CuadreDialogProps {
  isOpen: boolean
  date: string
  banker: string
  onCLose: () => void
}

export const CuadreDialog: React.FC<CuadreDialogProps> = ({ isOpen, date, banker, onCLose }) => {
  if (!isOpen) {
    return null;
  }
  const [detail, setDetail] = useState<Cuadre>({} as Cuadre);

  const reportSalesSvc = useService<Cuadre>({
    route: 'reportBankers',
    data: res => {
      setDetail(res);
    }
  });

  useEffect(() => {
    if (banker && date) {
      reportSalesSvc.get(`?banker=${banker}&date=${date}&sales=1`)
    }
  }, [date, banker]);

  const isLoading = reportSalesSvc.serviceLoading === 'get';

  function onPrint() {
    onPrintCuadre(detail)
  }

  function onCloseDialog() {
    onCLose();
  }

  return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-80 flex items-center justify-center z-40" onClick={onCloseDialog}>
            <div className="bg-white rounded-md w-full h-full md:w-1/2 lg:w-1/3 lg:h-fit flex flex-col justify-center items-center overflow-hidden" onClick={e => e.stopPropagation()}>
                {/*  */}
                <div className="flex-grow flex items-center justify-center flex-col text-left bg-gray-50 w-full p-4">
                    {!isLoading && <>
                        <h1 className="text-3xl">
                            <MdInfo className="text-9xl text-cta-blue" />
                        </h1>
                        <p className="text-cta-blue font-bold text-left text-lg">
                            Cuadre del dia
                        </p>
                        <div className="py-4" />
                        <ul className="grid grid-cols-2 gap-4 w-full">
                            <li className="flex gap-2 md:block">
                                <h1 className="font-bold">Total vendido: </h1>
                                <p>{currency((detail?.totalSold || 0).toString())}</p>
                            </li>
                            <li className="flex gap-2 md:block">
                                <h1 className="font-bold">Total Pagado: </h1>
                                <p>{currency((detail?.paidBetBill || 0).toString())}</p>
                            </li>
                            <li className="flex gap-2 md:block">
                                <h1 className="font-bold">Depositos: </h1>
                                <p>{currency((detail?.deposit || 0).toString())}</p>
                            </li>
                            <li className="flex gap-2 md:block">
                                <h1 className="font-bold">Total Retiro: </h1>
                                <p>{currency((detail?.withdrawal || 0).toString())}</p>
                            </li>
                            <li className="flex gap-2 md:block">
                                <h1 className="font-bold">Balance en caja: </h1>
                                <p>{currency((detail?.cashBClear || 0).toString())}</p>
                            </li>
                        </ul>
                    </>}
                    {isLoading && <Loader />}
                </div>
                {/*  */}
                <div className="border-t p-2 w-full flex justify-end items-center gap-2">
                    <button onClick={onCloseDialog} className="p-2 px-4 rounded-md bg-custom-danger bg-opacity-40 hover:opacity-80 font-semibold text-gray-600">Cancelar</button>
                    <button onClick={onPrint} className="p-2 px-4 border rounded-md bg-cta-blue text-white hover:opacity-80 flex gap-2 items-center">
                        {isLoading ? <BiLoaderAlt className="animate-spin text-white" /> : <MdPrint />}
                        Imprimir
                    </button>
                </div>
            </div>
        </div>
  )
};
