import { Alert, Drawer } from "src/components"
import { useGlobalModal } from "src/store"
import { KJUR, KEYUTIL, stob64, hextorstr } from 'jsrsasign';
import * as qz from 'qz-tray';
import { useEffect, useState } from "react";
import classNames from "classnames";
import { APP_STORAGE_KEY, generateKey, getStorageValue, setStorageValue, updateStorageValue } from "src/utils";
import { isEmpty } from 'lodash';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked, MdPrint } from "react-icons/md";

export const PRINTER_KEY = `${APP_STORAGE_KEY}-printer`;

export interface Options {
  printer: string
}

export const PrintersModal = () => {
  const { displayGlobalModal, setDisplayGlobalModal } = useGlobalModal();
  const [printers, setPrinters] = useState<string[]>([]);
  const [current, setCurrentPrinter] = useState<string>('');
  const [displayQuestion, setDisplayQuestion] = useState<boolean>(false);

  const handleClose = () => {
    setDisplayGlobalModal('none');
  }

  useEffect(() => {
    async function init() {
      try {
        const privateKey = "-----BEGIN PRIVATE KEY-----\n" +
          "MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDVYfT2UilUYAQZ\n" +
          "wgl9Q88MCe/N4MmpD6KivzeK9pzc3Vuo4ht5UYV/el0H+OX0AZUQXWHueSLsz+pV\n" +
          "R0lyGMD0o9Ex+CMZr4ED8jLywuSaRofb8CFeNn8ItwwIaM195whJZ37wqhWd/xdd\n" +
          "Q9+npRTXSEYbASVAfE+l4IkK1b38dOAtSxxKoV2voij8RyZY9Hfex8jG5GVB2YDr\n" +
          "NuKpxnhRHycEAqrnt2nzBsWJYE5n289QYqK4j4Ose8K1OLm8S4yhDHXyeLtKQ8rC\n" +
          "enwl2ppX6hE8nOArbKExyXrzcU2wkCyZQqfvvOGLdzg9XLeNW6xzoJDTtIw+BB8G\n" +
          "TQ8Hv6wrAgMBAAECggEARnt6Tii6dR/YCugQeVt+FwRrPQ5snhbHikluH80w6DWH\n" +
          "8sNo/sxCM+bnttvsnQouwj6OPBawRhexWCTZakIp7kT0MzS651+U1ohYct6UYy87\n" +
          "7hal9vg6QtcvttFjWxyGnLR+diFATbV2cryaMkZxmWDKo13f/6pwJqMBsV4DyND5\n" +
          "iuuYJFFSc1g+jTFPMRdP2ljGWnxR6c/cGtfATfk7PSag2jPmcIK7OTo8Vdo/FF4D\n" +
          "b9Ek7wlk5TidLC8zKpy/wZco9eiC8w2Fp/JhTUf8/JS+3putmOV1iiD++f+ImOGu\n" +
          "R51VrnjQJJYtA5b+l/dpZacQQKyiaxxQj6iAk9rvwQKBgQD5ASYr5eZqVn4eTBb2\n" +
          "70EEJvr6zCaRgQy2qnyqHEqaQaXuyffHM7V4VOKEEJ9HZtN7NE6ueCxDLUoxNCuO\n" +
          "5LQkCUUmHzzQtijsMrBCqDl8epqnFOOgAlSmJxhXOrrBvJJtMps24iSv0aKrAuyD\n" +
          "CuiM271hmkeMD+s0kDivziz8awKBgQDbYJ0r8xELpHaU2zNSB0Ayr45O/Y3TxnSV\n" +
          "ASvOcU7wQwYhZiBVfhRoODoabvwiPNQty8MCVwG1DpNzwK6ReZg+bH84igtR10/D\n" +
          "kErHyIflFnk+Ru/46K78UWLtlTe5pi+IVhu9SOg35/WJ5u4Txq3JgZ0qLYM4ajwj\n" +
          "GfklZe//QQKBgQCFLKsosUXBu86qD5H8sct5GAwFlKhQC9Ar5hTUulrgE5pWkyh1\n" +
          "Ht/Aos2fSnCVtJYI/9nREC7EIvXaSQe+3ezGlTqpf+17xuEuKpIApI5SWBRcphxz\n" +
          "2KtDTgeDp8J4mY1K39UJzntGmX1Br0elcnEb3O51xBjd/SmTrErVJBUFTwKBgDRZ\n" +
          "hsgRFdU4Zs+4yzNc5ZXyzLvOx8RsjC9uexylb1foNpNLwpWEI6yHFl5uc55b86oG\n" +
          "AMATMSYal1c8+KtgX9MtouJEFtVt0uvxB9dK5Brn1QDmfqdgJzmw1jGptQWJQbiA\n" +
          "C9iMiFWm9bH1uIRhmeEHHBEPkUuqFjWuIgUpoS7BAoGBAKuiP+ji+Qw276sXErcS\n" +
          "baLbkOKwpHJBQw/pqIY+hc+/OsobDziTtCKlLJkY7JHAGNgbkqr54Sok1dwCxYK1\n" +
          "0E5OlC5vu3LrC8jBlP3iLVQbjj1l8TNV7QT6mypBeDNmkfI8frN0W7DQirPWNZvX\n" +
          "eRVQPk25gSYzSxWTVSN6dF6l\n" +
          "-----END PRIVATE KEY-----\n";
        qz.security.setCertificatePromise(function(resolve: any, reject: any) {
          resolve("-----BEGIN CERTIFICATE-----\n" +
            "MIIECzCCAvOgAwIBAgIGAYqWJGR0MA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n" +
            "EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n" +
            "UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n" +
            "HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n" +
            "RGVtbyBDZXJ0MB4XDTIzMDkxNDAwMDEyMloXDTQzMDkxNDAwMDEyMlowgaIxCzAJ\n" +
            "BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n" +
            "VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n" +
            "IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n" +
            "VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDV\n" +
            "YfT2UilUYAQZwgl9Q88MCe/N4MmpD6KivzeK9pzc3Vuo4ht5UYV/el0H+OX0AZUQ\n" +
            "XWHueSLsz+pVR0lyGMD0o9Ex+CMZr4ED8jLywuSaRofb8CFeNn8ItwwIaM195whJ\n" +
            "Z37wqhWd/xddQ9+npRTXSEYbASVAfE+l4IkK1b38dOAtSxxKoV2voij8RyZY9Hfe\n" +
            "x8jG5GVB2YDrNuKpxnhRHycEAqrnt2nzBsWJYE5n289QYqK4j4Ose8K1OLm8S4yh\n" +
            "DHXyeLtKQ8rCenwl2ppX6hE8nOArbKExyXrzcU2wkCyZQqfvvOGLdzg9XLeNW6xz\n" +
            "oJDTtIw+BB8GTQ8Hv6wrAgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n" +
            "VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBS77GQCEsYKPWYmcUut+wFWHSLE9DANBgkq\n" +
            "hkiG9w0BAQsFAAOCAQEAOICGM267GOCvZxWan/Jy947hRCYCQmBjjl5gJHbcxXAr\n" +
            "pLlzjA5TJh5JqKjDfSOVVYV0bZAX4iwmLzmv8iY6Lk+ancEaH1jsUB5ETWczWFHm\n" +
            "hu8ngBjoRgHtrB+H5HqpNLpUOPtPE6P7gZVjspjyVRY8SmXJ+z68wcG32/KQzPrQ\n" +
            "KpfkkTGyVrRtW6hUXOH3KoVVqlzlp9orGrmR1siluvmvFXYtUtzjhDqNWO+ek1GQ\n" +
            "UlXD2jLPMYHwLOkq3BowJRS1rtoajjZtkLwRXe/FvHaa37rKbnSKCHvcOGch24gD\n" +
            "v3DG/QAMswZCJ2RotXYitzPFGcOsvEFEVdVbsPjQrQ==\n" +
            "-----END CERTIFICATE-----\n");
        });
        await qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        await qz.security.setSignaturePromise(function(toSign: any) {
          return function(resolve: any, reject: any) {
            try {
              const pk = KEYUTIL.getKey(privateKey);
              const sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
              sig.init(pk);
              sig.updateString(toSign);
              const hex = sig.sign();
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
              console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
              resolve(stob64(hextorstr(hex)));
            } catch (err) {
              console.error(err);
              reject(err);
            }
          };
        });
        !qz.websocket.isActive() && await qz.websocket.connect();
        // // Establecer el certificado
        //
        const printers = await qz.printers.find();
        setPrinters(printers);
      } catch (error) {
        console.error(">>>>", error)
      }
    }

    init()
  }, []);

  useEffect(() => {
    const storage = getStorageValue<Options>(PRINTER_KEY);

    if (!storage.printer) {
      setCurrentPrinter('');
    } else {
      setCurrentPrinter(storage.printer);
    }
  }, []);

  const onChangePrinter = (printer: string) => {
    return () => {
      setStorageValue(PRINTER_KEY, { printer });

      setCurrentPrinter(printer);
    }
  }

  const saveBet = async () => {
    try {
      !qz.websocket.isActive() && await qz.websocket.connect();

      const config = qz.configs.create(current);

      // Obtener la representación base64 del PDF
      const htmlContent = `
        <div class="ticket">
            <p class="centered">RECEIPT EXAMPLE
                <br>Address line 1
                <br>Address line 2</p>
            <table>
                <thead>
                    <tr>
                        <th class="quantity">Q.</th>
                        <th class="description">Description</th>
                        <th class="price">$$</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="quantity">1.00</td>
                        <td class="description">ARDUINO UNO R3</td>
                        <td class="price">$25.00</td>
                    </tr>
                    <tr>
                        <td class="quantity">2.00</td>
                        <td class="description">JAVASCRIPT BOOK</td>
                        <td class="price">$10.00</td>
                    </tr>
                    <tr>
                        <td class="quantity">1.00</td>
                        <td class="description">STICKER PACK</td>
                        <td class="price">$10.00</td>
                    </tr>
                    <tr>
                        <td class="quantity"></td>
                        <td class="description">TOTAL</td>
                        <td class="price">$55.00</td>
                    </tr>
                </tbody>
            </table>
            <p class="centered">Thanks for your purchase!
                <br>parzibyte.me/blog</p>
        </div>
      `;
      const data = [{
        type: 'html',
        format: 'plain', // or 'plain' if the data is raw HTML
        data: htmlContent
      }];

      await qz.print(config, data);

      setDisplayQuestion(true);
      // await qz.websocket.disconnect(); // Desconexión de QZ Tray
    } catch (error) {
      console.error('>>>', error);
      // await qz.websocket.disconnect(); // Desconexión de QZ Tray
    }
  }

  function savePrinterStatus(ready: boolean) {
    return () => {
      updateStorageValue(PRINTER_KEY, { ready });
      setDisplayQuestion(false);
    }
  }

  return (
        <Drawer
            isOpen={displayGlobalModal === 'printers'}
            onClose={handleClose}
            title="Impresoras"
        >
            <ul className="flex gap-4 flex-col p-4">
                {
                    !isEmpty(printers) && printers.map((printer, i) => {
                      return <li
                            key={generateKey(i, Date.now())}
                        >
                            <button type="button"
                                onClick={onChangePrinter(printer)}
                                className={classNames("w-full transition-all duration-300 border font-semibold rounded-md bg-red-600 p-4 flex items-center gap-4 cursor-pointer hover:text-white hover:bg-red-600 hover:border-transparent text-gray-600",
                                  {
                                    'bg-white': current !== printer
                                  },
                                  {
                                    'text-white': current === printer
                                  }
                                )}>
                                {current === printer ? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />} {printer}
                            </button>
                        </li>
                    })
                }

                {
                    isEmpty(printers) && (
                        <li className="flex-grow items-center justify-center flex">
                            <Alert kind="warning">
                                No existen impresoras disponibles.
                            </Alert>
                        </li>
                    )
                }
            </ul>

           <div className="px-4 flex flex-col gap-4 items-start justify-center">
                <button disabled={!current} className="disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2 rounded-md bg-red-600 text-white px-4 py-2" onClick={saveBet}>
                    <MdPrint /> Probar impresora
                </button>

                { displayQuestion && <div className="flex items-center justify-between p-4 border gap-4 rounded-lg bg-white w-full">
                  <h1>A sido la prueba satisfactoria?</h1>
                  <div className="flex gap-3">
                    <button type="button" onClick={savePrinterStatus(false)} className="text-red">No</button>
                    <button type="button" onClick={savePrinterStatus(true)} className="rounded-full px-4 py-1 bg-cta-blue text-white">Si</button>
                  </div>
                </div> }
            </div>
        </Drawer>
  )
}
