import { env } from "../env";

export const URL = env.REACT_APP_BASE_URL || '';

export const SERVICES = {
  BASE: '',
  AUTH: 'auth'
};

export const routes = {
  login: {
    svc: SERVICES.BASE,
    route: 'login'
  },
  coliseums: {
    svc: SERVICES.BASE,
    route: 'coliseums'
  },
  betsHistory: {
    svc: SERVICES.BASE,
    route: 'history-bets'
  },
  historyDetails: {
    svc: SERVICES.BASE,
    route: 'history-detail-day'
  },
  todayGames: {
    svc: SERVICES.BASE,
    route: 'today-games'
  },
  betPendingPlayer: {
    svc: SERVICES.BASE,
    route: 'bet-pending-player'
  },
  rechargeBalances: {
    svc: SERVICES.BASE,
    route: 'recharge-balances'
  },
  bettingTables: {
    svc: SERVICES.BASE,
    route: 'betting-tables'
  },
  betDetailPlayer: {
    svc: SERVICES.BASE,
    route: 'bet-detail-player'
  },
  playerBalance: {
    svc: SERVICES.BASE,
    route: 'player-balance'
  },
  bets: {
    svc: SERVICES.BASE,
    route: 'bets'
  },
  roles: {
    svc: SERVICES.BASE,
    route: 'roles'
  },
  bankers: {
    svc: SERVICES.BASE,
    route: 'bankers'
  },
  users: {
    svc: SERVICES.BASE,
    route: 'users'
  },
  runners: {
    svc: SERVICES.BASE,
    route: 'runners'
  },
  betSettings: {
    svc: SERVICES.BASE,
    route: 'bet-settings'
  },
  betDetails: {
    svc: SERVICES.BASE,
    route: 'bet-details'
  },
  dashboard: {
    svc: SERVICES.BASE,
    route: 'dashboard'
  },
  reports: {
    svc: SERVICES.BASE,
    route: 'reports/bankers-all'
  },
  bills: {
    svc: SERVICES.BASE,
    route: 'bet-bills'
  },
  reportBankers:{
    svc: SERVICES.BASE,
    route: 'reports/bankers'
  },
  players: {
    svc: SERVICES.BASE,
    route: 'players'
  },
  rechargeBalance: {
    svc: SERVICES.BASE,
    route: 'recharge-balances'
  },
  runnerInvoices: {
    svc: SERVICES.BASE,
    route: 'runner-invoices'
  }
}

export type RoutesKeys = keyof typeof routes;
