import classNames from 'classnames';
import {
  type DetailedHTMLProps,
  type InputHTMLAttributes,
  forwardRef,
  type HTMLAttributes
} from 'react';

interface Props
  extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  > {
  className?: HTMLAttributes<HTMLInputElement>['className']
  error?: string
  label: string
  right?: () => React.ReactNode
}

export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { className, error, right, ...attributes } = props;
  const baseClass = classNames('w-full border-b outline-none px-2 py-1 bg-transparent text-gray-700 font-medium disabled:opacity-70 disabled:cursor-not-allowed', className);
  return (
    <label className={classNames('w-full',
      { 'opacity-70 cursor-not-allowed': attributes.disabled }
    )}>
      <p className='text-gray-500 font-bold'>{props.label}</p>
      <div className='w-full flex items-center gap-2'>
        <input
          {...attributes}
          className={baseClass}
          ref={ref}
        />
        {right?.()}
      </div>
      <p className='text-red-700 py-2 font-semibold'>
        {error}
      </p>
    </label>
  );
});

Input.displayName = 'Input';
