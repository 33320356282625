import Headerv2 from './Headerv2';
import { MainFooter } from './MainFooter';
import { TopBar } from './Topbar';
import { SideBarMenu } from 'src/components/SideBarMenu';
interface LayoutProps {
  config?: {
    withTopBar?: boolean
    withSideBar?: boolean
    withFooter?: boolean
    footer?: {
      withLogo?: boolean
    }
  }
}
export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = (props) => {
  const { children, config } = props;
  const withTopBar = config?.withTopBar;
  const withSidebar = config?.withSideBar;
  const withFooter = config?.withFooter;

  return (
    <div className='h-full bg-white flex flex-col'>
      <Headerv2 />
      {withTopBar && <TopBar />}
      <div className="flex-grow flex">
        {withSidebar && <SideBarMenu />}
        <div className='flex-grow'>
          {children}
        </div>
      </div>
      {withFooter && <MainFooter />}
    </div>
  );
};
