import React from 'react'
import { Loader } from 'src/layouts'
import { useSelectedParlay } from 'src/store'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { findIndex } from 'lodash'
import { useAnimation } from 'src/hooks'

interface Props {
  data: Game
  to: number
  type?: number
  parlay?: boolean
}
const types = {
  1: 'A Tiempo',
  2: 'Money Line'
}
export default function ModalCard({ data, to, type, parlay }: Props) {
  const date = new Date(data.startDate);
  const { currentAnimation } = useAnimation();
  const { selectedParlay, setSelectedParlay } = useSelectedParlay();

  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}/${month}/${day}`;

  function getType() {
    if (data) {
      switch (to) {
        case 1:
          return `${data.fighters[0].team.name}(${data?.fighters[0]?.favorite ? '-' : '+'}${data?.fighters[0]?.betPercent})`
        case 2:
          return `${data.fighters[1].team.name}(${data?.fighters[1]?.favorite ? '-' : '+'}${data?.fighters[1]?.betPercent})`
        case 3:
          return `B ${data.lessTime.slice(3)}(${data.lessPercentage})`
        case 4:
          return `A ${data.moreTime.slice(3)}(${data.morePercentage})`
        default:
          return 'Ganador'
      }
    }
  }
  function removeParlay() {
    // lodash get index of selectedParlay
    const typeParlay = type === 2 ? 1 : 2
    const newParlay = findIndex(selectedParlay, { id: data.id, type: typeParlay })
    setSelectedParlay(selectedParlay.filter((_, index) => index !== newParlay));
  }
  return (
    <li className="pb-3 sm:pb-4 bg-white p-5 pt-2 rounded border">
      {parlay && selectedParlay.length > 2 && <div className="w-full flex justify-end mt-1">
        <button
          type="button"
          className=" flex h-4 w-4 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          onClick={() => {
            removeParlay()
          }}
        >
          <span className="sr-only">remover</span>
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>}
      {currentAnimation.id === data.id
        ? (
          <Loader />
          )
        : (
          <div className="mt-2">
            <div className="flex justify-between pb-3 flex-wrap items-center">
              <p className="modalName">
                <span className="modalNumber">#{data.fightNumber} |</span>{" "}
                {data.fighters[0].team.name}{" "}
                <span className="text-red-600 font-bold">VS</span>{" "}
                {data.fighters[1].team.name}
              </p>
              <p className="modalDate">{formattedDate}</p>
            </div>
            <hr />
            <div className="">
              {type === 2 && (
                <p className="mdoalNormalText">
                  <span className="modalType ">Tipo:</span> {types[to > 2 ? 1 : 2]}
                </p>
              )}
              <p className="mdoalNormalText">
                <span className="modalType ">Jugando a:</span>{" "}
                {type === 2
                  ? getType()
                  : `${data.fighters[to].team.name}(${data.fighters[0]?.favorite ? "-" : "+"
                  }${data.fighters[0]?.betPercent})`}
              </p>
            </div>
          </div>
          )}
    </li>

  )
}
