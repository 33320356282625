import { useForm } from "react-hook-form";

import { CButton, Drawer, Input } from "src/components"
import { type Base, useService } from "src/hooks";
import { useGlobalModal } from "src/store"
import { type UserForm, resolver } from "./validations";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { isEmail, onPrintCreateUser } from "src/utils";
import { BiLoaderAlt } from "react-icons/bi";
import { useValidatePrinter } from "src/hooks/useValidatePrinter";

export const CreateUserGlobalModal = () => {
  const { displayGlobalModal, setDisplayGlobalModal } = useGlobalModal();
  const { register, handleSubmit, formState: { errors }, clearErrors, reset, setError, watch } = useForm<UserForm>({
    resolver
  });
  const [notValidEmail, setNotValidEmail] = useState<boolean>(false);
  const email = watch('email');

  const { isValid, renderAlert } = useValidatePrinter();

  const playersSvc = useService<CreateUserResponse>({
    route: 'players',
    data: res => {
      reset({});
      clearErrors();
      playersSvc.clearError();
      setDisplayGlobalModal('none');
      setNotValidEmail(false);

      onPrintCreateUser(res.user.username, res.balance);
    }
  });

  const userSvc = useService<Base<User[]>>({
    route: 'users',
    data: res => {
      if (!isEmpty(res.data)) {
        setError('email',
          {
            message: 'Este email ya se encuentra en uso'
          },
          {
            shouldFocus: true
          }
        );
        setNotValidEmail(true)
      } else {
        clearErrors()
      }
    }
  });

  useEffect(() => {
    async function validateIfAlreadyInUse() {
      await userSvc.get(`?email=${email}`)
    }

    if (isEmail(email)) {
      validateIfAlreadyInUse();
    }

    if ((email || '').length <= 0) {
      clearErrors()
    }

    setNotValidEmail(false);
  }, [email])

  const handleCloseModal = () => {
    setDisplayGlobalModal('none');
    clearErrors();
    reset({});
    playersSvc.clearError();
  };

  const userForm = handleSubmit(async (data: UserForm) => {
    await playersSvc.post({
      data
    })
  });

  return (
    <Drawer
      isOpen={displayGlobalModal === 'create-user'}
      onClose={handleCloseModal}
      title="Crear usuario"
    >
      <form className="flex flex-col flex-1" onSubmit={userForm}>
        <div className="flex-grow bg-gray-50 relative">
          <div className="absolute inset-0 overflow-y-auto p-4">
            {renderAlert()}
            <Input
              {...register('names')}
              type="text"
              label="Nombres"
              placeholder="Ej: Juan"
              error={errors.names?.message}
            />
            <Input
              {...register('surNames')}
              label="Apellidos"
              placeholder="Ej: Sanchez"
              type="text"
              error={errors.surNames?.message}
            />
            <Input
              {...register('cardId')}
              label="Cédula"
              placeholder="Ej: 001-0000000-1"
              type="text"
              error={errors.cardId?.message}
            />
            <div className="flex items-center gap-2">
              <Input
                {...register('email')}
                label="Email"
                placeholder="Ej: ejemplo@gmai.com"
                type="email"
                error={errors.email?.message}
              />
              {userSvc.serviceLoading === 'get' && <BiLoaderAlt className="animate-spin text-cta-blue text-2xl" />}
            </div>
            <Input
              {...register('balance')}
              label="Balance"
              placeholder="Ej: $00.00"
              type="text"
              error={errors.balance?.message}
            />
          </div>
        </div>
        {/* action */}
        <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
          <CButton type='button' className="w-fit bg-transparent group p-3" onClick={handleCloseModal}>
            <p className="text-red-500 font-bold group-hover:text-red-800 transition-all duration-300">
              Cancelar
            </p>
          </CButton>

          <CButton
            type='submit'
            className="w-fit p-3 px-6"
            busy={playersSvc.serviceLoading === 'post' || userSvc.serviceLoading === 'get'}
            disabled={!!playersSvc.apiError || notValidEmail || !isValid}
          >
            <p className="text-white font-bold">
              Crear
            </p>
          </CButton>
        </div>
      </form>
    </Drawer>
  )
}
