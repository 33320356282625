import { useGlobalModal } from "src/store";
import { protectedRoutes } from 'src/router';

export function useSidebarActions() {
  const { setDisplayGlobalModal } = useGlobalModal();

  const actions = protectedRoutes.filter(p => p.action);

  const sideBarActions = actions.reduce((a, c) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { ...a, [c.action!]: () => setDisplayGlobalModal(c.action) }
  }, {}) as any;

  function getAction(type: SidebarAction) {
    if (!type) return () => {};

    return {
      ...sideBarActions
    }[type];
  }

  return {
    getAction
  };
}
