import { useForm } from "react-hook-form";

import { CButton, Drawer, Input } from "src/components"
import { type Base, useService } from "src/hooks";
import { type UserForm, resolver } from "../validations";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { isEmail } from "src/utils";
import { BiLoaderAlt } from "react-icons/bi";

export const CreatePlayerDialog: React.FC<CreateUserDialogProps> = ({ isOpen, onClose, onLoadMore, user }) => {
  const { register, handleSubmit, formState: { errors }, clearErrors, reset, setError, watch, setValue } = useForm<UserForm>({
    resolver,
    defaultValues: {
      email: ''
    }
  });
  const [notValidEmail, setNotValidEmail] = useState<boolean>(false);
  const email = watch('email');

  const playersSvc = useService({
    route: 'players',
    data: async res => {
      handleCloseModal();
      await onLoadMore();
    }
  });

  const userSvc = useService<Base<User[]>>({
    route: 'users',
    data: res => {
      if (!isEmpty(res.data)) {
        setError('email',
          {
            message: 'Este email ya se encuentra en uso'
          },
          {
            shouldFocus: true
          }
        );
        setNotValidEmail(true)
      } else {
        clearErrors()
      }
    }
  });

  useEffect(() => {
    async function validateIfAlreadyInUse() {
      await userSvc.get(`?email=${email}`)
    }

    if (isEmail(email) && !user?.id) {
      validateIfAlreadyInUse();
    }

    if ((email || '').length <= 0) {
      clearErrors()
    }

    setNotValidEmail(false);
  }, [email, user]);

  useEffect(() => {
    if (user?.id) {
      setValue('names', user.user.names);
      setValue('surNames', user.user.lastName);
      setValue('cardId', user.user?.cardId || '')
      setValue('email', user.user?.email || '')
    }
  }, [user])

  const handleCloseModal = () => {
    reset({});
    clearErrors();
    playersSvc.clearError();
    onClose();
    setNotValidEmail(false);
  };

  const userForm = handleSubmit(async (data: UserForm) => {
    if (user?.id) {
      playersSvc.update({
        id: user.id,
        data
      })
    } else {
      await playersSvc.post({
        data: {
          ...data,
          balance: 0
        }
      })
    }
  });

  return (
        <Drawer
            isOpen={isOpen}
            onClose={handleCloseModal}
            title={user?.id ? "Editar usuario" : "Crear usuario"}
        >
            <form className="flex flex-col flex-1" onSubmit={userForm}>
                <div className="flex-grow bg-gray-50 relative">
                    <div className="absolute inset-0 overflow-y-auto p-4">
                        <Input
                            {...register('names')}
                            type="text"
                            label="Nombres"
                            placeholder="Ej: Juan"
                            error={errors.names?.message}
                        />
                        <Input
                            {...register('surNames')}
                            label="Apellidos"
                            placeholder="Ej: Sanchez"
                            type="text"
                            error={errors.surNames?.message}
                        />
                        <Input
                            {...register('cardId')}
                            label="Cédula"
                            placeholder="Ej: 001-0000000-1"
                            type="text"
                            error={errors.cardId?.message}
                        />
                        <div className="flex items-center gap-2">
                            <Input
                                {...register('email')}
                                label="Email"
                                placeholder="Ej: ejemplo@gmai.com"
                                type="email"
                                error={errors.email?.message}
                            />
                            {userSvc.serviceLoading === 'get' && <BiLoaderAlt className="animate-spin text-cta-blue text-2xl" />}
                        </div>
                    </div>
                </div>
                {/* action */}
                <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
                    <CButton type='button' className="w-fit bg-transparent group p-3" onClick={handleCloseModal}>
                        <p className="text-red-500 font-bold group-hover:text-red-800 transition-all duration-300">
                            Cancelar
                        </p>
                    </CButton>

                    <CButton
                        type='submit'
                        className="w-fit p-3 px-6"
                        busy={playersSvc.serviceLoading === 'post' || userSvc.serviceLoading === 'update'}
                        disabled={!!playersSvc.apiError || notValidEmail}
                    >
                        <p className="text-white font-bold">
                            {user?.id ? 'Actualizar' : 'Crear'}
                        </p>
                    </CButton>
                </div>
            </form>
        </Drawer>
  )
}

interface CreateUserDialogProps {
  isOpen: boolean
  onClose: () => void
  onLoadMore: () => Promise<void>
  user?: PlayerUser
}
