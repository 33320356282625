import { ReactComponent as NotFoundImage } from 'src/assets/cock_vector.svg';
import classNames from 'classnames';

interface Props {
  message?: string
  desc?: string
  className?: string
}

type NoResultsProps = React.PropsWithChildren<Props>;

export const NoResult: React.FC<NoResultsProps> = ({ message = 'No hay resultados para mostrar! ', desc, children, className }) => {
  const classes = classNames('order-2 lg:order-1', className);

  return (
    <div className="p-6 px-2 grid grid-rows-2 lg:grid-rows-none items-center justify-center lg:grid-cols-2 h-fit lg:h-full container mx-auto">
      <div className={classes}>
        <div className='flex items-center lg:items-start justify-center h-fit lg:h-full lg:px-12 lg:pl-24 flex-col gap-4'>
          <h1 className="text-b-gray text-4xl font-bold">{message}</h1>
          <h2 className='text-[#CE0B0B] text-2xl font-bold'>{desc}</h2>
        {children}
        </div>
      </div>
      <div className='flex items-center justify-center lg:justify-start lg:px-12 w-full order-1 lg:order-2'>
        <NotFoundImage className='lg:h-96 lg:w-96 h-52 w-52' />
      </div>
    </div>
  )
}
