import { BiLoaderAlt } from 'react-icons/bi'

// props
interface Props {
  onClick: () => void
  disabled?: boolean
  loading?: boolean
  label: string
  classes?: string
}
export function Button({ onClick, disabled, label, classes, loading }: Props) {
  return (
    <button className={`font-medium text-white bg-[#181E5C] disabled:text-gray-300 disabled:bg-[#ECEFF2] disabled:cursor-not-allowed ${classes || ''}`} onClick={onClick} disabled={disabled}>
      {label}
      {loading && <BiLoaderAlt className='animate-spin' />}
    </button>
  )
}
