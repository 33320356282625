import { useSelectColiseum } from 'src/store'
import React from 'react'
interface Props {
  name: string
  id: number
  country: string
  city: string
  fights: number | undefined
}
function ItemCard({ name, country, city, fights, id }: Props) {
  const { selectedColiseum, setSelectedColiseum } = useSelectColiseum();
  const selected = selectedColiseum === id;
  const textSkin = selected ? 'text-white' : 'text-[#162B46]';
  const handleClick = () => {
    setSelectedColiseum(id);
  }
  return (
    <div className={`p-4 rounded-lg shadow-md mb-2 ${selected ? 'selectedCard' : 'bg-white'}`} onClick={handleClick}>
      <h2 className={`text-lg font-medium mb-2 cardTitle ${textSkin}`}>{name}</h2>
      <hr className="my-2 border-gray-300" />
      <p className={` mb-2 cardText ${textSkin}`}>{country}</p>
      <p className= {`mb-2 cardText ${textSkin}`}>{city}</p>
      <div className='flex justify-between'>
        <p className={'mb-2 cardBold CardText'}><span className='cardFights'>{fights ? `${fights}` : '0'} </span><span className={`${textSkin}`}>Peleas</span></p>

        <input type="radio" className="form-radio" name="radio-group" />
      </div>
    </div>
  )
}

export default ItemCard;
