import { useForm } from "react-hook-form";

import { CButton, Drawer, Input } from "src/components"
import { useService } from "src/hooks";
import { type UserPasswordForm, resolverPassword } from "../validations";
import { useState } from "react";
import { pick } from "lodash";

export const ChangePasswordDialog: React.FC<CreateUserDialogProps> = ({ isOpen, onClose, onLoadMore, user }) => {
  const { register, handleSubmit, formState: { errors }, clearErrors, reset } = useForm<UserPasswordForm>({
    resolver: resolverPassword
  });
  const [notValidEmail, setNotValidEmail] = useState<boolean>(false);

  const playersSvc = useService({
    route: 'users',
    data: async res => {
      handleCloseModal();
      await onLoadMore();
    }
  });

  const handleCloseModal = () => {
    reset({});
    clearErrors();
    playersSvc.clearError();
    onClose();
    setNotValidEmail(false);
  };

  const userForm = handleSubmit(async (data: UserPasswordForm) => {
    const payload = pick(data, ['password', 'enabled']);

    playersSvc.update({
      id: user?.user.id || 0,
      data: {
        ...payload,
        enabled: !payload.enabled
      }
    })
  });

  return (
        <Drawer
            isOpen={isOpen}
            onClose={handleCloseModal}
            title={'Actualizar contraseña'}
        >
            <form className="flex flex-col flex-1" onSubmit={userForm}>
                <div className="flex-grow bg-gray-50 relative">
                    <div className="absolute inset-0 overflow-y-auto p-4">
                        <div className="border rounded-md bg-white p-4 mb-4">
                            <div>
                                <h1 className="font-bold">Nombre completo</h1>
                                <p>{user?.user?.names} {user?.user?.lastName}</p>
                            </div>
                        </div>
                        <Input
                            {...register('password')}
                            label="Contraseña"
                            type="password"
                            error={errors.password?.message}
                        />

                        <label className="flex items-center gap-2 cursor-pointer select-none">
                            <input {...register('enabled', { value: false })} type="checkbox" />
                            <p className="text-gray-500 font-bold">Desea deshabilitar este usuario?</p>
                        </label>
                    </div>
                </div>
                {/* action */}
                <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
                    <CButton type='button' className="w-fit bg-transparent group p-3" onClick={handleCloseModal}>
                        <p className="text-red-500 font-bold group-hover:text-red-800 transition-all duration-300">
                            Cancelar
                        </p>
                    </CButton>

                    <CButton
                        type='submit'
                        className="w-fit p-3 px-6"
                        busy={playersSvc.serviceLoading === 'update'}
                        disabled={!!playersSvc.apiError || notValidEmail}
                    >
                        <p className="text-white font-bold">
                            {user?.id ? 'Actualizar' : 'Crear'}
                        </p>
                    </CButton>
                </div>
            </form>
        </Drawer>
  )
}

interface CreateUserDialogProps {
  isOpen: boolean
  onClose: () => void
  onLoadMore: () => Promise<void>
  user?: PlayerUser
}
