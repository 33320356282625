import classNames from "classnames";
import { isEmpty } from "lodash";
import { MdCheckCircle, MdDelete, MdInfo, MdQuestionMark, MdWarning } from "react-icons/md";
import { generateKey } from "src/utils";

export const Notification: React.FC<NotificationProps> = (props) => {
  const { type, message, title, actions, visible, onDismiss } = props;

  if (actions.length > 2) {
    throw new Error('Solo se permiten dos acciones');
  }

  const getIcon = {
    success: <MdCheckCircle />,
    delete: <MdDelete />,
    warning: <MdWarning />,
    info: <MdInfo />,
    asking: <MdQuestionMark />
  }[type];

  const iconContainer = (icon: React.ReactNode) => {
    const classes = classNames("flex items-center justify-center p-2 rounded-full text-white text-5xl w-fit",
      { "bg-red-600": type === 'delete' },
      { "bg-blue-800": type === 'asking' },
      { "bg-blue-800": type === 'info' }
    );
    return <div className={classes}>
      {icon}
    </div>
  }

  if (!visible) {
    return null;
  }

  return (
    <div
      onClick={onDismiss}
      className="absolute z-40 bg-gray-900 bg-opacity-60 inset-0 flex items-center justify-center">
      <div className="bg-white w-fit rounded-md p-4 flex flex-col items-center gap-4" onClick={e => e.stopPropagation()}>
        {iconContainer(getIcon)}
        <div className="">
          <h1 className="text-gray-500 font-bold">{title}</h1>
          <p>{message}</p>
        </div>
        <div className="flex items-center justify-end gap-4">
          {!isEmpty(actions) && actions.map(action => {
            return <button
              type="button"
              onClick={action.action}
              key={generateKey(action.label, Date.now())}
              className={classNames('font-bold duration-100 transition-all hover:opacity-80',
                { 'text-gray-500 font-semibold': action.type === 'none' },
                { 'text-red-800': action.type === 'remove' },
                { 'text-cta-blue': action.type === 'success' }
              )}
            >
              {action.label}
            </button>
          })}
        </div>
      </div>
    </div>
  )
}

type NotificationType = 'success' | 'delete' | 'warning' | 'info' | 'asking';

export interface NotificationProps {
  visible: boolean
  onDismiss: () => void
  type: NotificationType
  title: string
  message?: string
  actions: Array<{
    label: string
    icon?: React.ReactNode
    action: () => void
    type: 'success' | 'none' | 'remove'
  }>
}
