import classNames from "classnames"
import { MdPrint } from "react-icons/md"
import { Alert } from "src/components"
import { isPrinterActive } from "src/utils"

export function useValidatePrinter() {
  function renderAlert() {
    return !isPrinterActive() && <div className="mb-4">
      <Alert kind="error">
        No existe una impresora configurada. en estos momentos no estará disponible esta acción.
      </Alert>
    </div>
  }

  function renderBadge() {
    return <div className={classNames('user-select-none ml-4  flex items-center gap-2 rounded-full pr-4 bg-red-600', {
      "bg-green-600": isPrinterActive()
    })}>
      <div className='p-2 rounded-full bg-white'>
        <MdPrint className={classNames(
          { "text-green-600": isPrinterActive() },
          { "text-red-600": !isPrinterActive() }
        )} />
      </div>
      <h1 className='text-white'>
        {isPrinterActive() ? 'En linea' : 'Sin conexión'}
      </h1>
    </div>
  }
  return {
    renderAlert,
    renderBadge,
    isValid: isPrinterActive()
  }
};
