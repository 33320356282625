import momenttz from 'moment-timezone';
import 'moment/locale/es';

const time = 'YYYY-MM-DD h:mm:ss a';
export const year = 'YYYY-MM-DD';
export const hours = 'h:mm a';
export const month = 'MMMM DD, YYYY';
export const smallMonth = 'MMM DD, YYYY';

export type format = 'time' | 'year' | 'hours' | 'month' | 'smallMonth';

const getFormat = (key: format = 'time') => {
  return {
    time,
    year,
    hours,
    month,
    smallMonth
  }[key]
}

export function formatDate(dateToFormat: any) {
  return momenttz(dateToFormat).tz('America/Santo_Domingo').locale('en-ES').format(time);
}

export function getFormattedDateTime(dateToFormat: any, format: format = 'time') {
  if (!dateToFormat) {
    return ''
  }
  return momenttz(dateToFormat, time).locale('es-ES').format(getFormat(format))
}
