interface TitleWithIconProps {
  title: string
  icon: React.ReactNode
}

export const TitleWithIcon: React.FC<React.PropsWithChildren<TitleWithIconProps>> = ({ title, icon }) => {
  return (
    <div className="h-12 text-white justify-between flex w-fit items-center border rounded-lg">
      {/* icon */}
      <div className="rounded-l-lg h-full pl-6 pr-8 flex items-center justify-center bg-[#1a3150] clip">
        <span className="mr-4">
          {icon}
        </span>
      </div>
      {/* title */}
      <div className=" text-[#2F3576] h-full text-center align-middle flex items-center pr-6">
        <h1 className="text-sm font-bold uppercase">{title}</h1>
      </div>
    </div>
  );
};
