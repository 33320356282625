import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { MdClose, MdError } from "react-icons/md"
import { IoMdEye, IoMdEyeOff } from "react-icons/io"
import { CButton, Input } from "src/components"
import { type UserForm, usersSchemaResolver, BANKER } from "./validations";
import { type Base, useService } from "src/hooks";
import { useEffect, useState } from "react";
import { Loader } from "src/layouts";
import { find, get, isEmpty, omit } from "lodash";
import { isEmail } from "src/utils";
import { BiLoaderAlt } from "react-icons/bi";
// import { generateKey } from "src/utils";
// import { isEmpty } from "lodash";

export const CreateUserDialog: React.FC<CreateUserDialogProps> = (props) => {
  const { isOpen } = props;
  const [roles, setRoles] = useState<Role[]>([]);
  const [coliseums, setColiseums] = useState<Coliseum[]>([]);
  const [showPassword, setPassword] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [disabledFields, setDisabledFields] = useState<Record<string, { field: string, disabled: boolean }>>({});
  const { register, handleSubmit, formState: { errors }, clearErrors, reset, control, setValue, watch, setError } = useForm<UserForm>({
    resolver: usersSchemaResolver,
    defaultValues: {
      enabled: true,
      email: '',
      username: ''
    },
    context: {
      isEditing
    }
  });

  const [notValidEmail, setNotValidEmail] = useState<boolean>(false);

  const email = watch('email');
  const username = watch('username');

  console.log(email, username)

  const [isBanker, setIsBanker] = useState<boolean>(false);

  const rolesSvc = useService<Base<Role[]>>({
    route: 'roles',
    data: res => {
      setRoles(res.data);
    }
  });

  const bankerSvc = useService<Base<{}>>({
    route: 'bankers',
    data: res => {
      onClose();

      props.onLoadUserAfterClose();
    }
  });

  const coliseumsSvc = useService<Base<Coliseum[]>>({
    route: 'coliseums',
    data: res => {
      setColiseums(res.data)
    }
  });

  const onClose = () => {
    setDisabledFields({})

    setIsBanker(false);
    setIsEditing(false);
    setUserId(0);

    clearErrors();
    reset();

    props.onClose();
  }

  const userSvc = useService<Base<UserModel[]>>({
    route: 'users',
    data: (res, operation) => {
      if (!isEmpty(res.data) && operation === 'get') {
        console.log(res.data)
        if (email) {
          setError('email',
            {
              message: 'Este email ya se encuentra en uso'
            },
            {
              shouldFocus: true
            }
          );
        }
        if (username) {
          setError('username',
            {
              message: 'Este nombre de usuario ya se encuentra en uso'
            },
            {
              shouldFocus: true
            }
          );
        }
        setNotValidEmail(true)
      } else {
        clearErrors()
      }

      if (operation === 'post' || operation === 'update') {
        onClose();
        props.onLoadUserAfterClose();
      }
    }
  });

  useEffect(() => {
    rolesSvc.get()
  }, []);

  useEffect(() => {
    if (isBanker && isEmpty(coliseums)) {
      coliseumsSvc.get();
    }
  }, [isBanker, coliseums]);

  useEffect(() => {
    if (props.user?.id) {
      const { user } = props;
      setUserId(user.id);
      setIsEditing(true);
      // set up edit process
      const userColiseums = get(user, 'banker.coliseum', []) as Array<{ id: number, name: string }>;
      setValue('names', user.names || '');
      setValue('lastName', user.lastName || '');
      setValue('email', user.email || '');
      setValue('role', user.role.id.toString());
      setValue('username', user.username);
      setValue('enabled', user.enabled);
      setValue('password', get(user, 'password', ''))
      //
      if (!isEmpty(userColiseums) && user?.role?.id.toString() === BANKER) {
        setValue('coliseum', { data: userColiseums.map(i => i.id.toString()) })
      }

      setDisabledFields({
        username: {
          field: 'username',
          disabled: true
        },
        role: {
          field: 'role',
          disabled: true
        }
      });

      // display
      if (props.user.role.id.toString() === BANKER) {
        setIsBanker(true);
      }
    }
  }, [props.user]);

  useEffect(() => {
    if (userSvc.apiError) {
      userSvc.clearError();
    }
    if (coliseumsSvc.apiError) {
      coliseumsSvc.clearError();
    }

    if (bankerSvc.apiError) {
      bankerSvc.clearError();
    }
  }, [userSvc.apiError, coliseumsSvc.apiError, bankerSvc.apiError])

  useEffect(() => {
    async function validateIfAlreadyInUse() {
      const query = Object
        .entries({
          email,
          username
        })
        .filter(([k, v]) => {
          return v
        })
        .map(([key, value]) => (`${key}=${value}`))
        .join('&');

      await userSvc.get(`?${query}`)
    }

    if (isEmail(email) || username.length >= 4) {
      validateIfAlreadyInUse();
    }

    if ((email || '').length <= 0 || username.length <= 0) {
      clearErrors()
    }

    setNotValidEmail(false);
  }, [email, username])

  const userForm = handleSubmit(async (data: UserForm) => {
    if (data.role === BANKER) {
      // call here;
      if (userId) {
        // update here
        bankerSvc.update({
          id: get(props.user, 'banker.id', 0) as number,
          data: {
            ...data,
            user: userId,
            coliseum: {
              data: data.coliseum?.data.map(id => +id)
            },
            role: +data.role
          }
        });
      } else {
        // create
        bankerSvc.post({
          data: {
            ...data,
            coliseum: {
              data: data.coliseum?.data.map(id => +id)
            },
            role: +data.role
          }
        });
      }
    } else {
      if (userId) {
        userSvc.update({
          id: userId,
          data: omit(data, 'coliseum')
        });
      } else {
        userSvc.post({ data: omit(data, 'coliseum') });
      }
    }
  });

  const isLoading = rolesSvc.serviceLoading === 'get';
  const isUserSvcLoading = userSvc.serviceLoading === 'post' || bankerSvc.serviceLoading === 'post';

  if (!isOpen) {
    return null;
  }

  const roleLabels = [{ value: '0', label: 'Seleccione un rol' }, ...roles.filter(role => role.id === 6 || role.id === 1).map(role => ({ value: `${role.id}`, label: role.name } as const))] as any
  const coliseumsLabels = [{ value: '0', label: 'Seleccione un Coliseo' }, ...coliseums.map(coliseum => ({ value: `${coliseum.id}`, label: coliseum.name } as const))] as any

  return (
    <div className="absolute inset-0 z-10 bg-black bg-opacity-70 flex justify-end" onClick={onClose}>
      <div className="w-full lg:w-1/3 bg-white h-full flex flex-col" onClick={e => e.stopPropagation()}>
        {/* header */}
        <div className="p-4 flex items-center border-b bg-white">
          <div className="flex-grow">
            <h1 className="font-bold">{!userId ? "Agregar usuario" : 'Editar usuario'}</h1>
          </div>

          <CButton className="bg-red-400 rounded-[300rem] hover:bg-red-500 transition-all duration-300 p-2" onClick={onClose}>
            <MdClose size={24} />
          </CButton>
        </div>
        {/*  */}
        <form className="flex flex-col flex-1" onSubmit={userForm}>
          <div className="flex-grow bg-gray-50 relative">
            <div className="absolute inset-0 overflow-y-auto">
              {/* loader */}
              {isLoading && <Loader />}
              {/* has error */}
              {(userSvc.apiError || bankerSvc.apiError) && <div className="h-full flex flex-col items-center justify-center gap-2">
                <MdError size={52} className="text-red-600" />
                <h1 className="font-medium text-gray-600">Algo salio mal!</h1>
                <p>No fue posible crear el usuario.</p>
              </div>}
              {/* content */}
              {!isLoading && !userSvc.apiError && <div className="p-4 flex flex-col gap-4">
                <Input
                  {...register('names')}
                  label="Nombres"
                  placeholder="Ej: Juan"
                  error={errors.names?.message}
                />
                <Input
                  {...register('lastName')}
                  label="Apellidos"
                  placeholder="Ej: Fausto"
                  error={errors.lastName?.message}
                />
                <div className="flex items-center gap-2">
                  <Input
                    {...register('email')}
                    label="Email"
                    placeholder="Ej: ejemplo@gmai.com"
                    type="email"
                    error={errors.email?.message}
                  />
                  {userSvc.serviceLoading === 'get' && <BiLoaderAlt className="animate-spin text-cta-blue text-2xl" />}
                </div>
                <Input
                  {...register('username')}
                  className="disabled:cursor-not-allowed disabled:border-dashed disabled:border-spacing-x-10 disabled:opacity-60"
                  label="Nombre de usuario"
                  placeholder="Ej: jfausto"
                  disabled={disabledFields?.username?.disabled}
                  error={errors.username?.message}
                />
                <Input
                  {...register('password')}
                  label="Contraseña"
                  placeholder="Ej: ********"
                  error={!isEditing ? errors.password?.message : ''}
                  type={!showPassword ? 'password' : 'text'}
                  right={() => {
                    return <CButton
                      className="bg-transparent p-2 rounded-[300rem] hover:bg-gray-200 transition-all duration-300"
                      type='button' onClick={() => setPassword(prev => !prev)}>
                      {!showPassword ? <IoMdEye size={24} className="text-gray-400" /> : <IoMdEyeOff size={24} className="text-gray-400" />}
                    </CButton>
                  }}
                />
                <label className="w-full flex flex-col">
                  <p className='text-gray-500 font-bold'>Roles</p>
                  <Controller
                    name="role"
                    control={control}
                    render={({ field }) => <Select
                      {...field}
                      isSearchable
                      isDisabled={disabledFields?.role?.disabled}
                      className="disabled:cursor-not-allowed"
                      onChange={e => {
                        field.onChange(e.value)

                        setIsBanker(e.value === BANKER);
                      }}
                      value={roleLabels.find((e: any) => e.value === field.value)}
                      options={roleLabels}
                    />}
                  />
                  <p className='text-red-700 py-2 font-semibold'>{errors.role?.message}</p>
                </label>

                {isBanker && <label className="w-full flex flex-col">
                  <p className='text-gray-500 font-bold'>Coliseos</p>
                  <Controller
                    name="coliseum"
                    control={control}
                    render={({ field }) => {
                      return <Select
                        {...field}
                        isSearchable
                        isMulti
                        className="disabled:cursor-not-allowed"
                        onChange={e => {
                          field.onChange({ data: e.map(v => v.value) });
                        }}
                        value={coliseumsLabels.filter((p: any) => find(field.value?.data || [], v => v === p.value))}
                        options={coliseumsLabels}
                      />
                    }}
                  />
                  <p className='text-red-700 py-2 font-semibold'>{errors.coliseum?.message}</p>
                </label>}

                <label className="flex items-center gap-2 cursor-pointer select-none">
                  <input {...register('enabled', { value: true })} type="checkbox" />
                  <p className="text-gray-500 font-bold">Habilitar este usuario</p>
                </label>

              </div>}
            </div>
          </div>
          {/* action */}
          <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
            <CButton type='button' className="w-fit bg-transparent group p-3" onClick={onClose}>
              <p className="text-red-500 font-bold group-hover:text-red-800 transition-all duration-300">
                Cancelar
              </p>
            </CButton>

            <CButton
              type='submit'
              className="w-fit p-3 px-6"
              busy={isLoading || isUserSvcLoading}
              disabled={!!userSvc.apiError || notValidEmail}
            >
              {!userId ? "Guardar" : 'Actualizar'}
            </CButton>
          </div>
        </form>
      </div>
    </div>
  )
}

interface CreateUserDialogProps {
  isOpen: boolean
  onClose: () => void
  onLoadUserAfterClose: () => void
  user: UserModel
}
