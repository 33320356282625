import { TbReload } from 'react-icons/tb';
import { NoResult } from 'src/components';

export const ErrorPage = () => {
  function onNavigate() {
    location.reload();
  }
  return (
    <div className="h-screen flex items-center justify-center">
        <NoResult
            message='Lo sentimos'
            desc='Algo salio mal al intentar cargar esta vista'
        >
            <button
             type='button'
             onClick={onNavigate}
             className='p-4 rounded-lg bg-[#181E5C] text-white flex gap-2 items-center group transition-all duration-300'>
                <TbReload />
                <p>Recargar</p>
            </button>
        </NoResult>
    </div>
  )
}
