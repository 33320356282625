import SearchGallo from '../assets/SearchGallo.svg'

const NotFound = () => {
  return (
    <div className="flex items-center flex-col justify-center h-full w-full">
      <div className="text-gray-700 text-[2vw]">
        <img src={SearchGallo} alt="SearchGallo" className='max-w-xs'/>
      </div>
      <div className="text-gray-500 text-xl ml-4 CardText BigText m-5" >
      No se encuentran Peleas en este coliseo
      </div>
    </div>
  );
};

export default NotFound;
