import { useColiseum, useGames } from 'src/store';
import ItemCard from 'src/components/ItemCard';

export const Sidebar = () => {
  const { coliseum } = useColiseum();
  const { games } = useGames();

  const coliseumsId = games.map((game) => game.coliseum.id);

  return (
    <div className="bg-gray-200 hidden lg:flex flex-col w-1/4 2xl:w-1/6 py-4 lg:px-4 gap-4">
      <div>
        <h2 className='text-[#0F1345] font-black italic text-left'>COLISEOS</h2>

        <h3 className='text-[#566779] font-medium font-Montserrat text-sm text-left'>Haga click en un coliseo para ver sus peleas</h3>
        <hr className='w-1/4 bg-[#CE0B0B] h-2' />
      </div>

      <div className='flex-grow relative'>
        <div className="inset-0 absolute overflow-y-auto">
            {
              coliseum.map((battle) => {
                if (coliseumsId.includes(battle.id)) {
                  return (
                    <ItemCard
                      key={battle.id}
                      name={battle.name}
                      city={battle.city}
                      country={battle.country}
                      fights={battle.fights}
                      id={battle.id}
                    />
                  )
                }
                return null
              })
            }
        </div>
      </div>
    </div>
  )
}
