import classNames from 'classnames';
import { FaCheckCircle, FaExclamationTriangle, FaInfoCircle, FaRegTimesCircle, FaTimesCircle } from 'react-icons/fa'

export const Alert: AlertTypes = (props) => {
  function generateAlertContentStyles(type: AlertKind) {
    return {
      success: 'text-green-600',
      error: 'text-red-600',
      info: 'text-blue-900',
      warning: 'text-yellow-800'
    }[type];
  }

  function generateIcon(type: AlertKind) {
    return {
      success: <FaCheckCircle className={generateAlertContentStyles(type)}/>,
      error: <FaTimesCircle className={generateAlertContentStyles(type)}/>,
      info: <FaInfoCircle className={generateAlertContentStyles(type)}/>,
      warning: <FaExclamationTriangle className={generateAlertContentStyles(type)}/>
    }[type];
  }

  function generateAlertStyles(type: AlertKind) {
    return {
      success: 'bg-green-200 border-green-300',
      error: 'bg-red-200 border-red-300',
      info: 'bg-blue-200 border-blue-300',
      warning: 'bg-yellow-200 border-yellow-300'
    }[type];
  }

  const classes = classNames('w-full flex items-start justify-between p-4 rounded-md gap-3 border border-2', { [generateAlertStyles(props.kind)]: props.kind });
  const contentClasses = classNames('flex-grow font-medium', generateAlertContentStyles(props.kind));

  return (
    <div className={classes}>
      {/* icons */}
      <div className='flex items-center justify-center text-2xl'>
        {generateIcon(props.kind)}
      </div>
      {/* content */}
      <div className={contentClasses}>
        {props.children}
      </div>
      {/* action */}
      {props.action && <div>
        <button type='button' title='close' aria-label='close' onClick={props.action}>
          <FaRegTimesCircle />
        </button>
      </div>}
    </div>
  )
}

type AlertKind = 'success' | 'error' | 'info' | 'warning';

interface AlertProps {
  kind: AlertKind
  action?: () => void
}

type AlertTypes = React.FC<React.PropsWithChildren<AlertProps>>;
