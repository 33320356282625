import { CButton, Drawer, Input } from "src/components"
import { type CancelBetDetailsType, type CancelBetType } from "../CancelBetType";
import { betStatusIds, betStatusType, betType, currency, generateFighterInfo, generateKey, getActualFighter, getFormattedDateTime, isBanker } from "src/utils";
import { get, isEmpty } from "lodash";
import { useService, type Base } from "src/hooks";
import { useEffect, useState } from "react";
import { Loader } from "src/layouts";
import { MdClose, MdError, MdInfo } from "react-icons/md";
import { toast } from 'react-hot-toast';

export const CancelBetsDrawer: React.FC<CancelBetsProps> = (props) => {
  const { isOpen, onCLose, bet } = props;
  const [betDetails, setBetDetails] = useState<CancelBetDetailsType[]>([]);
  const [code, setCode] = useState<string>('');
  const [isValid, setIsValid] = useState<{ displayError: boolean, called: boolean, valid?: boolean }>({ displayError: false, called: false });

  const betDetailsSvc = useService<Base<CancelBetDetailsType[]>>({
    route: 'betDetails',
    data: (res) => {
      setBetDetails(res.data);
    }
  });

  const handleClose = () => {
    onCLose();
    betDetailsSvc.clearError();
    setBetDetails([]);
    setCode('');
    setIsValid({
      displayError: false,
      valid: false,
      called: false
    })
  }

  function displayToast(message: string) {
    toast((t) => (
      <div className="flex items-start gap-2 p-4">
        <div className="flex items-start gap-2 text-red-600">
          <MdInfo size={42}/> <h1 className="font-semibold">{message}</h1>
        </div>

        <button onClick={() => toast.dismiss(t.id)}>
          <MdClose className="text-2xl"/>
        </button>
      </div>
    ));
  }

  const betSvc = useService({
    route: 'bets',
    data: (res, operation) => {
      if (res && operation === 'get') {
        const displayError = isEmpty(get(res, 'data', []) || []);

        if (displayError) {
          setIsValid({
            displayError,
            called: true,
            valid: !displayError
          });
        }

        const payload = {
          status: betStatusIds.canceled, // cancelado
          gameMode: bet.gameMode.id,
          betAmount: bet.betAmount
        };

        if (!displayError) {
          betSvc.update({ id: bet.id, data: payload });
        }
      }

      if (res && operation === 'update') {
        handleClose();
        props.onRefresh();
      }
    },
    options: {
      messages: {
        update: {
          hideMessage: isBanker()
        }
      }
    }
  });

  useEffect(() => {
    if (bet?.id) {
      betDetailsSvc.get(`/?bet=${bet.id}`)
    }
  }, [bet]);

  useEffect(() => {
    if (betSvc.apiError) {
      console.log(get(betSvc.apiError.response?.data, 'message', '') || '')
      // Time to cancel expired ticket
      const message = get(betSvc.apiError.response?.data, 'message', '') || '';
      const str = "Time to cancel expired ticket";

      if (message.toLowerCase().includes(str.toLowerCase())) {
        displayToast('El tiempo para cancelar este ticket expiro!')
      }

      betSvc.clearError();
    }
  }, [betSvc.apiError])

  const onCancelBet = () => {
    const payload = {
      status: betStatusIds.canceled, // cancelado
      gameMode: bet.gameMode.id,
      betAmount: bet.betAmount
    };
    if (isBanker()) {
      betSvc.get(`?id=${bet.id}&verificationCode=${code}`);
    } else {
      betSvc.update({ id: bet.id, data: payload });
    }
  }

  const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = event;

    setCode(value);

    setIsValid(prev => ({ ...prev, displayError: false }))
  }

  const isLoading = betSvc.serviceLoading === 'update';
  const loadingContent = betDetailsSvc.serviceLoading === 'get';

  return (
    <Drawer title="Detalles de la apuesta" isOpen={isOpen} onClose={handleClose}>
      <div className="flex flex-grow flex-col bg-gray-50">
        <div className="flex-1 relative flex justify-center items-center">
          {betDetailsSvc.apiError && !bet && <div className="text-center flex items-center justify-center flex-col gap-2">
            <MdError size={42} className="text-red-600"/>
            <h1 className="font-bold text-red-800">No fue posible cargar la información</h1>
          </div>}
          {loadingContent && !betDetailsSvc.apiError && <Loader />}
          {!loadingContent && !betDetailsSvc.apiError && <div className="absolute inset-0 overflow-y-auto p-4 flex flex-col gap-4">
            <div className="grid grid-cols-2 gap-6">
              {/*  */}
              <div>
                <h1>Arriesgando:</h1>
                <p className="font-bold">{currency(bet?.betAmount || '0', 'USD')}</p>
              </div>
              {/*  */}
              <div>
                <h1>A ganar:</h1>
                <p className="font-bold">{currency(bet?.earnedAmount || '0', 'USD')}</p>
              </div>
              {/*  */}
              <div>
                <h1>Estado:</h1>
                <p className="font-bold">{betStatusType[bet?.status]}</p>
              </div>
              {/*  */}
              <div>
                <h1>Tipo:</h1>
                <p className="font-bold">{betType[bet?.gameMode?.id] || 0}</p>
              </div>

              <div>
                <h1>Coliseo:</h1>
                <p className="font-bold">{(bet?.betDetails?.length && bet?.betDetails[0]?.game?.coliseum?.name) || ''}</p>
              </div>

              <div>
                <h1>Fecha:</h1>
                <div className="font-bold flex flex-col">
                  <p>{getFormattedDateTime(bet.createdAt)}</p>
                </div>
              </div>
            </div>
            {
              isBanker() && <div className="border-t pt-2">
              <Input
                label="Código de verificación"
                placeholder="12345"
                value={code}
                onChange={handleUserInput}
                error={(!isLoading && isValid.displayError && isValid.called) ? "El código proporcionado no coincide con nuestros registros." : undefined}
              />
            </div>
            }
            {/* Details */}
            <h1>Detalles:</h1>
            <ul className="flex flex-col gap-4">
              {
                !isEmpty(betDetails) && betDetails.map(e => {
                  return <li className='p-4 border rounded-md bg-white' key={generateKey(e.id, e.betType.name)}>
                    <div className="border-b pb-4">
                      <p className="text-sm">
                        <span className="">
                          <b>#{e?.game?.fightNumber || 0}</b> |</span>{" "}
                        {e?.game?.fighters[0]?.team.name || ''}{" "}
                        <span className="text-red-600 font-bold">VS</span>{" "}
                        {e?.game?.fighters[1]?.team.name || ''}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 py-2">
                      {/* game type */}
                      <p>
                        <b>Tipo:</b> {e?.betType?.name || ''}
                      </p>
                      {/* playing to */}
                      <p>
                        <b>Jugando a:</b> {generateFighterInfo(e, e.fighter?.id)}
                      </p>
                      <p>
                        <b>Estado del juego:</b> {betStatusType[e.game?.status]}
                      </p>
                      {/* playing to */}
                      <p>
                        <b>Ganador:</b> {getActualFighter(e.game?.fighters, e.fighter?.id)?.isWinner ? getActualFighter(e.game?.fighters, e.fighter?.id)?.team.name : 'N/A'}
                      </p>
                    </div>
                  </li>
                })
              }
            </ul>
            {/*  */}
          </div>}
        </div>
        <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
          <CButton type='button' className="w-fit bg-transparent group p-3" onClick={handleClose}>
            <p className="text-cta-blue font-bold group-hover:opacity-80 transition-all duration-300">
              Cancelar
            </p>
          </CButton>

          <CButton
            type='submit'
            className="w-fit p-3 px-6 bg-red-500 enabled:hover:bg-opacity-80"
            onClick={onCancelBet}
            busy={isLoading || loadingContent}
            disabled={!!betDetailsSvc.apiError || (isBanker() && code.length <= 3)}
          >
            {isBanker() ? 'Validar y cancelar apuesta' : 'Cancelar apuesta'}
          </CButton>
        </div>
      </div>
    </Drawer>
  )
};

interface CancelBetsProps {
  isOpen: boolean
  onCLose: () => void
  bet: CancelBetType
  onRefresh: () => void
}
