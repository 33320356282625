import { useState, useEffect, useMemo } from 'react';
import { useModalParlay, useSelectedParlay } from 'src/store';
import BaseModal from '../BaseModal'
import ModalCard from '../modalCard'
import { useService } from 'src/hooks';
import { Alert, Button } from 'src/components';
import { calculatePairlay, currency, printParlay } from 'src/utils';
import { isEmpty } from 'lodash';

export const ParlayModal: React.FC<{ setting?: BetSettings }> = ({ setting }) => {
  const { selectedParlay, setSelectedParlay } = useSelectedParlay();
  const { isModalOpenParlay, setIsModalOpenParlay } = useModalParlay();
  const [amount, setAmount] = useState('');

  const handleModal = (status: boolean) => {
    return () => {
      setIsModalOpenParlay(status);
    }
  }

  const bet = useService<{ data: any }>({
    route: 'bets',
    data: async res => {
      if (res) {
        handleModal(false)()
        setAmount('');
        printParlay(res, selectedParlay);
        setSelectedParlay([]);
      }
    }
  });

  function disableButton() {
    return bet.serviceLoading === 'post' || +amount <= 0 || amount === '' || selectedParlay.length <= 1;
  };

  useEffect(() => {
    if (selectedParlay.length === 0) {
      setIsModalOpenParlay(false)
    }
  }, [selectedParlay])

  function saveBet() {
    const data = {
      betAmount: amount,
      earnedAmount: calculatePairlay(selectedParlay, amount),
      status: 1,
      gameMode: 2,
      betDetails:
        selectedParlay.map((item) => {
          const type = item.selected > 2 ? 2 : 1
          const fighter = type === 1 ? item.Game.fighters[item.selected === 2 ? 1 : 0] : undefined
          return {
            game: item.Game.id,
            betType: type,
            fighter: fighter ? fighter.id : undefined,
            favorite: fighter ? fighter.favorite : undefined,
            minTime: item.selected === 3 ? item.Game.lessTime : undefined,
            maxTime: item.selected === 4 ? item.Game.lessTime : undefined,
            betPercent: fighter ? fighter.betPercent : item.Game.morePercentage
          }
        })
    };

    bet.post({ data })
  };

  const isSettingAvailable = !!setting && !isEmpty(setting?.settings);
  const currentSetting = useMemo(() => {
    return isSettingAvailable && setting.settings.find(s => s.count === selectedParlay.length)
  }, [setting, isSettingAvailable, selectedParlay]);

  const exceedTheAmountToEarn = useMemo(() => {
    if (currentSetting) {
      const toEarn = +calculatePairlay(selectedParlay, amount);
      const maxToEarn = +currentSetting.max;
      return toEarn > maxToEarn;
    }
    return false;
  }, [currentSetting, amount, selectedParlay])

  return (
    <BaseModal isOpen={isModalOpenParlay}>
      <div className="flex flex-col items-center gap-4 p-4">
        <div className="flex w-full items-center px-10 gap-6">
          <h1 className="TitleTextModal">Parlay</h1>
          <hr className="ml-2 border-gray-400 flex-grow" />
        </div>

        {/* display alert if changes */}
        {!isSettingAvailable && <div className='w-full px-4'>
          <Alert kind='info'>
            La jugada no puede ser completada por que su configuración no esta realizada.
          </Alert>
        </div>}

        {/* display alert if changes */}
        {exceedTheAmountToEarn && <div className='w-full px-4'>
          <Alert kind='error'>
            El monto a ganar excede el monto máximo a ganar no es posible crear esta apuesta.
          </Alert>
        </div>}

        {/* content */}
        <div className="w-full">
          <ul className="divide-y divide-gray-200 mx-5 bg-[#F5F7F9] p-5 rounded overflow-auto max-h-80 border flex flex-col gap-4">
            {
              selectedParlay.map((item, index) => {
                return (
                  <ModalCard data={item.Game} to={item.selected} key={index} type={2} parlay />
                )
              })
            }
          </ul>
          {/* desc */}
          <div className="flex gap-4 p-5 items-center justify-between">
            <div className='flex justify-items-center justify-between flex-wrap items-center'>
              <span className='text-sm'>Monto:</span>
              <input
                type="text"
                className="bg-gray-200 rounded-lg p-2 w-1/2 lg:w-1/3 disabled:opacity-70 disabled:cursor-not-allowed disabled:text-gray-500 disabled:select-none"
                placeholder='0.00'
                value={amount}
                disabled={!isSettingAvailable}
                onChange={(e) => {
                  setAmount(e.target.value)
                }} /></div>
            <div>
              <p className='smallText'>Monto a ganar:
                <span className='font-bold '> {currency(calculatePairlay(selectedParlay, amount), 'USD')}</span>
              </p>
            </div>
          </div>
          <div className="p-5">
            <p className='smallText'>
              Monto máximo a ganar <b>({currentSetting ? currentSetting.count : 0})</b> combinaciones:
            </p>
            <p className='smallerText'>
              {currentSetting ? currency(currentSetting.max.toString(), 'USD') : currency('0', 'USD')}
            </p>
          </div>
        </div>
        {/* actions */}
        <div className="flex w-full gap-4 justify-end py-4">
          <button type='button' className="text-[#404F60] px-4 py-4 rounded-lg hover:text-red-800 font-medium transition-all duration-200" onClick={handleModal(false)}>Cancelar</button>
          <Button classes="bg-[#181E5C] text-white px-4 py-4 rounded-lg mr-4" onClick={saveBet} disabled={disableButton() || !isSettingAvailable || exceedTheAmountToEarn} label='CONFIRMAR' />
        </div>
      </div>

    </BaseModal>
  )
}
