import { useModal, useSelectedBet } from 'src/store';
import React, { useMemo } from 'react'
import BaseModal from './BaseModal'
import ModalCard from './modalCard'
import { useService } from 'src/hooks';
import { Alert, Button } from 'src/components';
import { calculateBet, currency, printBet } from 'src/utils';
import { isEmpty } from 'lodash';

const BetModal: React.FC<{ setting?: BetSettings }> = ({ setting }) => {
  const { isModalOpen, setModalOpen } = useModal();
  const { selectedBet, setSelectedBet } = useSelectedBet();
  const [amount, setAmount] = React.useState('');

  const handleModal = (status: boolean) => {
    return () => {
      setModalOpen(status);
      setAmount('');
      setSelectedBet(null);
    }
  }

  const closed = () => {
    const status = [2, 3, 4, 5, 6].map(s => s.toString());
    return selectedBet && status.includes(selectedBet.game.status.toString())
  }

  const bet = useService<{ data: any }>({
    route: 'bets',
    data: async res => {
      if (res) {
        handleModal(false)();

        printBet(res, selectedBet);
      }
    }
  });

  React.useEffect(() => {
    if (selectedBet) {
      setAmount(selectedBet.amount.replace('-', '').replace('+', ''))
    }
  }, [selectedBet]);

  function saveBet() {
    if (selectedBet) {
      bet.post({
        data: {
          betAmount: amount,
          earnedAmount: calculateBet(null, selectedBet, +amount),
          status: selectedBet.game.status,
          gameMode: 1,
          betDetails: [
            {
              game: selectedBet.game.id,
              betType: 1,
              fighter: selectedBet.game.fighters[selectedBet.to].id,
              favorite: selectedBet.game.fighters[selectedBet.to].favorite,
              betPercent: selectedBet.game.fighters[selectedBet.to].betPercent
            }
          ]
        }
      })
    }
  }

  const isSettingAvailable = !!setting && !isEmpty(setting?.settings);

  const exceedTheAmountToEarn = useMemo(() => {
    if (selectedBet && isSettingAvailable) {
      const canEarn = calculateBet(null, selectedBet, +amount) as number;
      const maxCanEarn = +setting.settings[0].max;

      return canEarn > maxCanEarn;
    };

    return false;
  }, [selectedBet, isSettingAvailable, amount, setting]);

  return (
    <BaseModal isOpen={isModalOpen}>
      <div className="flex flex-col items-center p-4 gap-4 transition-all duration-300">
        <div className="flex w-full items-center px-10 gap-6">
          <h1 className="TitleTextModal">Directo</h1>
          <hr className=" border-gray-400 flex-grow" />
        </div>
        {/* display alert if changes */}
        {!isSettingAvailable && <div className='w-full px-4'>
          <Alert kind='info'>
            La jugada no puede ser completada por que su configuración no esta realizada.
          </Alert>
        </div>}

        {/* display alert if changes */}
        {exceedTheAmountToEarn && <div className='w-full px-4'>
          <Alert kind='error'>
            El monto a ganar excede el monto máximo a ganar no es posible crear esta apuesta.
          </Alert>
        </div>}
        {/* content */}
        <div className="w-full">
          <ul className="divide-y divide-gray-200 mx-5 bg-[#F5F7F9] p-5 rounded overflow-auto max-h-80 border">
            {
              selectedBet ? <ModalCard data={selectedBet.game} to={selectedBet.to} /> : null
            }
          </ul>
          {/*  */}
          <div className="grid grid-cols-2 gap-4 p-5 items-center justify-center justify-items-center">
            <div className='flex justify-items-center justify-between flex-wrap items-center'>
              <span className='mdoalNormalText'>Monto de apuestas:</span>
              <input
                type="text"
                className="bg-gray-200 rounded-lg p-2 w-1/2 lg:w-1/3 disabled:opacity-70 disabled:cursor-not-allowed disabled:text-gray-500 disabled:select-none"
                placeholder='0.00'
                value={amount}
                disabled={!isSettingAvailable}
                onChange={(e) => {
                  setAmount(e.target.value)
                }}/>
              </div>
            <div>
              {selectedBet
                ? (<p className='smallText'>
                  Monto a ganar:{' '}
                  <span className='font-bold text-gray-600'>{currency(calculateBet(null, selectedBet, +amount).toString(), 'USD')}</span>
                </p>)
                : null
              }
            </div>
          </div>
          {/* monto máximo */}
          <div className="p-5">
            <p className='text-sm text-gray-600 font-medium'>
              Monto máximo a ganar:
            </p>
            <p className='font-bold smallerText'>
              {selectedBet && isSettingAvailable ? currency(setting.settings[0].max.toString(), 'USD') : null}
            </p>
          </div>
        </div>
        {/*  */}
        <div className="flex w-full justify-end">
          <button type='button' className=" text-[#404F60] px-4 py-4 rounded-lg mr-4" onClick={handleModal(false)}>Cancelar</button>
          <Button classes="bg-[#181E5C] text-white px-4 py-4 rounded-lg mr-4" onClick={saveBet} disabled={bet.serviceLoading === 'post' || closed() || !isSettingAvailable || exceedTheAmountToEarn} label='CONFIRMAR' />
        </div>
      </div>

    </BaseModal>
  )
}

export default BetModal
