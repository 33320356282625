import { useForm } from 'react-hook-form';
import { useIsAuth } from 'src/store';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useService } from 'src/hooks';
import { APP_STORAGE_KEY, setStorageValue } from 'src/utils';
import { Alert, CButton, Input } from 'src/components';
import { FaSignInAlt } from 'react-icons/fa';

import { ReactComponent as Logo } from 'src/assets/Icon_coock_blue.svg';

import Background from 'src/assets/background.jpg';
import Gallos from 'src/assets/Gallos.png';
import { LoginContainer } from 'src/container';

interface Form {
  username: string
  password: string
}

const schema = yup.object({
  username: yup
    .string()
    .required('Este campo es requerido'),
  password: yup.string().required('Este campo es requerido')
});

const resolvedForm = yupResolver(schema);

export const LoginPage = () => {
  const { setAuth } = useIsAuth();
  const { register, handleSubmit, formState: { errors } } = useForm<Form>({
    resolver: resolvedForm
  });

  const login = useService<Login>({
    route: 'login',
    data: res => {
      if (res.token) {
        setAuth({
          role: res.user.role.id,
          isReady: true
        });

        setStorageValue(APP_STORAGE_KEY, {
          token: res.token,
          user: res.user.username,
          id: res.user.id,
          role: res.user.role.id,
          banker: res.user?.banker?.id
        });
      }
    },
    options: {
      isPublic: true,
      messages: {
        post: {
          hideMessage: true
        }
      }
    }
  });

  const isLoading = login.serviceLoading === 'post';

  const submit = handleSubmit(async (data: Form) => {
    // if there's an error on the api
    if (login.apiError) login.clearError();
    // do the actual service call
    await login.post({
      data
    });
  });

  return (
    <LoginContainer>
      <div className='lg:grid grid-cols-1 lg:grid-cols-3 flex-grow h-full'>
        {/* left panel */}
        <div className='hidden lg:flex flex-col col-span-2 h-full'>

          <div className='h-full w-full bg-[#0B0E2C] relative overflow-hidden'>
            <img src={Gallos} alt="gallos" className='absolute left-1/4 top-1/4 w-[650px]' />
            <img src={Background} alt="login-bg" className='w-full h-full' />
          </div>
          <div className='bg-[#0B0E2C] px-12 py-6'>
            <h1 className='text-white font-bold'>Copyright © Cock Fight {new Date().getFullYear()}</h1>
          </div>
        </div>
        {/* right panel login container */}
        <div className='h-full mx-auto lg:mx-0 px-12 lg:px-6 w-full'>
          <form onSubmit={submit} className='w-full mx-auto md:max-w-xl lg:w-full h-full flex flex-col items-center justify-center gap-6 px-12'>
            {/* Logo */}
            <Logo />
            {/* Alert */}
            {login.apiError && <Alert kind='error'>
              Usuario o contraseña incorrectos
            </Alert>}
            {/* user name */}
            <Input
              {...register('username')}
              type='tex'
              label='Usuario'
              error={errors.username?.message}
            />
            {/* password */}
            <Input
              {...register('password')}
              type='password'
              label='Contraseña'
              error={errors.password?.message}
            />
            {/* login */}
            <CButton
              className='w-full p-4'
              icon={<FaSignInAlt />}
              type="submit" busy={isLoading}>Iniciar sesión</CButton>
          </form>
        </div>
      </div>
    </LoginContainer>
  )
}
