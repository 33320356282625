import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { Alert, CButton, Drawer, Input } from "src/components"
import { type Base, useService } from "src/hooks";
import { useGlobalModal } from "src/store"
import { BiLoaderAlt } from "react-icons/bi";
import { useState } from "react";
import { get, isEmpty } from "lodash";
import { currency, onPrintRecharge } from "src/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useValidatePrinter } from "src/hooks/useValidatePrinter";

interface Query {
  query: string
  searchByCardId: boolean
}

const resolver = yupResolver(Yup.object({
  searchByCardId: Yup.boolean(),
  query: Yup.string().required('Este campo es requerido')
}))

export const RecargarPlayerModal = () => {
  const { displayGlobalModal, setDisplayGlobalModal } = useGlobalModal();
  const { register, formState: { errors }, clearErrors, reset, handleSubmit } = useForm<Query>({
    defaultValues: {
      searchByCardId: false
    },
    resolver
  });
  const [state, setState] = useState({
    called: false,
    currentUser: {} as User
  });
  const [balance, setBalance] = useState<string>('');
  const { isValid, renderAlert } = useValidatePrinter();

  function onClear() {
    reset({})
    clearErrors()
  }

  const userSvc = useService<Base<User[]>>({
    route: 'users',
    data: res => {
      setState({
        called: true,
        currentUser: isEmpty(res.data) ? {} as User : res.data[0]
      })
    }
  });

  function handleClose() {
    onClear();
    setDisplayGlobalModal('none');
    userSvc.clearError();
    setState({
      called: false,
      currentUser: {} as User
    });
    setBalance('');
  }

  const rechargeSvc = useService({
    route: 'rechargeBalance',
    data: res => {
      onPrintRecharge(state.currentUser.username, get(res, 'amount', '0') || '0')

      handleClose();
    }
  });

  function handleBalance(event: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value } } = event;

    setBalance(value);
  }

  const handleUserInput = handleSubmit(async data => {
    const payloadKey = data.searchByCardId ? 'cardId' : 'username';

    const payload = {
      [payloadKey]: data.query,
      role: 3
    }

    const query = Object.entries(payload).map(([key, value]) => `${key}=${value}`).join('&');

    setState({
      called: false,
      currentUser: {} as User
    });

    await userSvc.get(`?${query}`);
  });

  const isLoading = userSvc.serviceLoading === 'get';
  const isRecharging = rechargeSvc.serviceLoading === 'post';

  const hasError = () => {
    const errors: Record<string, boolean> = {};

    if (!balance) {
      errors.isEmpty = true
    }

    if (isNaN(+balance) || +balance < 0) {
      errors.notValidNumber = true;
    }

    if (+balance <= 0) {
      errors.noEnoughMoney = true;
    };

    const isValid = Object.values(errors).every(error => !error);

    return !isValid;
  }

  const rechargePlayer = async () => {
    const payload = {
      transactionType: 1,
      status: 2,
      transactionMethod: 1,
      amount: balance,
      player: state.currentUser.player.id
    };

    await rechargeSvc.post({
      data: payload
    })
  };

  return (
    <Drawer
      isOpen={displayGlobalModal === 'recargar-player'}
      onClose={handleClose}
      title="Recargar player"
    >
      <form className="flex flex-col flex-1" onSubmit={handleUserInput}>
        <div className="flex-grow bg-gray-50 relative">
          <div className="absolute inset-0 overflow-y-auto p-4 flex flex-col gap-4">
            {renderAlert()}
            <div>
              <Input
                {...register('query')}
                type="text"
                label="Nombre de usuario o cédula"
                placeholder="Ej: juan22 / 0010000001"
                error={errors.query?.message}
              />
              <label className="flex items-center gap-2 cursor-pointer select-none">
                <input {...register('searchByCardId', { value: false })} type="checkbox" />
                <p className="text-gray-500 font-bold">Buscar por cédula</p>
              </label>
            </div>
            {/* loader */}
            {isLoading && <div className="flex-grow w-full flex items-center gap-3 justify-center">
              <BiLoaderAlt className='animate-spin' size={42} />
              <h2>Buscando usuario</h2>
            </div>}
            {/* if no data */}
            {!isLoading && state.called && !state.currentUser?.id && <div className="flex-grow w-full flex justify-center items-start">
              <Alert kind="info">
                No se encontró ningún usuario. por favor verifique que el nombre de usuario o cédula estén correctos.
              </Alert>
            </div>}
            {/* user info */}
            {!isLoading && state.currentUser?.id && <div className="flex flex-col gap-4 pt-4 border-t">
              <h1 className="font-bold text-gray-800">Información del usuario</h1>
              <div className="grid grid-cols-2 gap-6 border-t pt-4">
                <div>
                  <h1 className="font-bold text-gray-600">Nombre</h1>
                  <p className="text-gray-800">{state.currentUser.names} {state.currentUser.lastName}</p>
                </div>
                <div>
                  <h1 className="font-bold text-gray-600">Nombre de usuario</h1>
                  <p className="text-gray-800">{state.currentUser.username}</p>
                </div>
                <div>
                  <h1 className="font-bold text-gray-600">Cédula</h1>
                  <p className="text-gray-800">{state.currentUser.cardId || 'N/A'}</p>
                </div>
                <div>
                  <h1 className="font-bold text-gray-600">Correo</h1>
                  <p className="text-gray-800">{state.currentUser.email || 'N/A'}</p>
                </div>
                <div>
                  <h1 className="font-bold text-gray-600">Balance</h1>
                  <p className="text-gray-800 font-semibold">{currency(state.currentUser?.player?.balance)}</p>
                </div>
              </div>
              <Input
                type="text"
                label="Agregar balance"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                onChange={handleBalance}
                value={balance}
                error={(isNaN(+balance) || +balance < 0) ? "Por favor inserte un numero valido" : undefined}
              />
            </div>}
          </div>
        </div>
        {/* action */}
        <div className="p-4 border-t flex gap-4 items-center justify-end bg-white">
          <CButton type='button' className="w-fit bg-transparent group p-3" onClick={handleClose}>
            <p className="text-red-500 font-bold group-hover:text-red-800 transition-all duration-300">
              Cancelar
            </p>
          </CButton>

          <CButton
            type='submit'
            className="w-fit p-2 px-6"
            busy={isLoading}
            disabled={!!userSvc.apiError || !isValid}
          >
            <p className="text-white font-bold text-sm">
              Buscar usuario
            </p>
          </CButton>

          {state.currentUser?.id && <CButton
            type='button'
            className="w-fit bg-green-900 p-2 px-6 text-sm disabled:cursor-not-allowed"
            disabled={hasError() || !isValid || isRecharging}
            onClick={rechargePlayer}
          >
            <p className="text-white font-bold text-sm">
              Recargar
            </p>
          </CButton>}
        </div>
      </form>
    </Drawer>
  )
}
