import { FaSignOutAlt } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom"
import { client } from "src/grapql.config";
import { useIsAuth } from "src/store";
import { APP_STORAGE_KEY, deleteStorageValue, generatePath } from "src/utils";
import { protectedRoutes } from '../../router/index';
import { useSidebarActions } from "src/hooks";
import { MdOutlineOpenInNew } from "react-icons/md";

export const SideBarMenu = () => {
  const { setAuth, auth } = useIsAuth();
  const { getAction } = useSidebarActions();
  const location = useLocation();

  function isActive(path: string) {
    const base = location.pathname.toLowerCase().split('/')[1];
    const validate = path.toLowerCase().split('/')[1];
    const isHome = location.pathname.length === 1 && path.length === 1;

    return isHome || (base && validate && base.includes(validate));
  }

  function onLogout() {
    setAuth({
      isReady: false,
      role: 0
    });

    deleteStorageValue(APP_STORAGE_KEY);
    deleteStorageValue('logged');

    client.resetStore();

    window.location.replace('/');

    window.location.reload();
  }

  const generateLink = (route: typeof protectedRoutes[0]) => {
    return <Link to={generatePath(route.path)}>
      <li className={`group flex gap-2 items-center px-4 py-3 rounded-md hover:bg-[#0B0E2C] ${isActive(route.path) ? 'bg-[#0B0E2C]' : ''}`}>
        {/* route icon */}
        <div className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'text-white' : ''}`}>
          {route.icon}
        </div>
        {/* route label */}
        <p className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'font-medium text-white' : ''}`}>{route.label}</p>
      </li>
    </Link>
  }

  const generateLinkNewTab = (route: typeof protectedRoutes[0]) => {
    return <li>
      <Link to={generatePath(route.path)} target="_blank" rel="noopener noreferrer" className={`w-full justify-between group flex gap-2 items-center px-4 py-3 rounded-md hover:bg-[#0B0E2C] ${isActive(route.path) ? 'bg-[#0B0E2C]' : ''}`}>
        <div className="flex gap-2 items-center">
          <div className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'text-white' : ''}`}>
            {route.icon}
          </div>
          <p className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'font-medium text-white' : ''}`}>{route.label}</p>
        </div>
        {/*  */}
        <div className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'text-white' : ''}`}>
          <MdOutlineOpenInNew />
        </div>
      </Link>
    </li>
  }

  const generateAction = (route: typeof protectedRoutes[0]) => {
    return <li className="w-full">
      <button className={`group w-full flex gap-2 items-center px-4 py-3 rounded-md justify-between hover:bg-[#0B0E2C] ${isActive(route.path) ? 'bg-[#0B0E2C]' : ''}`} onClick={getAction(route.action)}>
        {/*  */}
        <div className="flex gap-2 items-center">
          <div className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'text-white' : ''}`}>
            {route.icon}
          </div>
          <p className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'font-medium text-white' : ''}`}>{route.label}</p>
        </div>
        {/*  */}
        <div className={`text-[#0B0E2C] group-hover:text-white ${isActive(route.path) ? 'text-white' : ''}`}>
          <MdOutlineOpenInNew />
        </div>
      </button>
    </li>
  }

  return (
    <div className="h-full flex-col w-1/5 2xl:w-1/6 bg-gray-200 hidden lg:flex justify-between px-6 py-4">
      <ul className="flex flex-col gap-1">
        {protectedRoutes
          .filter(e => e.roles?.includes(auth.role))
          .filter(route => route.icon && route.label)
          .filter(e => !e.hide)
          .map((route) => {
            if (route.target) {
              return generateLinkNewTab(route);
            }

            if (route.action) {
              return generateAction(route)
            }

            return generateLink(route)
          })}
      </ul>

      <div className="flex flex-col gap-2 items-center">
        <div className="w-full h-0.5 bg-[#0B0E2C]" />
        <button type="button" onClick={onLogout} className="text-[#0B0E2C] flex gap-3 items-center group hover:text-blue-900">
          <FaSignOutAlt className="group-hover:text-blue-900" /> Cerrar sesión
        </button>
      </div>
      {/*  */}
    </div>
  )
}
