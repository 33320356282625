import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const userSchema = Yup.object({
  email: Yup.string().email('Por favor inserte un email valido').required('Este campo es requerido'),
  names: Yup.string().required('Este campo es requerido'),
  surNames: Yup.string().required('Este campo es requerido'),
  cardId: Yup.string().required('Este campo es requerido')
});

export const resolver = yupResolver(userSchema);

export interface UserForm {
  cardId: string
  email: string
  names: string
  surNames: string
}

export interface UserPasswordForm {
  password: string
  enabled: boolean
}

const userPasswordSchema = Yup.object({
  password: Yup.string(),
  enabled: Yup.boolean()
});

export const resolverPassword = yupResolver(userPasswordSchema);
