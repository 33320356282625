import { useCurrentAnimation } from "src/store"

export function useAnimation() {
  const { setCurrentAnimation, currentAnimation } = useCurrentAnimation();

  function onClearAnimation() {
    setCurrentAnimation({
      id: 0,
      type: 'loader'
    })
  }

  function handleAnimation(id: number, options: HandleAnimationOptions = {}) {
    // start the animation
    setCurrentAnimation({
      id,
      type: options.type || 'loader'
    });

    // reset the animation
    setTimeout(onClearAnimation, options.duration || 2000);
  }

  return {
    currentAnimation,
    setCurrentAnimation,
    handleAnimation,
    onClearAnimation
  }
}

interface HandleAnimationOptions {
  duration?: number
  type?: 'loader' | 'pulse'
}
