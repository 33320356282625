import classNames from "classnames";
import { type ButtonHTMLAttributes, type DetailedHTMLProps, forwardRef, type HTMLAttributes } from 'react';
import { BiLoaderAlt } from 'react-icons/bi'
interface Props
  extends DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
  > {
  className?: HTMLAttributes<HTMLButtonElement>['className']
  busy?: boolean
  icon?: React.ReactNode
}

export const CButton = forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { className, busy, ...attributes } = props;
    const baseClass = classNames('flex gap-2 items-center justify-center bg-cta-blue rounded-md text-white transition-all duration-300', { 'disabled:opacity-70 disabled:cursor-not-allowed': busy || attributes.disabled }, className);

    return (
      <button
        {...attributes}
        ref={ref}
        disabled={attributes.disabled || busy}
        className={baseClass}>
        {busy && <BiLoaderAlt className="animate-spin text-white" />}
        {props.icon && !busy && props.icon}
        {props.children}
      </button>
    );
  }
);

CButton.displayName = 'Button';
