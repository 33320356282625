// prop type for BaseModal
interface BaseModalProps {
  children?: React.ReactNode
  isOpen: boolean
  onClose?: () => void
}

const BaseModal = ({ isOpen, children }: BaseModalProps) => {
  //   const toggleModal = () => setIsOpen(!isOpen);

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 transition-all duration-300 flex items-center justify-center">
          <div className="w-full h-full md:h-fit md:w-3/4 lg:w-1/2 bg-white md:rounded-lg md:shadow-xl">
             {children}
          </div>
        </div>
      )}
    </>
  );
};

export default BaseModal;
