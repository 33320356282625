import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { ReactComponent as LogoHeader } from 'src/assets/Logo_heahde.svg';
import { useIsAuth } from 'src/store';
import { APP_STORAGE_KEY, deleteStorageValue, getStorageValue, Paths } from 'src/utils';
import { Link, useLocation } from 'react-router-dom';
import { client } from 'src/grapql.config';
import { SideMenu } from 'src/layouts'
import { RiAccountCircleLine, RiMenuFill } from "react-icons/ri"
import classNames from 'classnames';
import { useValidatePrinter } from 'src/hooks/useValidatePrinter';

export default function Headerv2() {
  const { setAuth } = useIsAuth();
  const storage = getStorageValue<{ user: string, role: number }>(APP_STORAGE_KEY);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { renderBadge } = useValidatePrinter();

  const location = useLocation();
  const enableSidebarMenuButton = location.pathname.trim().toLowerCase() === Paths.home.trim().toLowerCase() && storage.role === 6;

  const menuClasses = classNames("w-6 h-6 block text-white mb-1",
    { 'lg:hidden': !enableSidebarMenuButton }
  )
  const accountIconClasses = classNames('text-white',
    { hidden: enableSidebarMenuButton }
  )

  function onLogout() {
    setAuth({
      isReady: false,
      role: 0
    });

    deleteStorageValue(APP_STORAGE_KEY);
    deleteStorageValue('logged');

    client.resetStore();

    window.location.replace('/');

    window.location.reload();
  }

  return (
    <Disclosure as="nav" className="bg-[#0B0E2C]">
      {({ open }) => (
        <>
          <SideMenu sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} onLogout={onLogout} />
          <div className="mx-auto max-w-full pl-2 sm:pl-6 lg:pl-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center  sm:items-stretch sm:justify-between h-full w-3/4">
                <div className="flex flex-shrink-0 items-center">
                  <Link to={Paths.home}>
                    <LogoHeader className='w-32' />
                  </Link>

                  {renderBadge()}
                </div>
              </div>
              {/*  */}
              <div className="absolute inset-y-0 right-0 flex items-center  sm:static sm:inset-auto sm:ml-32 sm:pr-0 w-1/4 h-full ">
                {/* Profile dropdown */}
                <div className='clipPath bg-red-600 h-full w-full flex justify-center gap-4 items-center'>

                  <div className='relative hidden lg:flex justify-center items-center '>

                    <div className='h-full w-full flex justify-center items-center select-none'>
                      <div className="flex rounded-full  text-md  h-1/2 items-center gap-2">
                        <RiAccountCircleLine className={accountIconClasses} size={32} />
                        <span className="text-white font-bold hidden lg:block uppercase">{storage.user || 'Nombre de usuario'}</span>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <button type='button' onClick={() => setSidebarOpen(true)} className={menuClasses}>
                    <RiMenuFill size={28} />
                  </button>
                </div>

              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  )
}
