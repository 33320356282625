export function currency(data: string, code = 'USD') {
  const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: code }).format(+data);
  return formatted.includes('NaN') ? '$0.00' : formatted;
}
export function calculatePairlay(selectedParlay: any, amount: number | string) {
  let percent = 0;
  let totalPercent = 0;

  for (const detail of selectedParlay) {
    if (detail.selected < 3) { // Money
      const selected = detail.selected === 2 ? 1 : 0;
      if (detail.Game.fighters[selected].favorite) {
        percent = 1 + (100 / +detail.Game.fighters[selected].betPercent);
      } else {
        percent = 1 + (+detail.Game.fighters[selected].betPercent / 100);
      }
    } else if (detail.selected > 2) { // Time
      percent = 1 + (100 / detail.Game.morePercentage);
    }

    if (totalPercent) {
      totalPercent *= percent;
    } else {
      totalPercent = percent;
    }
  }
  const result = +amount * totalPercent - +amount
  const roundedNum = result.toFixed(2);
  const formattedNum = parseFloat(roundedNum);
  return addDecimal(formattedNum.toString());
};

export function calculateBet(game: any, detail: Bet, amount: any) {
  const { betPercent, favorite } = detail.game.fighters[detail.to];
  let result: number | string = 0;

  if (favorite) {
    const profitPerDollar = 100 / +betPercent;
    result = amount * profitPerDollar;
  } else {
    const profitPerDollar = +betPercent / 100
    const value = amount * profitPerDollar;
    const roundedNum = value.toFixed(2);
    const formattedNum = parseFloat(roundedNum);
    result = addDecimal(formattedNum.toString());
  }

  return result;
}

export function addDecimal(number: string) {
  const decimalNumber = parseFloat(number);

  if (Number.isNaN(decimalNumber)) {
    return number;
  }

  if (decimalNumber % 1 === 0) {
    return decimalNumber.toFixed(2);
  }

  return decimalNumber.toString();
}
