import classNames from "classnames";
import { isEmpty, orderBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom"
import { useAnimation } from "src/hooks";
import { generateKey, getFormattedDateTime, getStorageValue } from "src/utils";
import { TIMER_KEY, type Options } from "./ConfigDialog";
import { useGames } from "src/store";
import { SubscriptorContainer } from "src/container";
import { NoResult } from "src/components";

const LiveGamesDetails = () => {
  const { coliseum } = useParams<{ coliseum: string }>();
  const [searchParams] = useSearchParams();
  const displayAll = searchParams.get('display-all') === 'true';
  const cls = coliseum && !isNaN(+coliseum) ? +coliseum : 0;
  const { currentAnimation } = useAnimation();
  const { games } = useGames();

  const [elementsThatFit, setElementThatFit] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(0);
  const [currentColiseum, setCurrentColiseum] = useState<number>(cls);
  const [shouldChange, setShouldChange] = useState<boolean>(false);

  const coliseumIds = [...new Set(games.map(c => c.coliseum.id))];

  const ref = useRef<HTMLDivElement | null>(null);

  function paginateArray<T>(array: T[], itemsPerPage: number, currentPage: number): T[] {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentArray = array.slice(startIndex, endIndex);

    return currentArray;
  }

  const filteredGames = (games || [])
    .filter(g => g
      .coliseum
      .id
      .toString()
      .toLowerCase()
      .includes(currentColiseum.toString())
    ).filter(g => g.status === 1);

  const memoizedList = paginateArray(orderBy(filteredGames, 'fightNumber', 'asc'), elementsThatFit, currentPage);

  const [first] = memoizedList;

  useEffect(() => {
    if (ref.current) {
      const itemsPerPage = Math.floor(ref.current.getBoundingClientRect().height / 94);
      const totalPages = Math.ceil(filteredGames.length / itemsPerPage) || 1;

      setElementThatFit(itemsPerPage);
      setPages(totalPages);
    }
  }, []);

  useEffect(() => {
    const { value } = getStorageValue<Options>(TIMER_KEY);
    const timer = value || 10_000;

    const time = setInterval(() => {
      setCurrentPage(prev => {
        if (prev < pages && !isEmpty(filteredGames)) {
          setShouldChange(false);

          return prev + 1
        }

        if (displayAll) {
          setShouldChange(true);
        }

        return 1;
      });
    }, timer);

    if (isEmpty(filteredGames)) {
      clearInterval(time)
    }

    return () => {
      clearInterval(time)
    }
  }, [pages, filteredGames, displayAll]);

  useEffect(() => {
    if (pages >= currentPage && shouldChange && displayAll) {
      const currentIndex = coliseumIds.indexOf(currentColiseum);
      const nextIndex = (currentIndex + 1) % coliseumIds.length;
      const next = coliseumIds[nextIndex];

      setCurrentColiseum(next);
      setShouldChange(false);
      setCurrentPage(1);
    }
  }, [pages, currentPage, coliseumIds, shouldChange, displayAll]);

  if (isEmpty(filteredGames)) {
    return <>
      <NoResult />
    </>
  }

  return (
    <div className="h-full bg-white flex flex-col">
      <div className="p-6 bg-cta-blue">
        <div className="container mx-auto flex items-center gap-4">
          <h1 className="text-white font-bold">{first?.coliseum.name}</h1>
          <span className="font-bold text-white">-</span>
          <p className="text-white ">Peleas: <b>{filteredGames.length}</b></p>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto p-4" ref={ref}>
        <ul className="container mx-auto flex flex-col">
          {
            memoizedList.map((l) => {
              const [firstFighter, secondFighter] = l.fighters;

              return <li key={generateKey(l.id, Date.now())} className={classNames("flex lg:flex-row flex-col gap-2 border text-sm relative rounded-md mb-2", {
                'border-red-600 border': currentAnimation.id === l.id
              })}>
                {currentAnimation.id === l.id && <div className='absolute -top-1 -right-1'>
                  <span className="relative flex h-4 w-4">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-4 w-4 bg-red-600"></span>
                  </span>
                </div>}
                {/* fight */}
                <div className="flex gap-4 flex-grow lg:w-1/2 lg:flex-row flex-col">
                  <div className={classNames(
                    "text-xs bg-gray-300 flex flex-col items-center p-4 lg:rounded-l-sm lg:w-1/6 relative overflow-hidden",
                    {
                      'bg-red-600 text-white border-red-600 border': currentAnimation.id === l.id
                    }
                  )}>
                    <h1 className="font-bold text-lg"># {l.fightNumber}</h1>
                    <p className="capitalize">{getFormattedDateTime(l.startDate, 'smallMonth')}</p>
                    {currentAnimation.id === l.id && <span className="absolute animate-ping inline-flex h-full top-1/2 left-1/2  w-full rounded-full bg-white opacity-75"></span>}
                    {currentAnimation.id === l.id && <span className="absolute animate-ping inline-flex h-full -top-1/2 -left-1/2 w-full rounded-full bg-white opacity-75"></span>}
                  </div>
                  {/* fighters */}
                  <div className="flex items-center px-4 mx-4">
                    <p className="mr-4">
                      {firstFighter.team.name}{' '}
                      <span className="bg-gray-100 rounded-md p-1 px-2 font-semibold">
                        {firstFighter.favorite ? "-" : "+"}{firstFighter.betPercent}
                      </span>
                    </p>
                    <p className="text-red-600 font-bold text-xl mr-4">VS</p>
                    <p>
                      {secondFighter.team.name}{' '}
                      <span className="bg-gray-100 rounded-md p-1 px-2 font-semibold">
                        {secondFighter.favorite ? "-" : "+"}{secondFighter.betPercent}
                      </span>
                    </p>
                  </div>
                </div>

                {/* in time */}
                <div className="flex-grow flex items-center justify-center lg:justify-end p-4">
                  <div className={classNames(
                    "lg:w-[10rem] rounded-full px-4 py-1 text-left"
                  )}>
                    <h1 className="font-semibold">A tiempo:</h1>
                    <h2 className={classNames("font-bold", {
                      'text-green-800': l.morePercentage && l.moreTime
                    },
                    {
                      'text-red-600': !(l.morePercentage && l.moreTime)
                    })}>{(l.lessPercentage && l.moreTime && `${l.moreTime.substring(3)} (-${l.morePercentage})`) || "No disponible"}</h2>
                  </div>
                </div>
              </li>
            })
          }
        </ul>
      </div>
    </div>
  )
}

export const LiveGamesDetailsPage = () => (
  <SubscriptorContainer>
    <LiveGamesDetails />
  </SubscriptorContainer>
);
