import { useState } from 'react';
import { Service, type Operation, type DeleteOperation, type PutOperation, type RoutesKeys, type ApiOptions, type Base, type PaginatedBase } from 'src/Api';
import { type AxiosError } from 'axios';

export type { Operation, DeleteOperation, PutOperation, RoutesKeys, ApiOptions, Base, PaginatedBase };

export function useService<T>(args: UseServiceProps<T>) {
  const { route, data, options } = args;
  const service = new Service(route, options);

  const [serviceLoading, setServiceLoading] =
    useState<ServiceOperation>('stopped');
  const [apiError, setApiError] = useState<AxiosError | undefined>(undefined);
  const handleIsLoading = (where: ServiceOperation) => { setServiceLoading(where); };

  function clearError() {
    setApiError(undefined);
  }
  /**
   * method to do a get request to the api
   * @param query string
   */
  async function get(query: string = '') {
    handleIsLoading('get');

    const { data: res, error } = await service.get<T>(query);
    if (error) {
      handleIsLoading('stopped');

      setApiError(error);
    }

    if (res) {
      handleIsLoading('stopped');
      // reset the api error
      clearError();

      data(res, 'get');
    }
  }
  async function post<K>(props: Operation<K>) {
    handleIsLoading('post');

    const { data: res, error } = await service.post<K>(props);
    if (error) {
      handleIsLoading('stopped');

      setApiError(error);
    }

    if (res) {
      handleIsLoading('stopped');
      // reset the api error
      clearError();

      data(res as T, 'post');
    }
  }
  async function remove<K>(props: DeleteOperation) {
    handleIsLoading('remove');

    const { data: res, error } = await service.delete<K>(props);
    if (error) {
      handleIsLoading('stopped');

      setApiError(error);
    }

    if (res) {
      handleIsLoading('stopped');
      // reset the api error
      clearError();
      data(res as T, 'remove');
    }
  }
  async function update<K>(props: PutOperation<K>) {
    handleIsLoading('update');

    const { data: res, error } = await service.update<K>(props);
    if (error) {
      handleIsLoading('stopped');

      setApiError(error);
    }

    if (res) {
      handleIsLoading('stopped');
      // reset the api error
      clearError();

      data(res as T, 'update');
    }
  }

  return { get, post, serviceLoading, apiError, remove, clearError, update };
}

export interface UseServiceProps<T> {
  route: RoutesKeys
  data: (data: T, service: ServiceOperation) => void
  options?: ApiOptions
}

type ServiceOperation = 'get' | 'post' | 'stopped' | 'remove' | 'update';
