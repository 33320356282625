import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const BANKER = '6';
export interface UserForm {
  role: string
  names: string
  lastName: string
  email: string
  username: string
  password: string
  enabled: boolean
  coliseum?: {
    data: string[]
  }
}

const messages = {
  required: 'Este campo es requerido!',
  email: 'Este no es un email valido.',
  password: {
    min: 'Debe contener al menos 6 caracteres. en combinación con números, letras (Mayúsculas/Minúsculas)  y caracteres especiales.'
  },
  rol: 'Es necesario un rol para continuar.'
}

// const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const roles = ["1", "2", "3", "4", "5", "6", "7"];

const schema = Yup.object({
  role: Yup.string().oneOf(roles, messages.rol).required(messages.required),
  names: Yup.string().required(messages.required),
  lastName: Yup.string().required(messages.required),
  email: Yup.string().email(messages.email).required(messages.required),
  password: Yup.string().test(
    'required-password',
    messages.required,
    function (value) {
      const isEditing = this.options.context?.isEditing ?? false;
      if (isEditing) {
        return true; // No validation required for editing
      }
      return !!value; // Required for new entry
    }
  ),
  username: Yup.string().required(messages.required),
  enabled: Yup.bool().notRequired(),
  coliseum: Yup.object({
    data: Yup.array().of(Yup.string())
  }).when('role', {
    is: (val: string) => val === BANKER, // banker
    then: (schema) => schema
      .required(messages.required)
  })
});

export const usersSchemaResolver = yupResolver(schema);
