import { FaChartBar, FaFileInvoiceDollar } from 'react-icons/fa'
import { AiFillDollarCircle, AiOutlineDollarCircle } from 'react-icons/ai'
import { MdPeopleAlt } from 'react-icons/md'
import { Layout, Loader } from 'src/layouts'
import { useService } from 'src/hooks'
import { useEffect, useState } from 'react'
import { betType, currency, getFormattedDateTime } from 'src/utils'
import { generateKey } from '../../utils/generateKey/index';
import { useSubscription } from '@apollo/client'
import { LISTEN_TO_DASHBOARD } from 'src/graphql'
import { isEmpty } from 'lodash'
import { Alert } from 'src/components'

export const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<Dashboard>({
    totalWin: 0
  } as Dashboard);
  const subscription = useSubscription<ListenDashboard>(LISTEN_TO_DASHBOARD);

  const dashboardSvc = useService<Dashboard>({
    route: 'dashboard',
    data: res => setDashboardData(res)
  });

  useEffect(() => {
    dashboardSvc.get();
  }, []);

  useEffect(() => {
    if (subscription.data) {
      setDashboardData(subscription.data?.listenDashboard.node || {} as Dashboard);
    }
  }, [subscription.data])

  const isLoading = dashboardSvc.serviceLoading === 'get';

  return (
    <Layout
      config={{
        withSideBar: true
      }}
    >
      <div className='w-full h-full p-4 bg-custom-bg gap-2'>
        {isLoading && !dashboardSvc.apiError && <Loader />}
        {!isLoading && dashboardData && <div className='w-full h-full flex flex-col gap-4 p-4 bg-white rounded-lg border shadow-lg'>
          {/* Summary */}
          <div className='rounded-xl border-2 border-gray-100 p-4 flex flex-col gap-4'>
            <div className='border-b pb-2 flex gap-2 items-center text-gray-900'>
              <FaChartBar size={24} className='text-gray-600' />
              <h1 className='font-bold text-gray-600'>Summary</h1>
            </div>
            <div className='flex gap-4 flex-col md:flex-row md:items-center justify-between'>
              {/* chart 1 */}
              <ChartData
                icon={<AiFillDollarCircle className='text-green-600' size={48} />}
                text='Total ganado'
                amount={currency(dashboardData?.totalWin.toString() || '0', 'USD')}
                amountColor='text-green-600'
              />
              {/* chart 2 */}
              <ChartData
                icon={<FaFileInvoiceDollar className='text-red-500' size={48} />}
                text='Apuestas'
                amount={dashboardData.totalCountBet}
                amountColor='text-red-500'
              />
              {/* chart 3 */}
              <ChartData
                icon={<MdPeopleAlt className='text-blue-600' size={48} />}
                text='Jugadores activos'
                amount={dashboardData.activePlayers}
                amountColor='text-blue-600'
              />
            </div>
          </div>
          {/*  */}
          <div className='flex-grow flex md:flex-row flex-col gap-4'>
            {/*  */}
            <div className='flex flex-col gap-4 w-full h-full'>
              <div className='rounded-xl border-2 border-gray-100 p-4 flex-1 flex flex-col gap-2'>
                {/* flex */}
                <div className='border-b pb-2 flex gap-2 items-center text-gray-900'>
                  <AiOutlineDollarCircle size={24} className='text-gray-600' />
                  <h1 className='text-gray-600 font-bold'>Ultimas {(dashboardData?.lastTenBets || []).length} apuestas</h1>
                </div>
                {/*  */}
                <div className='flex-1 flex flex-col relative'>
                  <div className='absolute inset-0 overflow-y-auto'>
                    <ul className='flex flex-col h-full gap-2'>
                      {isEmpty(dashboardData.lastTenBets) && <li className='w-full h-full flex items-center self-center'>
                        <Alert kind='warning'>
                          No hay resultado para mostrar!.
                        </Alert>
                      </li>}

                      {!isEmpty(dashboardData.lastTenBets) && dashboardData.lastTenBets.map(b => {
                        return <li key={generateKey(b.names, b.createdAt)} className='rounded-md bg-white flex flex-col md:flex-row'>
                          <div className='px-4 w-[10%] font-bold text-gray-500'>
                            {getFormattedDateTime(b.createdAt, 'hours')}
                          </div>
                          <div className='flex flex-col justify-center items-center'>
                            <div className='rounded-full p-2 bg-white border-red-600 border-4'>
                            </div>
                            <div className='border-l-2 border-dashed flex-grow w-0'></div>
                          </div>
                          <div className='px-4 flex flex-col rounded-md'>
                            <p className='md:font-bold text-gray-600'>{getFormattedDateTime(b.createdAt, 'year')}</p>
                            <div className='text-left flex gap-2'>
                              <h1 className='font-bold text-gray-500'>Nombres:</h1>
                              <p className=''>
                                {b.names}
                              </p>
                            </div>
                            <div className='text-left flex gap-2'>
                              <h1 className='font-bold text-gray-500'>Tipo:</h1>
                              <p>{betType[b.gameMode]}</p>
                            </div>
                            <div className='text-left flex gap-2'>
                              <h1 className='font-bold text-gray-500'>Monto:</h1>
                              <p className='text-green-600 font-bold'>{currency(b.betAmount.toString(), 'USD')}</p>
                            </div>
                          </div>

                        </li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </Layout>
  )
}

interface CharDataProps {
  text: string
  amount: string | number
  icon: React.ReactNode
  amountColor?: string
}

const ChartData: React.FC<CharDataProps> = (props) => {
  const { text, amount, icon, amountColor } = props;
  return <div className='flex items-center gap-4'>
    {icon}
    <div className='flex flex-col gap-1'>
      <p className='text-sm text-gray-500 font-bold'>{text}</p>
      <h1 className={`font-bold ${amountColor || ''} text-4xl`}>{amount}</h1>
    </div>
  </div>
}
