import { CreateUserGlobalModal, PrintersModal, RecargarPlayerModal, WithdrawBalanceModal } from "src/Modals"

export const ModalsContainer: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <>
        <CreateUserGlobalModal />
        <RecargarPlayerModal />
        <WithdrawBalanceModal />
        <PrintersModal />
        {children}
    </>
  )
}
