/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isEmpty } from 'lodash';
import { NoResult } from "../NoResults";
import { generateKey } from "src/utils";
import classNames from 'classnames';
import { Loader } from 'src/layouts';
import { TbReload } from 'react-icons/tb';

interface Col<T> {
  name: string
  field: string
  class?: string
  icon?: JSX.Element
  render?: (row: Col<T> & { data: T }) => JSX.Element | null
  hide?: boolean
}

interface Props<T> {
  data: T[]
  cols: Array<Col<T>>
  isLoading?: boolean
  titleIcon?: JSX.Element
  loadMoreTitle?: string
  loadMoreIcon?: JSX.Element
  className?: string
  onRowClick?: (data: T) => void
  onServiceCall?: () => void
}

export const CustomTable = function <T>(props: Props<T>) {
  const { data, cols, isLoading, className } = props;
  const classes = classNames('h-fit relative', className);
  const content = classNames('h-[92%] border lg:h-4/5 overflow-x-hidden flex flex-col gap-4 lg:block bg-white', {
    'h-fit pt-12': isEmpty(data)
  });
  const rowClasses = classNames('w-full border-b hidden lg:flex p-4 border-b cursor-default last:border-none',
    { 'cursor-pointer hover:border-l-8 hover:border-l-red-600 transition-all duration-300': !!props.onRowClick }
  );
  const onClickRow = (value: T) => () => {
    if (props.onRowClick) {
      props.onRowClick(value);
    }
  }

  function renderCardItem(col: Col<T>, d: T) {
    return <div className={classNames('text-left', { 'flex gap-2': col.field === 'id' })}>
      <h5 className={'font-bold flex items-center gap-1'}>{col.icon} {col.name}:</h5> {(d as any)[col.field]}</div>
  }

  return (
    <div className={classes}>
      {/* header */}
      <div className='sticky top-0 bg-gray-100 gap-2 py-4 px-4 lg:flex text-left text-gray-800 text-sm font-bold mb-6 rounded-lg hidden'>
        {
          cols.filter(c => c.name).filter(c => !c.hide).map((c, i) => {
            return <div key={c.name + c.field} className={`w-[35%] ${c.class || ''}`}>
              {c.name}
            </div>
          })
        }
      </div>

      {/* content */}
      <div className={content}>
        {!isLoading && !isEmpty(data) && data.map((d, i) => {
          return <button
            onClick={onClickRow(d)}
            type='button'
            key={generateKey(i, Date.now())}
            className={classNames('border rounded lg:hidden w-full p-4 flex flex-col gap-4')}
          >
            {
              cols
                .filter(c => !c.hide)
                .map((t, i) => {
                  return <div key={generateKey(t.name, i)} className={`text-sm w-full flex flex-col items-start ${t.class || ''}`}>
                    {t.render ? <div className='text-left'><h5 className='font-bold'>{t.name}: </h5> {t.render({ ...t, data: d })}</div> : renderCardItem(t, d)}
                    {t.field === 'id' && <div className='border-b pt-2 w-full' />}
                  </div>
                })
            }
          </button>
        })}
        {
          !isLoading && !isEmpty(data) && data.map((d, i) => {
            return <button
              type='button'
              className={rowClasses}
              key={generateKey(i, new Date().getTime())}
              onClick={onClickRow(d)}
            >
              {
                cols
                  .filter(c => !c.hide)
                  .map((t, i) => {
                    return <div key={generateKey(t.name, i)} className={`w-[35%] text-sm flex items-center px-2 justify-start font-medium ${t.class || ''}`}>
                      {t.render ? t.render({ ...t, data: d }) : <p className='break-all text-sm text-left'>{(d as any)[t.field]}</p>}
                    </div>
                  })
              }
            </button>
          })
        }
        {
          !isLoading && isEmpty(data) && (
            <NoResult />
          )
        }
        {
          isLoading && <Loader />
        }
      </div>
      {props.onServiceCall && <div className='py-2 flex items-center justify-end flex-wrap'>
        <button
          disabled={isLoading}
          onClick={props.onServiceCall}
          type="button"
          className='p-2 px-4 flex items-center gap-2 border rounded-full disabled:cursor-not-allowed bg-red-600 text-white'>
          <TbReload className={classNames(
            { 'animate-spin': isLoading }
          )} />
          <p className='text-sm font-bold'>Cargar mas</p>
        </button>
      </div>}
    </div>
  )
}
