import { useModal, useSelectedBet, useBetType } from 'src/store';
import React from 'react'
import Icon_coock_blue from '../../assets/Icon_coock_blue.svg'
import Icon_coock_yellow from '../../assets/Icon_coock_yellow.svg'
import { Button } from 'src/components'
import { Loader } from 'src/layouts';
import classNames from 'classnames';
import { useAnimation } from 'src/hooks';
import { getFormattedDateTime, isPrinterActive } from 'src/utils';
interface Props {
  data: Game
}

export function BetCard({ data }: Props) {
  const { isModalOpen, setModalOpen } = useModal();
  const { setSelectedBet } = useSelectedBet();
  const { currentAnimation } = useAnimation();

  const { betType } = useBetType();
  const [bet1, setBet1] = React.useState<string>('');
  const [bet2, setBet2] = React.useState<string>('');
  const formattedDate = getFormattedDateTime(data.startDate, 'month');
  const animate = currentAnimation.id === data.id;

  const generateImage = (color: string) => {
    if (color === 'blue') {
      return Icon_coock_blue
    }

    return Icon_coock_yellow
  }

  const classes = classNames("shadow-lg bg-white rounded-md relative");

  if (animate && currentAnimation.type === 'loader') {
    return <div className='animate-pulse bg-white border rounded-md w-full shadow-lg flex flex-col h-[21rem]'>
      <div className='h-14 w-full bg-gray-300 rounded-t-md'></div>
      <div className='flex items-center justify-evenly flex-grow p-4'>
        <Loader />
      </div>
    </div>
  }

  return (
    <div className={classes}>

      {animate && currentAnimation.type === 'pulse' && <div className='absolute -top-1 -right-1'>
        <span className="relative flex h-4 w-4">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-4 w-4 bg-red-600"></span>
        </span>
      </div>}

      {/* header */}
      <div className="px-6 py-4 text-center text-gray-700 bg-gray-200 overflow-hidden rounded-t-md">
        <h1 className="text-lg cardTitle flex justify-between">
          <span className='capitalize'>{formattedDate}</span> <span>#{data.fightNumber}</span>
        </h1>
      </div>
      {/* content */}
      <div className="py-4 px-2 relative overflow-hidden flex-grow rounded-b-md">
        <div className="flex">
          <FighterBet
            img={generateImage(data.fighters[0].color)}
            teamName={data.fighters[0].team.name}
            favorite={data?.fighters[0]?.favorite}
            betPercent={data?.fighters[0]?.betPercent}
            value={bet1}
            onChange={e => setBet1(e)}
            disable={bet2 !== ''}
          />

          <div className="w-1/5 px-1 py-4 text-center flex justify-center items-center">
            <p className="mt-4 text-[#CE0B0B] font-extrabold text-2xl ">VS</p>
          </div>
          {/* this could be a component */}
          <FighterBet
            img={generateImage(data.fighters[1].color)}
            teamName={data.fighters[1].team.name}
            favorite={data?.fighters[1]?.favorite}
            betPercent={data?.fighters[1]?.betPercent}
            value={bet2}
            onChange={e => setBet2(e)}
            disable={bet1 !== ''}
          />
        </div>

        <div className=" py-4 text-center flex  flex-wrap w-full justify-center content-between justify-items-center">
          <Button classes="bg-[#181E5C] text-white py-2 px-4 rounded-full w-full md:w-full my-2" onClick={() => {
            setSelectedBet({
              game: data,
              to: bet1 !== '' ? 0 : 1,
              amount: bet1 !== '' ? `${data?.fighters[0]?.favorite ? '-' : '+'} ${bet1}` : `${data?.fighters[1]?.favorite ? '-' : '+'} ${bet2}`,
              betType
            })
            setModalOpen(!isModalOpen)

            setBet1('')
            setBet2('')
          }} disabled={(!bet1 && !bet2) || (+bet1 === 0 && +bet2 === 0) || (+bet1 < 1 && +bet2 < 1) || !isPrinterActive()} label='APOSTAR' />
        </div>
      </div>
    </div>
  )
}

interface FighterBetProps {
  img: string
  teamName: string
  favorite: boolean
  betPercent: string
  onChange: (value: string) => void
  value: string
  disable: boolean
}

const FighterBet: React.FC<FighterBetProps> = (props) => {
  function onChangeBet(e: React.ChangeEvent<HTMLInputElement>) {
    const { target: { value } } = e;

    props.onChange(value);
  }

  return <div className="w-2/5 px-1 py-4 text-center flexJustifyCenter">
    <img src={props.img} alt="Image 3" className="w-16 h-16 object-cover rounded-full mx-auto" />
    <p className="mt-4 cardRusterName">{props.teamName}</p>
    <div className="flex items-center justify-center h-8 rounded-md mt-2">
      <div className="bg-[#6EAB5B] w-1/2 h-full rounded-l-md flex justify-center items-center text-white font-bold text-sm">
        {props.favorite ? '-' : '+'}{props.betPercent}
      </div>
      {/*  */}
      <input
        className="bg-gray-300 w-1/2 h-full rounded-r-md p-2 text-xs"
        type={'number'}
        min={0}
        onChange={onChangeBet}
        value={props.value}
        disabled={props.disable}
      />
    </div>
  </div>
};
