import { Suspense, useEffect, useState } from 'react';

import { Routes, Route, useNavigate } from 'react-router-dom';
import { globalRoutes, protectedRoutes } from 'src/router';
import { useIsAuth } from 'src/store';
import { getStorageValue, APP_STORAGE_KEY } from 'src/utils';
import { NotFoundPage } from 'src/pages';
import { AppContainer, ModalsContainer, ToastContainer } from 'src/container';
import { Loader } from './layouts';

function GlobalRoutes() {
  return <Routes>
    {globalRoutes.map(route => <Route key={route.key} path={route.path} element={route.page} />)}
  </Routes>
}

function ProtectedRoutes(props: { role: number }) {
  return <ModalsContainer>
    <Routes>
      {
        protectedRoutes
          .filter(e => e.roles?.includes(props.role))
          .filter(e => !e.action)
          .filter(e => !e.hide)
          .map(route => <Route key={route.key} path={route.path} element={route.page} />)
      }
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  </ModalsContainer>
}

export function App() {
  const { auth, setAuth } = useIsAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const storage = getStorageValue<{ token: string, role: number }>(APP_STORAGE_KEY);

    if (storage.token && storage.role) {
      setAuth({
        isReady: true,
        role: storage.role
      });
    } else {
      // if no token then redirect the user to /
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const time = setTimeout(() => {
      setIsLoading(false);

      clearTimeout(time)
    }, 1000);

    return () => {
      clearTimeout(time)
    }
  }, []);

  if (isLoading) {
    return <AppContainer>
      <Loader />
    </AppContainer>
  }

  return (
    <AppContainer>
      <Suspense fallback={<Loader />}>
        <ToastContainer>
          {auth.isReady && auth.role ? <ProtectedRoutes role={auth.role} /> : <GlobalRoutes />}
        </ToastContainer>
      </Suspense>
    </AppContainer>
  );
}
